import { styled } from '@abyss/web/tools/styled';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

export const CostText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '14px',
  },
});

export const ProviderIdText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '14px',
  },
});

export const PrintViewAddress = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '9px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11px',
  },
});

export const PrintViewDivider = styled(Divider, {
  '&.abyss-divider-root': {
    border: '0.5px solid #CCCCCC',
    height: 0,
    marginTop: '$sm !important',
    marginBottom: '$md !important',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
  },
});

export const PrintViewProviderMediumText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '17px',
    marginBottom: '5px',
  },
});

export const PrintViewProviderBoldText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderNormalText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderDetailsNameText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '590',
    fontStyle: 'normal',
    lineHeight: '14px',
  },
});

export const PrintViewProviderTierOneText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '9.24px',
    fontWeight: '590',
    fontStyle: 'normal',
    lineHeight: '12.332px',
  },
});

export const PrintTierInfoBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '3.083px',
  padding: '1.542px 6.166px',
  gap: '3.08px',
  backgroundColor: '#F3F3F3 !important',
  width: '94px',
  height: '16.08px',
  webKitFilter: 'grayscale(100%)',
});
export const PrintTierTwoInfoBox = styled('div', {
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
  justifyItems: 'right',
  padding: '0',
  gap: '3.08px',
  borderRadius: '3.083px',
  backgroundColor: '#F3F3F3 !important',
  width: '94px',
  height: '16.08px',
  webKitFilter: 'grayscale(100%)',
});

export const PrintViewProviderDetailsXSText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '14px',
  },
});

export const PrintViewProviderDetailsMediumText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '14px',
    textAlign: 'right',
  },
});

export const PreferredProviderContainer = styled(Flex, {
  '&.abyss-flex-root': {
    padding: 0,
    margin: 0,
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
});
