import { useRouter } from '@abyss/web/hooks/useRouter';
import { ProviderSearchForPortal } from 'parcels/src/frontends/ProviderSearchForPortal/ProviderSearchForPortal';
import React from 'react';

import { PSX_LANDING_PAGE_PARAMS } from '../../parcels/src/common/Constants';
import { PortalData } from '../../parcels/src/common/Portal';
import {
  mockBehavioralGuestPortalMemberInfo,
  mockTier1PortalMemberInfo,
} from '../../parcels/src/common/Portal/mockBehavioralGuestPortalMemberInfo';
import { mockMedicalGuestPortalMemberInfo } from '../../parcels/src/common/Portal/mockMedicalGuestPortalMemberInfo';
import { mockPortalMemberInfo } from '../../parcels/src/common/Portal/mockPortalMemberInfo';
import { ProviderSearch } from '../../parcels/src/frontends/ProviderSearch';
import { DetailsDrawer } from './DetailsDrawer';

export const browser = () => {
  const { getLocation } = useRouter();
  const { pathname = '' } = getLocation() || {};
  if (pathname === '/details-widget') {
    return <DetailsDrawer />;
  }
  if (pathname.startsWith('/laww')) {
    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);

    // get theme
    const theme = urlParams.get('theme') || 'optum';

    // Get language, defaults to en-US
    const lang = urlParams.get('lang') || 'en-US';

    // Get the user tier, defaults to 2
    const userTier = urlParams.get('userTier') || '2';
    const eapOnlyClient = urlParams.get('eapOnlyClient') === 'true';
    const showEapFilter =
      eapOnlyClient || urlParams.get('showEapFilter') !== 'false';
    const lockEapFilter =
      eapOnlyClient || urlParams.get('lockEapFilter') === 'true';
    const preSelectEapFilter =
      eapOnlyClient || urlParams.get('preSelectEapFilter') === 'true';
    const showOnlineScheduling =
      urlParams.get('showOnlineScheduling') !== 'false';
    const portalShowCost = !(
      eapOnlyClient || urlParams.get('showCost') === 'false'
    );
    const showTreatmentCenter = !(
      eapOnlyClient || urlParams.get('showTreatmentCenter') === 'false'
    );
    const showMarketPlace = !(
      eapOnlyClient || urlParams.get('showMarketPlace') === 'false'
    );
    const memberInfo = () => {
      if (userTier === '0') return mockBehavioralGuestPortalMemberInfo;
      if (userTier === '1') return mockTier1PortalMemberInfo;
      return mockPortalMemberInfo;
    };
    const showVirtualVisits = urlParams.get('showVirtualVisits') !== 'false';

    const portalData: PortalData = {
      portalName: 'LAWW',
      portalConfig: {
        basePath: '/laww',
        headerChipsEnabled: false,
        theme,
        language: lang,
        homePage: PSX_LANDING_PAGE_PARAMS,
        signInUrl: '/api/auth/server-signin?callbackUrl=LANDING_PAGE',
        isGuestUser: userTier === '0',
        showCost: portalShowCost,
        showTreatmentCenter,
        showMarketPlace,
        includeBaseCss: true,
        userTier,
        showEapFilter,
        lockEapFilter,
        preSelectEapFilter,
        showVirtualVisits,
        showOnlineScheduling,
      },
      portalMemberInfo: memberInfo(),
      portalUrls: [],
    };

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  if (pathname.startsWith('/medical-guest')) {
    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const choosePcp = urlParams.get('choosePCP') || 'false';
    const chipValue = urlParams.get('chipValue');

    let homePath = '/';
    if (choosePcp === 'true') {
      homePath =
        '/results?chipValue=Primary%20Care&coverageType=M&search=Primary%20Care&searchType=speciality&providerType=&choosePCP=true&pcpIndicator=true';

      if (!chipValue) {
        const newUrl = `${window.location.protocol}//${window.location.host}/medical-guest${homePath}`;
        window.location.href = newUrl;
      }
    }

    const portalData: PortalData = {
      portalName: 'medical-guest',
      portalConfig: {
        basePath: '/medical-guest',
        headerChipsEnabled: true,
        theme: 'uhc',
        language: 'en-US',
        homePage: homePath,
        isGuestUser: true,
        showCost: true,
        showTreatmentCenter: true,
        includeBaseCss: true,
        showVirtualVisits: true,
        showOnlineScheduling: true,
      },
      portalMemberInfo: mockMedicalGuestPortalMemberInfo,
      portalUrls: [],
    };

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  return <ProviderSearch />;
};
