import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { ProviderSummaryTypes } from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { RatingText } from '../../../../../common/DataCard/DataCard.style';
import { getTierProviderEligibility } from '../../../../../common/Utils/memberUtils/memberUtils';
import { ProviderType } from '../../../../../models/ProviderDetails';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { returnSuppressFlag } from '../../../../../utils/featureSuppress';
import { getFormattedPhoneNumber } from '../../../../../utils/phone.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../utils/providerNames.utils';
import { getCurrentMember } from '../../../../../utils/user.utils';
import { isVirtualVisitOnlyAddress } from '../../../../../utils/virtualVisitOnly';
import { getStarsRating } from './PrintView';
import {
  PrintTierInfoBox,
  PrintViewProviderDetailsNameText,
  PrintViewProviderDetailsXSText,
  PrintViewProviderTierOneText,
} from './PrintView.style';
import { PrintPreviewTierOneIcon } from './TierOneIcon';

type Props = {
  provider: {
    numberOfReviews?: string;
    acceptingNewPatients: boolean;
    providerName: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    organizationType: string;
    providerType?: string;
    networkStatus: string;
    distance: string;
    isPreferredLab?: boolean;
    isFreeStandingFacility?: boolean;
    ddpCode?: string;
    healthGradeRating?: string;
    isTieredProvider: boolean;
    virtualCareOffered?: boolean;
    languagesSpoken: string[];
    website: string;
    address: {
      postalCode: string;
      district: string;
      line: string[];
      state: string;
      city: string;
    };
    firstName?: string;
    middleName?: string;
    lastName?: string;
    costIndicator?: string;
    phones: {
      phone: string[];
    };
  };
};

export const ProviderDemographics = ({ provider }: Props) => {
  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );
  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );
  const isVirtualVisitOnlyAddressLine = (
    addressLine: string,
    suppressVirtualCareQuickCare: boolean
  ) =>
    suppressVirtualCareQuickCare &&
    isVirtualVisitOnlyAddress(chipValue, addressLine);
  const shouldShowVirtualVisitAddress = isVirtualVisitOnlyAddressLine(
    provider?.address?.line?.[0],
    isVirtualCareQuickCareSuppressed
  );

  const { t } = useTranslation();
  const suppressRatings: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const currentMember = getCurrentMember();
  const isCNS = currentMember?.isCnSMember;
  const { healthGradeRating = '' } = provider;
  const nameAttrs = getNameAttrs(provider);
  const parsedHealthGradeRating = parseFloat(
    parseFloat(healthGradeRating).toFixed(1)
  );

  const isDisplayTier1Identifier = getTierProviderEligibility(
    currentMember,
    provider?.isTieredProvider
  );

  return (
    <Flex
      css={{
        'abyss-flex-root': {
          gap: '2px',
        },
      }}
      direction="column"
    >
      <PrintViewProviderDetailsNameText color="#333333">
        {parseProviderName(
          provider.providerName,
          provider.providerType as ProviderType,
          provider.primaryDegrees?.[0],
          nameAttrs
        )}
      </PrintViewProviderDetailsNameText>
      <PrintViewProviderDetailsXSText color="#333333">
        {provider?.speciality || provider?.organizationType}
      </PrintViewProviderDetailsXSText>
      {!suppressRatings &&
        provider?.providerType === ProviderSummaryTypes.PRACTITIONER && (
          <Flex>
            <Layout.Group space="">
              {getStarsRating(parsedHealthGradeRating)}
              {parsedHealthGradeRating ? (
                <RatingText className="text" size="11px">
                  {provider?.numberOfReviews
                    ? `${provider?.numberOfReviews} Reviews`
                    : parsedHealthGradeRating}
                </RatingText>
              ) : null}
            </Layout.Group>
          </Flex>
        )}
      {!isCNS && isDisplayTier1Identifier ? (
        <Flex>
          <PrintTierInfoBox
            data-auto-testId="print-preview-tier-one-indicator"
            data-testId="print-preview-tier-one-indicator"
          >
            <PrintPreviewTierOneIcon />
            <PrintViewProviderTierOneText color="#333333">
              {t('SHARE_PDF.Tier 1 Provider')}
            </PrintViewProviderTierOneText>
          </PrintTierInfoBox>
        </Flex>
      ) : null}
      <Flex>
        <PrintViewProviderDetailsXSText color="#333333">
          {provider.languagesSpoken?.join(', ')}
        </PrintViewProviderDetailsXSText>
      </Flex>
      <Flex>
        {!shouldShowVirtualVisitAddress &&
        provider?.address?.city &&
        provider?.address?.state ? (
          <PrintViewProviderDetailsXSText color="#333333">
            {`${provider?.address?.line?.[0]}, ${provider?.address?.city}, ${
              provider?.address?.state
            } • ${parseFloat(provider?.distance).toFixed(1)} ${t(
              'SHARE_PDF.Miles away'
            )}`}
          </PrintViewProviderDetailsXSText>
        ) : (
          <PrintViewProviderDetailsXSText color="#333333">
            {provider?.website}
          </PrintViewProviderDetailsXSText>
        )}
      </Flex>
      <Flex>
        <PrintViewProviderDetailsXSText color="#333333">
          {getFormattedPhoneNumber(provider.phones?.phone?.[0])}
        </PrintViewProviderDetailsXSText>
      </Flex>
    </Flex>
  );
};
