/* eslint-disable no-console */

const { DateTime: LuxonDateTime } = require('luxon');

export class DateTime {
  static offsetMillis = 0;

  static centralTimeOffSetFromUTCMillis = 0;

  static getCurrentDateTime() {
    return new Date(Date.now() + DateTime.offsetMillis);
  }

  static getCurrentDateTimeWithCentralOffset() {
    return new Date(
      Date.now() +
        DateTime.centralTimeOffSetFromUTCMillis +
        DateTime.offsetMillis
    );
  }

  static setTimeOffsetMillis(offsetMillis: number) {
    DateTime.offsetMillis = offsetMillis;
  }

  static setTimezoneOffset(enableTimeZoneOffset) {
    if (enableTimeZoneOffset) {
      const currentDateInCentralTime =
        LuxonDateTime.local().setZone('America/Chicago');
      DateTime.centralTimeOffSetFromUTCMillis =
        currentDateInCentralTime.offset * 60 * 1000;

      console.log(
        `Timezone offset is enabled: ${DateTime.centralTimeOffSetFromUTCMillis}`
      );
    }
  }

  static setTimeMachineTesting(
    enableTimemachineTesting,
    timeMachineTestOffsetMillis
  ) {
    if (enableTimemachineTesting && timeMachineTestOffsetMillis) {
      DateTime.setTimeOffsetMillis(Number(timeMachineTestOffsetMillis));
      console.log(
        `Timemachine enabled: Current Time is ${DateTime.getCurrentDateTime()} using timeStampInMillies ${timeMachineTestOffsetMillis}}`
      );
    }
  }
}
