import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useSpecialtiesResult } from '../../../../hooks/useSpecialties';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  CulturalCompetence,
  Education,
  Identifiers,
} from '../../../../models/ProviderDetails';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { Constants, ReverseCoverageTypesCodes } from '../../../Constants';
import { mobileOnly } from '../../../ConstantsStyles';
import {
  collapseContainer,
  collapseContainerOtherProviders,
} from '../About.styled';
import { CollapseProviderDetailsSection } from './CollapseProviderDetailsSection';

const {
  AREAS_OF_EXPERTISE,
  HOSPITAL_AFFILIATIONS,
  PROVIDER_SPECIALTIES,
  EDUCATION,
  OTHER_PROVIDER_DETAILS,
  NA,
  PROVIDER_EXPERIENCE,
} = Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION;

type Props = {
  areaOfExpertise: string[] | undefined;
  culturalCompetencies: CulturalCompetence[] | string[] | undefined | null;
  educationInfo: Education[];
  hospitalAffiliations: Identifiers[];
  licenseNumber: string[];
  npi: string;
  specialties: string[];
  userState: string | undefined;
  isFrequentServicesProvidedEnabled: boolean;
  isProviderExperienceSectionEnabled: boolean;
  frequentServices?: string[] | undefined;
  providerName?: string;
  providerType?: string;
};

export const CollapseDetailsSectionRoot = ({
  areaOfExpertise,
  culturalCompetencies,
  educationInfo,
  hospitalAffiliations,
  licenseNumber,
  npi,
  specialties,
  userState,
  isFrequentServicesProvidedEnabled,
  isProviderExperienceSectionEnabled,
  frequentServices,
  providerName,
  providerType,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;

  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );
  const isVisionCare = coverageType === ReverseCoverageTypesCodes.VISION;
  const isDentalCare = coverageType === ReverseCoverageTypesCodes.DENTAL;

  const { data: referralRequiredSpecialties = [] } = useSpecialtiesResult({
    isReferralRequired: true,
    userState,
  });

  return (
    <React.Fragment>
      {isProviderExperienceSectionEnabled ? (
        <React.Fragment>
          <Flex css={collapseContainer(mobileScreen)}>
            <CollapseProviderDetailsSection
              accordionItemValue={'specialties'}
              areaOfExpertise={
                areaOfExpertise || [
                  t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'),
                ]
              }
              collapseConstantKey={PROVIDER_EXPERIENCE}
              collapseContent={
                specialties || [
                  t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'),
                ]
              }
              collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.PROVIDER_EXPERIENCE"
              culturalCompetencies={culturalCompetencies}
              education={
                educationInfo ||
                (new Array({
                  degrees: [
                    {
                      degreeCode: t(
                        'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                      ),
                      isPrimary: true,
                      description: t(
                        'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                      ),
                    },
                  ],
                  schoolName: t(
                    'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'
                  ),
                }) as Education[])
              }
              frequentServices={frequentServices}
              isFrequentServicesProvidedEnabled={
                isFrequentServicesProvidedEnabled
              }
              isProviderExperienceSectionEnabled={
                isProviderExperienceSectionEnabled
              }
              providerName={providerName}
              providerType={providerType}
              referralRequiredSpecialties={referralRequiredSpecialties}
            />
          </Flex>
          {!isVisionCare && !isDentalCare && (
            <Flex css={collapseContainerOtherProviders(mobileScreen)}>
              <CollapseProviderDetailsSection
                accordionItemValue={'hospitalAfflictions'}
                collapseConstantKey={HOSPITAL_AFFILIATIONS}
                collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS"
                hospitalAffiliations={
                  hospitalAffiliations ||
                  ([
                    {
                      id: NA,
                      value: t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'),
                    },
                  ] as Identifiers[])
                }
              />
            </Flex>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Flex css={collapseContainer(mobileScreen)}>
            <CollapseProviderDetailsSection
              accordionItemValue={'specialties'}
              collapseConstantKey={PROVIDER_SPECIALTIES}
              collapseContent={
                specialties || [
                  t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'),
                ]
              }
              collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.PROVIDER_SPECIALTIES"
              referralRequiredSpecialties={referralRequiredSpecialties}
            />
            {isDentalCare && (
              <CollapseProviderDetailsSection
                accordionItemValue={'education'}
                collapseConstantKey={EDUCATION}
                collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION"
                culturalCompetencies={culturalCompetencies}
                education={
                  educationInfo ||
                  (new Array({
                    degrees: [
                      {
                        degreeCode: t(
                          'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                        ),
                        isPrimary: true,
                        description: t(
                          'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                        ),
                      },
                    ],
                    schoolName: t(
                      'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'
                    ),
                  }) as Education[])
                }
                showCulturalCompetencies={!isDentalCare}
              />
            )}
          </Flex>
          {!isVisionCare && !isDentalCare && (
            <React.Fragment>
              <Flex css={collapseContainer(mobileScreen)}>
                <CollapseProviderDetailsSection
                  accordionItemValue={'areaOfExpertise'}
                  collapseConstantKey={AREAS_OF_EXPERTISE}
                  collapseContent={
                    areaOfExpertise || [
                      t(
                        'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                      ),
                    ]
                  }
                  collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.AREAS_OF_EXPERTISE"
                />
                <CollapseProviderDetailsSection
                  accordionItemValue={'education'}
                  collapseConstantKey={EDUCATION}
                  collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION"
                  culturalCompetencies={culturalCompetencies}
                  education={
                    educationInfo ||
                    (new Array({
                      degrees: [
                        {
                          degreeCode: t(
                            'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                          ),
                          isPrimary: true,
                          description: t(
                            'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                          ),
                        },
                      ],
                      schoolName: t(
                        'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'
                      ),
                    }) as Education[])
                  }
                />
              </Flex>
              <Flex css={collapseContainerOtherProviders(mobileScreen)}>
                <CollapseProviderDetailsSection
                  accordionItemValue={'hospitalAfflictions'}
                  collapseConstantKey={HOSPITAL_AFFILIATIONS}
                  collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS"
                  hospitalAffiliations={
                    hospitalAffiliations ||
                    ([
                      {
                        id: NA,
                        value: t(
                          'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'
                        ),
                      },
                    ] as Identifiers[])
                  }
                />
              </Flex>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Flex css={collapseContainerOtherProviders(mobileScreen)}>
        <CollapseProviderDetailsSection
          accordionItemValue={'providerDetails'}
          collapseConstantKey={OTHER_PROVIDER_DETAILS}
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.OTHER_PROVIDER_DETAILS"
          licenseNumber={licenseNumber || []}
          npi={npi || ''}
        />
      </Flex>
    </React.Fragment>
  );
};
