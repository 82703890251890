export const boardCertifiedInfoIconText = {
  'abyss-text-root': {
    padding: '5px 6px 0px 0px',
    fontSize: '$smallText !important',
    fontWeight: '$semibold !important',
    lineHeight: '14px !important',
    height: '18px',
    width: 'fit-content',
    color: '#196ECF !important',
  },
};
