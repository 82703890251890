import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { getCurrentMember } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { FeatureFlags } from '../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { PSXIcon } from '../PSXIcon';
import { getFeatureFlag } from '../Utils';
import { getTierProviderEligibility } from '../Utils/memberUtils/memberUtils';
import { TierBadgeIcon } from './TierBadge.styled';

type Props = {
  isTier2Provider: boolean | undefined;
  isTieredprovider: boolean | undefined;
};

export const TierBadge = ({ isTier2Provider, isTieredprovider }: Props) => {
  const { t } = useTranslation();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const showTier1ProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );
  const showTier2Badge: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_TWO_PROVIDER
  );

  const currentMember = getCurrentMember();
  const isCns = currentMember?.isCnSMember;
  const isDisplayTier1Badge =
    showTier1ProviderTag &&
    getTierProviderEligibility(currentMember, isTieredprovider);

  const isDisplayTier2Badge =
    !isDisplayTier1Badge &&
    showTier2Badge &&
    currentMember?.isIFPTieredPlan &&
    isTier2Provider;

  if ((!isDisplayTier1Badge && !isDisplayTier2Badge) || isCns) return null;

  const tierData = isDisplayTier1Badge
    ? {
        title: 'Tier 1',
        icon: 'Group 204976975',
        testId: 'tier-1-label-badge',
      }
    : { title: 'Tier 2', icon: 'tier_two', testId: 'tier-2-label-badge' };

  return (
    <Flex css={TierBadgeIcon} data-testid={tierData?.testId}>
      <PSXIcon icon={tierData?.icon} size={16} />
      <Text css={{ fontWeight: 'bold' }} size={12.64}>
        {t(tierData?.title)}
      </Text>
    </Flex>
  );
};
