import { storage } from '@abyss/web/tools/storage';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { useTranslation } from 'react-i18next';

import { useBhEap } from '../../hooks/useBhEap';
import { useLagoon } from '../../hooks/useLagoon';
import { suppressBHForPlans } from '../../utils/benefit.utils';
import { getUiMessageFromLagoon } from '../../utils/common';
import { getCurrentMember } from '../../utils/user.utils';
import {
  getFilteredWhereToGetHelpContent,
  getFilteredWhoCanHelpContent,
  getWhereToGetHelpContent,
} from '../../utils/whereToGetHelp.utils';
import { Constants, LOB } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import {
  cardHeaderContainerStyle,
  cardHeaderMyBenefits,
} from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { WhereToGetHelpCard } from './WhereToGetHelpCard/WhereToGetHelpCard';

export type WhereToGetHelpProps = {
  'data-testid'?: string;
  selectedChipValue: string;
};

export const WhereToGetHelp = ({
  'data-testid': testId = 'heading-where-to-get-help',
  selectedChipValue,
}: WhereToGetHelpProps) => {
  const { t } = useTranslation();
  const whereToGetHelp = useLagoon('where-to-get-help')();
  const { IS_BH_EAP_ELIGIBLE } = Constants.STORAGE_KEYS.SESSION;
  const currentMember = getCurrentMember();

  const helpSectionTitleFromLagoon = getUiMessageFromLagoon(
    ConstantsLagoon.UI_MESSAGING.HELP_SECTION_TITLE
  );
  const helpSectionTitle = helpSectionTitleFromLagoon
    ? t(helpSectionTitleFromLagoon)
    : t('WHERE_TO_GET_HELP');

  const bhEap = useBhEap();

  if (bhEap) storage.session.set(IS_BH_EAP_ELIGIBLE, bhEap.isEapEligible);

  const whereToGetHelpContent = getWhereToGetHelpContent(
    whereToGetHelp,
    selectedChipValue
  );
  const lineOfBusiness = currentMember?.lineOfBusiness;

  const memberState =
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.[0]
      ?.situsState;
  const memberGroupId = currentMember?.policyNumber;

  if (whereToGetHelpContent?.length === 0) {
    return null;
  }

  const filteredLagoonData = getFilteredWhereToGetHelpContent(
    {
      planPurchaseProvisions:
        bhEap?.planPurchaseProvisions?.map((item) => item.id) || [],
      isEapEligible: bhEap?.isEapEligible || false,
      eapPhoneNumber: bhEap?.eapData?.eapPhoneNumber || '',
    },
    whereToGetHelpContent
  );

  const whoCanHelpContent =
    lineOfBusiness === LOB.CNS &&
    getFilteredWhoCanHelpContent(
      whereToGetHelpContent,
      memberState,
      memberGroupId
    );

  const filteredData =
    whoCanHelpContent.length || lineOfBusiness === LOB.CNS
      ? whoCanHelpContent
      : filteredLagoonData;

  const suppressedContents = suppressBHForPlans(
    filteredData,
    selectedChipValue,
    currentMember?.eligibility
  );

  const containerForAllSectionStyle = {
    height: 'auto',
  };

  if (suppressedContents.length === 0) {
    return null;
  }

  return (
    <ContainerForAllSectionsStyled css={containerForAllSectionStyle}>
      <ContentWrapper>
        <Flex
          alignItems="flex-end"
          css={cardHeaderContainerStyle}
          justify="start"
        >
          <Heading
            css={cardHeaderMyBenefits}
            data-auto-testid={testId}
            data-testid={testId}
            display="h4"
            offset={1}
          >
            {helpSectionTitle}
          </Heading>
        </Flex>
        <WhereToGetHelpCard
          data-auto-testid="where-to-get-help-card"
          data-testid="where-to-get-help-card"
          whereToGetHelpContent={suppressedContents}
        />
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
