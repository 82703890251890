import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import {
  ContactMyPCP,
  ExternalLinkIcon,
  ReferralBannerBoxBanner,
  ReferralBannerContactPCP,
  ReferralBannerTextContainer,
} from './KsAffliates.styled';

export const KsAffiliatesBanner = () => {
  const { t } = useTranslation();
  const PhoneScreen = useMediaQuery(phoneOnly);

  const allConfigs = useLagoon('config')();

  const KsAffliatedProvidersLink = allConfigs?.find(
    (row) => row.key === 'KELSEY_SEYBOLD_AFFILIATE_BANNER_URL'
  )?.value;

  const handleClick = () => {
    window.open(KsAffliatedProvidersLink, '_blank');
  };

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <ReferralBannerBoxBanner data-testid="ks-affiliated-banner-box">
          <IconMaterial icon="info" size={PhoneScreen ? 18 : 20} />
          <Layout.Stack alignItems="left" space={0}>
            <ReferralBannerTextContainer
              data-testid="Ks-refferal-banner-text-container"
              id="referralText"
            >
              {t('KS_Affiliated_REFERRAL_REQUIRED_BANNER')}
              <ReferralBannerContactPCP
                aria-describedby="referralText specialtyList"
                data-auto-testid="ks-affiliated-contactpcp"
                data-testid="ks-affiliated-contactpcp"
                href={KsAffliatedProvidersLink}
                onClick={handleClick}
                openNewWindow={true}
              >
                <ContactMyPCP>
                  {' '}
                  {t('PROVIDER_DETAILS.BANNER.CONTACT_MY_PCP')}
                </ContactMyPCP>
                <ExternalLinkIcon>
                  <IconMaterial
                    color="$info1"
                    data-testid="contact-pcp-external-link"
                    icon="open_in_new"
                  />
                </ExternalLinkIcon>
              </ReferralBannerContactPCP>
            </ReferralBannerTextContainer>
          </Layout.Stack>
        </ReferralBannerBoxBanner>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
