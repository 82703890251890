import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCoverageType } from '../../hooks/useCoverageType';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../hooks/useLagoon';
import {
  returnInclusionFlag,
  returnSuppressFlag,
} from '../../utils/featureSuppress';
import {
  getCurrentMember,
  getIsCurrentMemberSubscriber,
  isLoggedInMember,
} from '../../utils/user.utils';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { Constants, LOB, ReverseCoverageTypesCodes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ScheduleModal } from '../ScheduleModal';
import { getFeatureFlag } from '../Utils';
import { ResponsiveText } from './DataCard.style';
import { SchedulePopover } from './SchedulePopover';

export const ScheduleButton = styled('button', {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
});

export const scheduleTextStyles = {
  fontSize: '14.22px !important',
  lineHeight: '16px',
  fontWeight: '600',
  '@screen < $sm': {
    fontSize: '$extraSmallText !important',
    fontWeight: '700',
  },
};

type Props = {
  id: string;
  npi: string;
  handleSearchResultClick(clickSection: string): void;
  phone: string;
  practiceId: string;
  providerName: string;
  sectionType?: string;
  showDirection: any;
  providerId?: string;
  locationId: string;
  isCTADisplayLogic?: boolean;
  isTieredDataCard: boolean;
};

export const Schedule = ({
  id,
  npi,
  handleSearchResultClick,
  phone,
  practiceId,
  providerName,
  locationId,
  sectionType,
  showDirection,
  isCTADisplayLogic = false,
  isTieredDataCard,
}: Props) => {
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const coverageType = useCoverageType();
  const featureFlags = useLagoon('feature-flags')();
  const schedulingEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ONLINE_SCHEDULING
  );
  const showScheduleButton: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SCHEDULE_OPTION
  );
  const showScheduleButtonForDpndt: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SCHEDULING_FOR_DEPENDENT
  );

  const isCurrentMemberPreEffective = useCurrentMemberIsPreEffective();
  const isGuestUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.IS_GUEST
  );

  const isMemberEligibleToSchedule =
    isGuestUser ||
    (showScheduleButtonForDpndt
      ? isLoggedInMember()
      : getIsCurrentMemberSubscriber());

  const docAsapEnabled = schedulingEnabled && practiceId;

  const handleScheduleButtonClick = () => {
    if (!isCurrentMemberPreEffective) {
      handleSearchResultClick('Schedule');
      adobeModalTrackEvent(
        `schedule a visit:${docAsapEnabled ? 'schedule' : 'call'}`
      );
      setIsOpenScheduleModal(true);
    }
  };
  const { lineOfBusiness } = getCurrentMember();

  const suppressDocAsap = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.SUPPRESS_DOC_ASAP
  );

  const includeDocAsap =
    lineOfBusiness === LOB.CNS &&
    returnInclusionFlag(
      ConstantsLagoon.FEATURE_INCLUSION_FLAGS.INCLUDE_DOC_ASAP
    );

  if (
    (lineOfBusiness === LOB.CNS ? !includeDocAsap : suppressDocAsap) ||
    !showScheduleButton ||
    !isMemberEligibleToSchedule ||
    [
      ReverseCoverageTypesCodes.DENTAL,
      ReverseCoverageTypesCodes.VISION,
    ].includes(coverageType) ||
    sectionType !== Constants.RESULT_SECTION.PROVIDER
  ) {
    return null;
  }

  const scheduleText = (
    <ResponsiveText
      color="$interactive1"
      css={scheduleTextStyles}
      data-auto-testid={`data-card-calendar-schedule-${sectionType}-${id}`}
      data-testid={`data-card-calendar-schedule-${sectionType}-${id}`}
      fontWeight="$bold"
    >
      {isCTADisplayLogic
        ? t('DATA_CARD.SCHEDULE_ONLINE')
        : t('DATA_CARD.SCHEDULE')}
    </ResponsiveText>
  );

  return (
    <React.Fragment>
      <div style={{ height: 'auto' }}>
        <Divider margin={0} orientation="vertical" width={1} />
      </div>
      {!isCurrentMemberPreEffective ? (
        <ScheduleButton
          data-testid={`data-card-calendar-schedule-${sectionType}`}
          onClick={handleScheduleButtonClick}
        >
          <IconMaterial
            aria-hidden="true"
            icon="insert_invitation"
            size={mobileScreen ? 18 : 24}
          />
          {scheduleText}
        </ScheduleButton>
      ) : (
        <SchedulePopover
          mobileScreen={mobileScreen}
          scheduleText={scheduleText}
        />
      )}
      <ScheduleModal
        isOpen={isOpenScheduleModal}
        isTieredDataCard={isTieredDataCard}
        locationId={locationId}
        npi={npi}
        phone={phone}
        practiceId={practiceId}
        providerId={id}
        providerName={providerName}
        sectionType={sectionType}
        setIsOpen={setIsOpenScheduleModal}
        showDirection={showDirection}
      />
    </React.Fragment>
  );
};
