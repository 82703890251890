import { storage } from '@abyss/web/tools/storage';
import React, { Fragment, useEffect } from 'react';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { DateTime } from '../../../../utils/DateTime';
import { IChildrenProp } from '../../types';

export const DateTimeConfig = ({ children }: IChildrenProp) => {
  const [enableTimezoneOffset, enableTimeMachineTesting] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIMEZONE_OFFSET,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIMEMACHINE_TESTING,
  ]);

  const timeMachineTestOffsetMillis = storage.cookie.get(
    'time-machine-test-offset-millis'
  );

  useEffect(() => {
    DateTime.setTimezoneOffset(enableTimezoneOffset);
    DateTime.setTimeMachineTesting(
      enableTimeMachineTesting,
      timeMachineTestOffsetMillis
    );
  }, []);

  return <Fragment>{children}</Fragment>;
};
