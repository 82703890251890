import { mapIcons } from './mapIcons.utils';

export const USER_LAYER = 'curLocation';
export const CLUSTER_LAYER = 'clusters';
export const RESULT_LAYER = 'unclustered-point';
export const ROUTE_LAYER = 'nav-route';
export const PLACES_SOURCE = 'places';

export const getDistanceString = (distance: number) => {
  const miles = distance / 1609.344;
  return `${miles?.toFixed(1)} mi`;
};

export const getStepDistanceString = (distance: number) => {
  if (distance > 0) {
    const miles = distance / 1609.344;
    return `${miles?.toFixed(1)} mi`;
  }
  return '';
};

export const getStepIcon = (modifier: string | undefined, type: string) => {
  const direction = `${type ? type.replace(/ /g, '_') : ''}${
    modifier ? `_${modifier.replace(/ /g, '_')}` : ''
  }`;
  return Object.keys(mapIcons).includes(direction) ? mapIcons[direction] : '';
};

export const getDurationString = (
  duration: number,
  isSecondRequired: boolean = false
) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const formattedTime = [
    { time: hours, title: hours > 1 ? 'hours' : 'hour' },
    { time: minutes, title: minutes > 1 ? 'minutes' : 'minute' },
    { time: seconds, title: seconds > 1 ? 'seconds' : 'second' },
  ];

  if (!isSecondRequired) formattedTime.pop();

  return formattedTime
    .filter(({ time }) => Boolean(time))
    .map((item) => `${item.time} ${item.title}`)
    .join(' ');
};

export const getNavTypeString = (navType: string) => {
  switch (navType) {
    case 'driving-traffic':
      return 'drive with traffic';
    case 'walking':
      return 'walk';
    case 'cycling':
      return 'bike ride';
    default:
      return 'drive';
  }
};
