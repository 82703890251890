import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerMenuButton } from '../../../DrawerMenuButton';
import { PrintButton } from '../../../PrintButton';
import { ShareButton } from '../../../ShareButton';
import { adobeCompareDrawerLinkLocation } from '../../utility/compareDrawerConstants';
import { EditCompareButton } from '../EditCompare/EditCompareButton';

export const CompareDrawerHeaderStyle = styled('div', {
  variants: {
    view: {
      mobile: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      desktop: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
});
type Props = {
  handleOnClickCarret: any;
  handleFocusIn: any;
  handleTabOutOfSelection: any;
  isExpandedCardOpen: boolean;
  caretRef: React.RefObject<HTMLInputElement>;
  selectedCount: number;
  total: number;
  handleClickShare: any;
  modal: any;
  isEditCompareOpen: boolean;
  setIsEditCompareOpen: any;
  setNameOfButtonClickedLast: any;
};

export const CompareDrawerHeader = ({
  handleOnClickCarret,
  handleFocusIn,
  handleTabOutOfSelection,
  isExpandedCardOpen,
  caretRef,
  selectedCount,
  total,
  handleClickShare,
  modal,
  isEditCompareOpen,
  setIsEditCompareOpen,
  setNameOfButtonClickedLast,
}: Props) => {
  const { t } = useTranslation();

  return (
    <CompareDrawerHeaderStyle view="desktop">
      <Box
        css={{
          'abyss-box-root': {
            backgroundColor: 'transparent',
            padding: '0px',
          },
        }}
      >
        <Flex
          alignItems="left"
          css={{
            'abyss-flex-root': {
              marginTop: '26px',
            },
          }}
          justify="space-between"
        >
          {/* <Layout.Stack css={{ paddingTop: '4px' }}> */}
          <Layout.Group
            data-auto-testid="selection-show-mini-cards"
            data-testid="selection-show-mini-cards"
            onClick={handleOnClickCarret}
            onFocus={() => handleFocusIn()}
            onKeyDown={(e) => handleTabOutOfSelection(e)}
          >
            <Button
              aria-expanded={Boolean(isExpandedCardOpen)}
              css={{ all: 'unset' }}
              data-auto-testid="compare-providers-button-to-expand-and-close-compare-drawer"
              data-testid="compare-providers-button-to-expand-and-close-compare-drawer"
              ref={caretRef}
              tabIndex="0"
              variant="tertiary"
            >
              <Heading
                color="$gray8"
                css={{
                  paddingRight: '5px',
                  fontSize: '20.25px !important',
                  lineHeight: '24px !important',
                  fontWeight: '700 !important',
                }}
                offset={2}
              >
                {selectedCount} {t('of')} {total} {t('SELECTED')}
              </Heading>
              <IconMaterial
                data-auto-testid="compare-providers-icon-button-to-expand-and-close-compare-drawer"
                data-testid="compare-providers-icon-button-to-expand-and-close-compare-drawer"
                icon={
                  isExpandedCardOpen
                    ? 'keyboard_arrow_up'
                    : 'keyboard_arrow_down'
                }
                size="$md"
              />
            </Button>
          </Layout.Group>
          {/* </Layout.Stack> */}
          <Layout.Group alignItems="top">
            <div>
              <PrintButton
                locationForAnalytics={`modal:${adobeCompareDrawerLinkLocation}`}
                view="desktop"
              />
            </div>

            <div>
              <ShareButton
                data-auto-testid="compare-drawer-share-button"
                data-testid="compare-drawer-share-button"
                onClick={() => handleClickShare()}
                view="none"
              />
            </div>
            <div style={{ margin: '0px 4px 4px 4px' }}>
              <DrawerMenuButton
                data-auto-testid="mapview-compare-drawer-menu"
                data-testid="mapview-compare-drawer-menu"
                icon="close"
                locationForAnalytics="modal:compare drawer"
                onClick={() => modal?.open()}
                view="desktop"
              />
            </div>
          </Layout.Group>
        </Flex>
        <Flex alignItems="left">
          {isExpandedCardOpen && (
            <EditCompareButton
              isEditCompareOpen={isEditCompareOpen}
              setIsEditCompareOpen={setIsEditCompareOpen}
              setNameOfButtonClickedLast={setNameOfButtonClickedLast}
            />
          )}
        </Flex>
      </Box>
    </CompareDrawerHeaderStyle>
  );
};
