import { config } from '@abyss/web/tools/config';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ReverseCoverageTypesCodes } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { DataCardContainer } from '../../../../common/DataCard/DataCardContainer';
import { OutsideCountyAlert } from '../../../../common/DataCard/OutsideCountyAlert';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import {
  DataCardStyle,
  PopUpDataCardStyle,
} from '../../../../common/ResultSection/ResultSection.style';
import { isOutOfCounty } from '../../../../common/SnackCardContainer/utils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { FacilityLocationsResponse } from '../../../../models/FacilityDetails';
import { Provider } from '../../../../models/Provider';
import { ProviderLocation } from '../../../../models/ProviderDetails';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
/* eslint-disable no-param-reassign */
import { closePopUp } from '../../../../utils/map.utils';
import { PopupStyle } from './MapView.styled';

type Props = {
  headers?: ResponseHeaders;
  practitioner?: Provider;
  disableCost?: boolean;
  locationForAnalytics?: string;
  pageNumber?: number;
  selectedFilters?: string;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  updatePin?(
    content: ProviderLocation | FacilityLocationsResponse | null
  ): void;
  isMNR?: boolean;
};
export const Popup = ({
  headers,
  practitioner,
  disableCost,
  locationForAnalytics,
  pageNumber,
  selectedFilters,
  selectLocation,
  updatePin,
  isMNR,
}: Props) => {
  const [mapviewEnhancements] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);

  const [popupContainer, setPopupContainer] = useState<any>(null);

  const coverageType = useCoverageType();
  const lagoonData = useLagoon('ui-messaging')();
  const nationaAncillaryNoLocation = find(lagoonData, {
    key: ConstantsLagoon.NATIONAL_ANCILLARY_NO_LOCATION_MODAL,
  });
  const visionOnlineProvidersNoLocation = find(lagoonData, {
    key: ConstantsLagoon.ONLINE_PROVIDERS_VISION_NO_LOCATION_MODAL,
  });

  const [countySearchAlertFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT,
  ]);
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();

  const { t } = useTranslation();

  const enterIntoMapPopUp = (e, rootElem) => {
    if (
      e.key === 'Tab' &&
      (rootElem as HTMLElement)?.style.visibility === 'visible'
    ) {
      (rootElem as HTMLElement).focus();
    }
  };

  const exitMapPopUp = (e, practitioner, rootElem) => {
    if (e.key === 'Escape') {
      const currentPinPoint = document.querySelector(
        `#custom-marker-${practitioner.providerId}`
      );
      const tooltip = document.querySelector('.mapboxgl-popup-tip');

      closePopUp(currentPinPoint, rootElem, tooltip, updatePin);
    }
  };

  useEffect(() => {
    let rootElem: Element | null;
    let mapElem: Element | null;

    const handleMapElemFocus = () => {
      (mapElem as HTMLElement).addEventListener(
        'keydown',
        handleMapElemKeydown
      );
    };

    const handleMapElemKeydown = (e: any) => {
      enterIntoMapPopUp(e, rootElem);
    };

    const handleRootElemKeydown = (e: any) => {
      if (practitioner && rootElem) {
        exitMapPopUp(e, practitioner, rootElem);
      }
    };

    if (practitioner) {
      rootElem = document.querySelector('.datacard-popup');
      if (rootElem) {
        (rootElem as HTMLElement).tabIndex = 0;
        (rootElem as HTMLElement).style.height = 'fit-content';
        (rootElem as HTMLElement).style.width = mapviewEnhancements
          ? '151px'
          : 'fit-content';
        (rootElem as HTMLElement).style.visibility = 'visible';
        mapElem = document.querySelector('.mapboxgl-canvas');

        mapElem?.addEventListener('focus', handleMapElemFocus);

        (rootElem as HTMLElement).addEventListener(
          'keydown',
          handleRootElemKeydown
        );
      }
      setPopupContainer(rootElem);
      document.querySelectorAll('.mapboxgl-popup-tip').forEach((el) => {
        (el as HTMLElement).style.display = mapviewEnhancements
          ? 'none'
          : 'block';
        if (!practitioner?.address?.line?.[0]) {
          (el as HTMLElement).style.visibility = 'hidden';
          (el as HTMLElement).style.marginTop = '-30%';
        }
      });
    }

    return () => {
      if (rootElem && mapElem) {
        mapElem.removeEventListener('keydown', handleMapElemKeydown);
        mapElem.removeEventListener('focus', handleMapElemFocus);
        rootElem.removeEventListener('keydown', handleRootElemKeydown);
      }
    };
  }, [practitioner]);

  const handleDirectionClick = (provider) => {
    if (selectLocation)
      selectLocation(
        provider.locationId,
        provider.longitude,
        provider.latitude
      );
  };

  const displayOutOfCntyIndicator = (provider) => {
    const isOutOfCnty = isOutOfCounty(
      isCountySearch,
      name,
      stateCode,
      provider
    );
    return (
      countySearchAlertFlag &&
      isOutOfCnty && (
        <OutsideCountyAlert
          county={name?.split(',')[0]}
          isOutOfCounty={isOutOfCnty}
        />
      )
    );
  };

  const showPopup = () =>
    practitioner?.address?.line?.[0] ? (
      <React.Fragment>
        <DataCardContainer
          css={mapviewEnhancements ? PopUpDataCardStyle : DataCardStyle}
          disableCost={disableCost}
          handleDirectionClick={() => handleDirectionClick(practitioner)}
          headers={headers}
          isMNR={isMNR}
          locationForAnalytics={locationForAnalytics}
          pageNumber={pageNumber}
          popupContainer={popupContainer && mapviewEnhancements}
          practitioner={practitioner || {}}
          selectedFilters={selectedFilters}
        />
        {displayOutOfCntyIndicator(practitioner)}
      </React.Fragment>
    ) : (
      <Flex
        css={{
          display: 'flex',
          marginTop: '-30%',
          width: '623px',
          padding: '32px 24px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
          borderRadius: '10.08px',
          background: 'rgba(255, 255, 255, 0.80)',
          '@screen < $md': {
            width: '311px',
            padding: '40px 32px',
            borderRadius: '12px',
          },
        }}
      >
        <img
          alt="national ancillary facility"
          src={`${config(
            'CDN_BASE_URL'
          )}/cdn/assets/images/national_ancillary_facility.png`}
        />
        <Text
          css={{
            'abyss-text-root': {
              alignSelf: 'stretch',
              fontSize: '18px',
              fontWeight: '$bold',
              lineHeight: '24px',
              color: '#000',
            },
          }}
          data-auto-testid="no-location-text"
          data-testid="no-location-text"
        >
          {coverageType === ReverseCoverageTypesCodes.VISION
            ? t('noLocationInfoVision')
            : t('Find providers near you')}
        </Text>
        <Text
          css={{
            'abyss-text-root': {
              alignSelf: 'stretch',
              fontSize: '18px',
              fontWeight: '$medium',
              lineHeight: '24px',
              color: '#000',
            },
          }}
        >
          {coverageType === ReverseCoverageTypesCodes.VISION
            ? visionOnlineProvidersNoLocation?.message
            : nationaAncillaryNoLocation?.message}
        </Text>
      </Flex>
    );
  return (
    <React.Fragment>
      {popupContainer !== null &&
        createPortal(<PopupStyle>{showPopup()}</PopupStyle>, popupContainer)}
    </React.Fragment>
  );
};
