import { lazy } from 'react';

export const BrowseLabAndServices = lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "BrowseLab" */ '../../../../common/BrowseLabsAndServices'
    )
  ).BrowseLabAndServices,
}));

export const RecentActivityAndVisit = lazy(async () => ({
  default: (await import('../../../../common/RecentActivityAndVisit'))
    .RecentActivityAndVisit,
}));

export const ReferralCard = lazy(async () => ({
  default: (await import('../../../../common/ReferralCard')).ReferralCard,
}));

export const TalkToNurseCard = lazy(async () => ({
  default: (await import('../../../../common/TalkToNurseCard')).TalkToNurseCard,
}));

export const ThingsToKnowCard = lazy(async () => ({
  default: (await import('../../../../common/ThingsToKnowCard'))
    .ThingsToKnowCard,
}));

export const TopPrimaryCareMedicalGroupsCards = lazy(async () => ({
  default: (await import('../../../../common/TopPrimaryCardMedicalGroups'))
    .TopPrimaryCareMedicalGroupsCards,
}));

export const TopPrimaryCareProviderCards = lazy(async () => ({
  default: (await import('../../../../common/TopPrimaryCardProviders'))
    .TopPrimaryCareProviderCards,
}));

export const FQHCandRHCCards = lazy(async () => ({
  default: (await import('../../../../common/FQHCandRHCCards'))
    .FQHCandRHCCards,
}));

// comment out this section as it is not required at the moment
// const TopPrimaryCareClinicsSection = lazy(async () => ({
//   default: (await import('../../../../common/TopPrimaryCareClinicsSection'))
//     .TopPrimaryCareClinicsSection,
// }));

export const BHTierOneMessage = lazy(async () => ({
  default: (await import('../../../../common/BHTierOneMessage/BHTierOneMessage'))
    .BHTierOneMessage,
}));
export const BrowseBehavioralHealthProviders = lazy(async () => ({
  default: (await import('../BrowseBehavioralHealthProviders'))
    .BrowseBehavioralHealthProviders,
}));

export const BrowseHealthSupplies = lazy(async () => ({
  default: (await import('../BrowseHealthSupplies')).BrowseHealthSupplies,
}));

export const BrowseMedicalSpecialists = lazy(async () => ({
  default: (await import('../BrowseMedicalSpecialists'))
    .BrowseMedicalSpecialists,
}));

export const BrowseDentalProviders = lazy(async () => ({
  default: (await import('../BrowseDentalProviders')).BrowseDentalProviders,
}));

export const GalileoCard = lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "GalileoCard" */ '../../../../common/VirtualCareCard'
    )
  ).GalileoCard,
}));

export const DisclaimersContainer = lazy(async () => ({
  default: (
    await import('../../../../common/DisclaimersContainer/DisclaimersContainer')
  ).DisclaimersContainer,
}));

export const Notifications = lazy(async () => ({
  default: (await import('../../../../common/Notifications')).Notifications,
}));

export const PrimaryCareProvider = lazy(async () => ({
  default: (await import('../../../../common/PrimaryCareProvider'))
    .PrimaryCareProvider,
}));

export const CareOptions = lazy(async () => ({
  default: (await import('../../../../common/CareOptions')).CareOptions,
}));

export const ChipsGeneralContent = lazy(async () => ({
  default: (await import('../../../../common/ChipsGeneralContent'))
    .ChipsGeneralContent,
}));

export const CommonlySearchedCard = lazy(async () => ({
  default: (await import('../../../../common/CommonlySearched'))
    .CommonlySearchedCard,
}));
