import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Text } from '@abyss/web/ui/Text';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';

export const FeedbackFormContainer = styled('div', {
  padding: '0 24px',
  '@media (max-width: 1248px)': {
    padding: 0,
  },
});

export const StyledDrawer = styled(Drawer, {
  zIndex: '999 !important',
  '.abyss-modal-header-container': {
    width: 'fit-content',
  },
  '.abyss-modal-content-container': {
    borderRadius: 0,
    width: '408px',
    '@screen < $sm': {
      top: '25px',
      height: 'calc(100vh - 26px)',
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
      color: '$primary1',
      border: '1px solid $primary1',
    },
  },
  '.abyss-modal-close-button': {
    '@screen < $sm': {
      top: '12px',
      right: '12px',
    },
  },
  '.abyss-modal-body': {
    scrollbarWidth: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const StyledTextInputArea = styled(TextInputArea, {
  width: '100%',

  '&::placeholder': {
    color: '$gray5',
  },

  '&.abyss-text-input-area-label-wrapper': {
    '.abyss-layout-group': {
      width: '100%',
    },
    '.abyss-text-input-area-max-length-label': {
      textAlign: 'right',
    },
  },
});

export const DrawerSubtitle = styled(Text, {
  fontWeight: 600,
  lineHeight: '20px',
  color: '$gray8',
});

export const EmailInputStyle = {
  flexDirection: 'row',
  alignItems: 'center',
  border: `1px solid $searchBorderColor`,
  'abyss-text-input-left-add-on': {
    backgroundColor: '$primary2',
    borderRight: 0,
  },

  'abyss-text-input': {
    height: '40px',
  },
};
