/* eslint-disable no-console */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useContext, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { PortalContext } from '../../context/PortalContext';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { useSpecialtiesResult } from '../../hooks/useSpecialties';
import { BhProviderIcons } from '../../models/Lagoon';
import { ProviderType } from '../../models/ProviderDetails';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { getBhIcon } from '../../utils/iconography.utils';
import {
  capitalizeEachWord,
  parseProviderName,
} from '../../utils/providerNames.utils';
import { getShowCost } from '../../utils/showCost.utils';
import { ReverseCoverageTypesCodes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { CostIndicatorPopover } from '../DataCard/CostIndicatorPopover';
import { ImageComponent } from '../ImageComponent';
import {
  AvatarDetailsPage,
  CostIndicatorIconStyle,
  DetailsSectionWrap,
  DetailsSubSection,
  MarginStyle,
  ProviderIndicatorSection,
  ProviderSubtitle,
  ProviderSubtitleSection,
  ProviderTitle,
  displayVirtualVisitStyle,
} from './DetailsPageHeader.styled';
import { InNetworkStatusLabel } from './InNetworkStatusLabel';
import { ProviderDetailsRating } from './ProviderDetailsRating';
import { VirtualCareOfferedLabel } from './VirtualCareOfferedLabel';

export type Props = {
  costEnableFlag: boolean;
  suppressCostEstimates: boolean;
  suppressRatings: boolean;
  imageLocation?: string;
  providerType?: ProviderType;
  displayVirtualCareIcon: boolean;
  providerName: string;
  professionalDesignation?: string;
  nameAttrs: string;
  primarySpeciality: string[];
  inNetwork: string;
  rating?: string;
  virtualCareOffered: boolean;
  costIndicator: string;
  surveyCount?: number;
  coverageType?: string;
  handleTabChange: (a: number) => void;
};

export const DetailsSection = ({
  costEnableFlag,
  costIndicator,
  suppressCostEstimates,
  suppressRatings,
  imageLocation,
  providerType,
  displayVirtualCareIcon,
  providerName,
  professionalDesignation,
  nameAttrs,
  primarySpeciality,
  inNetwork,
  rating,
  virtualCareOffered,
  surveyCount,
  coverageType,
  handleTabChange,
}: Props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const imgSrc = imageLocation;
  const mobileScreen = useMediaQuery(phoneOnly);
  const [providerRatings] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_PROVIDER_RATINGS,
  ]);
  const { portalData } = useContext(PortalContext);
  const portalShowCost = getShowCost(portalData);

  const bhProviderIcons: BhProviderIcons[] = useLagoon('bh_provider_icons')();
  const { data: bhSpecialtyCodes = [] } = useSpecialtiesResult({
    coverageType: 'B',
  });

  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );

  const isVirtualCareUnetRailroadSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_RAILROAD
  );

  const suppressVirtualCare =
    isVirtualCareQuickCareSuppressed ||
    (coverageType !== ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
      isVirtualCareUnetRailroadSuppressed);

  const bhIcon =
    coverageType === 'B'
      ? getBhIcon([], primarySpeciality, bhProviderIcons, bhSpecialtyCodes)
      : '';
  const renderAboutTab = () => {
    if (rating !== null) {
      handleTabChange(0);
    }
  };
  const { specialtySearch = '' } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      specialtySearch: state.detailsState.specialtySearch,
    }))
  );

  const { searchBySpeciality } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchBySpeciality: state.typeaheadSearchStore.searchBySpeciality,
    }))
  );

  if (specialtySearch && searchBySpeciality !== false) {
    primarySpeciality = primarySpeciality?.filter(
      (value) => value !== specialtySearch
    );
    primarySpeciality = primarySpeciality
      ? [specialtySearch, ...primarySpeciality]
      : [specialtySearch];
  }

  return (
    <DetailsSectionWrap>
      <Flex
        css={{
          'abyss-flex-root': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '-20px',
          },
        }}
      >
        <AvatarDetailsPage
          data-auto-testid="provider-profile-picture"
          data-testid="provider-profile-picture"
        >
          {ImageComponent({
            imgRef,
            providerType,
            imgSrc,
            bhIcon,
            width: '84px',
            height: '84px',
          })}
        </AvatarDetailsPage>
        {displayVirtualCareIcon && !suppressVirtualCare && (
          <Flex
            css={displayVirtualVisitStyle}
            data-auto-testid="videocam-icon"
            data-testid="videocam-icon"
          >
            <IconMaterial
              color="$primary1"
              icon="videocam"
              size={16}
              variant="filled"
            />
          </Flex>
        )}
      </Flex>
      <DetailsSubSection>
        <ProviderTitle
          color="$primary1"
          data-auto-testid="provider-title"
          data-testid="provider-title"
          display="h5"
        >
          {parseProviderName(
            providerName,
            providerType,
            professionalDesignation,
            nameAttrs
          )}
        </ProviderTitle>
        <ProviderSubtitleSection
          data-auto-testid="provider-subtitle"
          data-testid="provider-subtitle"
        >
          <Flex>
            {primarySpeciality?.length > 0 && (
              <ProviderSubtitle
                color="$primary1"
                fontWeight="$medium"
                size={mobileScreen ? '$md' : '$lg'}
              >
                {capitalizeEachWord(primarySpeciality[0])}
              </ProviderSubtitle>
            )}
            {/* Renders InNetworkStatus and Cost Indicator, Virtual Care labels in the same row for viewports greater than mobile. */}
            {!mobileScreen && (
              <Flex direction="row">
                <InNetworkStatusLabel inNetwork={inNetwork} />
                <CostIndicatorIconStyle>
                  {portalShowCost &&
                    costEnableFlag &&
                    costIndicator &&
                    !suppressCostEstimates &&
                    providerType === ProviderType.PRACTITIONER && (
                      <MarginStyle>
                        <CostIndicatorPopover
                          callFrom="detailpageheader"
                          costIndicator={costIndicator}
                          providerType={providerType}
                        />
                      </MarginStyle>
                    )}
                </CostIndicatorIconStyle>
                {!suppressVirtualCare && (
                  <Layout.Group data-testid="Offers-virtual-care-label">
                    <VirtualCareOfferedLabel
                      virtualCareOffered={displayVirtualCareIcon}
                    />
                  </Layout.Group>
                )}
              </Flex>
            )}
          </Flex>
          {/* Renders InNetworkStatus and Cost Indicator in the same row, and renders Virtual Care Offered label on next row for mobile view only. */}
          {mobileScreen && (
            <Flex
              css={{
                marginTop: '12px',
              }}
            >
              <InNetworkStatusLabel inNetwork={inNetwork} />
              <CostIndicatorIconStyle>
                {portalShowCost &&
                  costEnableFlag &&
                  costIndicator &&
                  !suppressCostEstimates &&
                  providerType === ProviderType.PRACTITIONER && (
                    <MarginStyle>
                      <CostIndicatorPopover
                        callFrom="detailpageheader"
                        costIndicator={costIndicator}
                        providerType={providerType}
                        selectedProvider={providerName}
                      />
                    </MarginStyle>
                  )}
              </CostIndicatorIconStyle>
            </Flex>
          )}
          {/* Pushing Virtual care label to next row for mobile view only. */}
          {virtualCareOffered && mobileScreen && !suppressVirtualCare ? (
            <ProviderIndicatorSection>
              <VirtualCareOfferedLabel
                virtualCareOffered={displayVirtualCareIcon}
              />
            </ProviderIndicatorSection>
          ) : null}
          {/* Pushing Starts Reviews in the end for mobile view only. */}
          <Flex
            alignItems="center"
            data-testid="provider-detail-clickable-reviews"
            onClick={renderAboutTab}
          >
            {!suppressRatings &&
              mobileScreen &&
              providerType === ProviderType.PRACTITIONER &&
              (providerRatings ? (
                <ProviderIndicatorSection>
                  <ProviderDetailsRating
                    hideNumber
                    providerType={providerType}
                    rating={rating}
                    surveyCount={surveyCount}
                  />
                </ProviderIndicatorSection>
              ) : (
                <ProviderIndicatorSection>
                  <ProviderDetailsRating
                    providerType={providerType}
                    rating={rating}
                  />
                </ProviderIndicatorSection>
              ))}
          </Flex>

          {/* Renders Starts reviews in the same row as In Network and Virutal Care for viewports different than mobile only, align stars to the rigth end of the container. */}
          <Flex
            alignItems="center"
            css={{
              '.abyss-flex-root': {
                cursor: rating === null ? '' : 'pointer',
              },
            }}
            data-testid="provider-detail-clickable-reviews"
            onClick={renderAboutTab}
          >
            {!mobileScreen &&
              providerType === ProviderType.PRACTITIONER &&
              !suppressRatings &&
              (providerRatings ? (
                <ProviderDetailsRating
                  hideNumber
                  providerType={providerType}
                  rating={rating}
                  surveyCount={surveyCount}
                />
              ) : (
                <ProviderDetailsRating
                  providerType={providerType}
                  rating={rating}
                />
              ))}
          </Flex>
        </ProviderSubtitleSection>
      </DetailsSubSection>
    </DetailsSectionWrap>
  );
};
