import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import { find, pick } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import { StoreKeys } from '../../../..//hooks/useStore/state';
import { useStore } from '../../../..//hooks/useStore/useStore';
import { adobeImpressionTrackEvent } from '../../../../common/AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeSearchModifiedEvent } from '../../../../common/AdobeTagging/adobeSearchModifiedEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  CareCategories,
  Constants,
  CoverageTypesCodes,
  PseudoRollupCodeTableKeys,
  ReverseCoverageTypesCodes,
  SHOULD_GET_HG_DATA,
  TypeaheadChipCategoriesMapping,
  errorCodeRouteMap,
} from '../../../../common/Constants';
import { FeatureFlags } from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DisclaimersContainer } from '../../../../common/DisclaimersContainer/DisclaimersContainer';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton/SectionSkeleton';
import { NullResultsPage } from '../../../../common/NullResultsPage';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getCategoryFromChipCategoriesCount } from '../../../../common/RecentActivity/helper';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import { convertObjectToUrlParams } from '../../../../common/Utils';
import {
  convertCoverageType,
  getIndicatorsForImpressions,
  getSearchLocation,
  getTier1Indicator,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../../../../common/Utils/getFeatureFlag';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { PortalContext } from '../../../../context/PortalContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  usePlanYear,
  useSaveRecentSearches,
} from '../../../../hooks/useRecentSearches/useRecentSearches';
import { useSpecialtiesResult } from '../../../../hooks/useSpecialties';
import { useTypeaheadPseudoRollup } from '../../../../hooks/useTypeaheadPseudoRollup';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { Option } from '../../../../models/ProviderSearch';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useErrorStore } from '../../../../store/useErrorStore';
import { ErrorStore } from '../../../../store/useErrorStore/errorStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../../../store/useSearchStore';
import { SearchState } from '../../../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import {
  getEmployeeAssistanceProgramValue,
  getSuppressFacilityFlags,
  getVirtualCareValue,
} from '../../../../utils/providerDetails.utils';
import {
  getAutoIncrementRadius,
  getLagoonConfigValue,
  getSearchRadius,
} from '../../../../utils/providerSearch.utils';
import { toCapitalCase } from '../../../../utils/string.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getIsOxfordAndUSP,
  getLoggedInMember,
  getNetworkIdsForPES,
  isOHCNSMember,
} from '../../../../utils/user.utils';
import { NullPageContainerStyled } from '../NullSpecialtyPage/NullSpecialtyPage.styles';
import { GetCategoryCountProps } from './categoryCountTypes';
import { SearchedResultSectionData } from './SearchedResultSectionData';
import {
  getNoOfResultsForAnalytics,
  getProviderSearchPayloadWithRecriprocityId,
  getProviderType,
  getProviderTypesFromDisplayedResults,
  groupSpecialtyRollupCodes,
  isEmptyResult,
  resolveCoverageType,
  resolveIncludeSpecialityRollupCodesAndUpdateStore,
} from './searchUtils';
import { useParallelSearchResultsCategoriesCount } from './useParallelSearchResultsCategoriesCount';
import { useSearchResultsCategoriesCount } from './useSearchResultsCategoriesCount';
import { useSearchResultsProviderResults } from './useSearchResultsProviderResults';
import {
  IsSearchResultsLoading,
  checkForPseudoRollupCodes,
  compareAndReturn,
  getAOEOrSpecialityById,
  getAllProvidersData,
  getAoeCodesForEAP,
  getDntFiltersFromQueryParams,
  getEapBHProgramId,
  getFirstEnabledChip,
  getModifiedPayloadForProviderSearch,
  getPreferredFacilityFilter,
  isShowProviderGroups,
} from './utils';

interface GlobalSearchParams {
  includeSpecialityRollupCodes: string;
}

const hasResultsLoadedForParallelCategoryCount =
  (parallelCategoryCountResults: {
    isNameResultsLoaded: boolean;
    isSpecialityResultsLoaded: boolean;
    nameResults: any[];
    specialityResults: any[];
  }) =>
    parallelCategoryCountResults.isNameResultsLoaded &&
    parallelCategoryCountResults.isSpecialityResultsLoaded &&
    (parallelCategoryCountResults.nameResults.length > 0 ||
      parallelCategoryCountResults.specialityResults.length > 0);

const getSpecialtyCode = (pesKeyword, searchCategory, specialtyCodes) =>
  (pesKeyword ?? Array.isArray(searchCategory))
    ? undefined
    : specialtyCodes[searchCategory?.toUpperCase()];

const getSelectedCoverageType = (value: string) =>
  find(Constants.MOCK_CARE_CATEGORIES, {
    value,
  })?.coverageType || CoverageTypesCodes.MEDICAL;

const getPesSearchTerm = (
  bhProgramFacilityAgg,
  includeSpecialityRollupCodes,
  search
) =>
  bhProgramFacilityAgg && !includeSpecialityRollupCodes
    ? ''
    : compareAndReturn(includeSpecialityRollupCodes, search);

export const SearchResults = () => {
  const { t } = useTranslation();
  const [isResultsLoading, setIsResultsLoading] = useState(true);
  const [specialtySearch, setSpecialtySearch] = useState(false);
  const [uesTranslation, setUEStranslation] = useState({});
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();

  const deepLinkMapping = useLagoon(Constants.LAGOON_TABLE.DEEPLINK_MAPPING)();
  const uiMessaging = useLagoon('ui-messaging')();
  const typeaheadPreferenceCodes = useLagoon('typeahead-preference')();

  const [currentChip, setCurrentChip] = useState<string>(CareCategories.ALL);
  const [noCategoryDataFound, setNoCategoryDataFound] = useState(false);
  const [emptyResults, setEmptyResults] = useState(false);
  const [isSearchModified, setIsSearchModified] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isPageTracked, setPageTracked] = useState<boolean>(false);
  const [showProviders, setShowProviders] = useState(true);
  const [showProviderGroups, setShowProviderGroups] = useState(true);
  const [showFacilities, setShowFacilities] = useState(true);
  const [isInitialChipSelected, setIsInitialChipSelected] = useState(false);

  const { navigate } = useRouter();
  // initialize store values from url params for search results page
  const { providerType = '', chipValue: initStoreValue } = useInitializeStore(
    ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
  );

  const OHCNSpolicyIds = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;

  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { chipValue: storeChipValue, coverageType } = chipStore;

  const [params, setSearchParams] = useSearchParams();
  const [newRollupcodesFromQueryParams, setNewRollupcodesFromQueryParams] =
    useState<string>('');
  const [conditionAoeCodes, setConditionAoeCodes] = useState<string[]>([]);
  const [areaOfExpertise, setAreaOfExprtise] = useState<string[]>([]);
  const [specialtyCodeFromQuery, setSpecialtyCodeFromQuery] = useState<
    string[]
  >([]);
  const [genderCode, setGenderCode] = useState<Option[]>([]);
  const [virtualCareCode, setVirtualCareCode] = useState<Option>();

  const [globalSeachParams] = useSessionStorage<GlobalSearchParams>(
    Constants.STORAGE_KEYS.SESSION.PSX_GLOBAL_SEARCH,
    { includeSpecialityRollupCodes: '' }
  );

  const isGlobalSearch =
    params.get(Constants.SOURCE) === Constants.GLOBAL_SEARCH;

  const getChipValue = () => storeChipValue || initStoreValue;

  const chipValue = getChipValue();

  const { dependentSeqNbr, choosePCP } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const {
    linkName = '',
    originLinkNameForAnalytics = '',
    setAnalyticsState,
  } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      originLinkNameForAnalytics:
        state.analyticsState.originLinkNameForAnalytics,
      linkName: state.analyticsState.linkName,
      setAnalyticsState: state.setAnalyticsState,
    }))
  );

  const { typeaheadSearchStore, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const {
    searchType = '',
    userZip = '',
    category: searchCategory = '',
    pesKeyword,
    search = '',
    pseudoVerticals = '',
    combinedIncludeSpecialityRollupCodes,
    searchMethod = '',
    searchTerm = '',
    keyword = '',
    medicalSpeciality,
    coverageTypes = '',
    isMixedSuggestions,
    isUserTextSearch,
    previous,
    suggestion,
    sectionType,
    specialtyCategory,
    condition,
    aoeCodes: typeaheadAoeCodes = [],
    typeaheadLinkName,
    userSearchTerm,
    bhProgramFacilityAgg,
    acceptsEap,
  } = typeaheadSearchStore;

  const setupAoeCodesForEAP = (
    eapSpecialitySearch: boolean,
    eapBHProgramFlag: boolean
  ) => {
    if (eapSpecialitySearch && !eapBHProgramFlag) {
      const aoeCodes = getAoeCodesForEAP() || [];
      const existingCodes = new Set(typeaheadAoeCodes);
      aoeCodes.forEach((code) => {
        if (!existingCodes.has(code)) {
          typeaheadAoeCodes.push(code);
          existingCodes.add(code);
        }
      });
    }
    return;
  };

  const includeSpecialityRollupCodesProps =
    resolveIncludeSpecialityRollupCodesAndUpdateStore(
      isGlobalSearch,
      globalSeachParams,
      combinedIncludeSpecialityRollupCodes,
      setTypeaheadSearchStore
    );

  useEffect(() => {
    setNoCategoryDataFound(false);
    setIsResultsLoading(true);
    setIsInitialChipSelected(false);
  }, [typeaheadLinkName, userSearchTerm]);

  useEffect(() => {
    if (searchType === Constants.SEARCH_TYPES.NAME) {
      setSpecialtySearch(false);
    }
  }, [searchType]);

  // setting to false as a temp fix to avoid category count error.Need to investigate further
  const { onlineRetailers } = useSearchStore(
    useShallow((state: SearchState) => ({
      onlineRetailers: state.searchStore.onlineRetailers,
    }))
  );

  const { setErrorStore } = useErrorStore(
    useShallow((state: ErrorStore) => ({
      setErrorStore: state.setErrorStore,
    }))
  );

  const {
    contextValue: value,
    setStoreChipValue,
    setStoreCoverageType,
  } = useChipStore(
    useShallow((state: ChipState) => ({
      contextValue: state.chipValue,
      setStoreChipValue: state.setChipValue,
      setStoreCoverageType: state.setCoverageType,
    }))
  );
  const { clearSearchFilters } = useContext(SearchFilterContext);
  const { isCountySearch } = useContext(CountySearchContext);
  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const ddpCodeUser = getLoggedInMember()?.ddpCode;
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const enableNavigateToCorrectCategory = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NAVIGATE_TO_CORRECT_CATEGORY
  );

  const disablePCPIndAndANP = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PCP_IND_AND_ANP
  );

  const enableBHVirtualcare: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_HEALTH_VIRTUAL_CARE
  );
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const isPCPLandingPage =
    chipValue === Constants.CHIPS_CATEGORIES.PRIMARY_CARE ? true : false;

  const isPcpIndicator =
    disablePCPIndAndANP && isPCPLandingPage && !choosePCP
      ? null
      : choosePCP || value === CareCategories.PRIMARY_CARE;

  const acceptingNewPatients = isPcpIndicator ? true : undefined;

  const storedLocation = useGeoLocationStorage();
  const { longitude, latitude, name: location } = storedLocation;

  useEffect(() => {
    const UESData = getModuleOrDefault('en', 'uesSpecialityTranslations');
    setUEStranslation(UESData);
  }, []);

  const { data: specialtyCodes = [] } = useSpecialtiesResult({
    coverageType,
  });

  useEffect(() => {
    if (specialtyCategory && deepLinkMapping && specialtyCodes) {
      const specialtyCategoryIds = getDntFiltersFromQueryParams(
        specialtyCategory,
        deepLinkMapping
      );

      const specialityCodeTranslation = getModuleOrDefault(
        'en',
        'sourceSystemSpecialityTranslations'
      );

      const specialtyCodeArray = getAOEOrSpecialityById(
        specialtyCategoryIds,
        specialityCodeTranslation
      );

      setSpecialtyCodeFromQuery(specialtyCodeArray);
      const codes = [] as any;
      specialtyCodes.forEach((item) => {
        if (specialtyCategoryIds.includes(item.id)) {
          codes.push(item.codes);
        }
      });

      setNewRollupcodesFromQueryParams(codes.flat().join());
    }
  }, [specialtyCategory, specialtyCodes]);

  useEffect(() => {
    if (condition && deepLinkMapping) {
      const conditionIds = getDntFiltersFromQueryParams(
        condition,
        deepLinkMapping
      );
      const aoeTranslations = getModuleOrDefault('en', 'aoeTranslations');

      const aoeArray = getAOEOrSpecialityById(conditionIds, aoeTranslations);
      setAreaOfExprtise(aoeArray);

      const codes = Object.keys(aoeTranslations).filter((key) =>
        conditionIds.includes(aoeTranslations[key])
      );

      setConditionAoeCodes(codes);
    }
  }, [condition]);

  useEffect(() => {
    if (typeaheadSearchStore['gender']) {
      const genderCode = getGenderCode(typeaheadSearchStore['gender']);
      setGenderCode([genderCode]);
    }
  }, [typeaheadSearchStore['gender']]);

  const getGenderCode = (code: string) => {
    const genderValue: string = toCapitalCase(code);
    return {
      label: genderValue,
      value: genderValue,
      dnt_label: genderValue,
    };
  };

  useEffect(() => {
    if (typeaheadSearchStore['virtualCare']) {
      const virtualCareCode = getVirtualCareCode();
      setVirtualCareCode(virtualCareCode);
    }
  }, [typeaheadSearchStore['virtualCare']]);

  const getVirtualCareCode = () => ({
    label: t('FILTER_OPTIONS.VirtualCare.Y'),
    dnt_label: translationsEnJson.FILTER_OPTIONS.VirtualCare.Y,
    value: true,
  });

  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const dependentInfo = getDependentInfo(dependentSeqNbr);

  const specialtyCode: string = getSpecialtyCode(
    pesKeyword,
    searchCategory,
    specialtyCodes
  );

  const checkForPortalRollupCodes: boolean =
    newRollupcodesFromQueryParams !== '';

  const setIncludeSpecialityRollupCodesFn = () =>
    checkForPortalRollupCodes
      ? newRollupcodesFromQueryParams
      : (specialtyCode ?? includeSpecialityRollupCodesProps);

  const includeSpecialityRollupCodes = setIncludeSpecialityRollupCodesFn();

  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );

  const hasOnlyPseudoRollupCodes = checkForPseudoRollupCodes(
    includeSpecialityRollupCodes
  );
  const shouldGetHGData = SHOULD_GET_HG_DATA;
  const [cleanUpProviderCalls, autoCompleteParallelSearchFlow] = useFeatureFlag(
    [
      ConstantsLagoon.FEATURE_FLAGS.CLEANUP_PROVIDER_CALLS,
      ConstantsLagoon.FEATURE_FLAGS.AUTOCOMPLETE_PARALLEL_SEARCHFLOW,
    ]
  );

  const getSearchTitle = (defaultSearchTitle) => {
    if (
      searchType === Constants.SEARCH_TYPES.LOCATION &&
      search !== Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS
    ) {
      return userZip;
    }
    return defaultSearchTitle;
  };

  const searchTitle: string = getSearchTitle(search);

  const getFilterValue = (name: string) => {
    const filterValueFromStore = typeaheadSearchStore[name];
    if (!filterValueFromStore) {
      return undefined;
    }
    return filterValueFromStore;
  };

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const pseudoVerticalsDetails = useTypeaheadPseudoRollup(
    pseudoVerticals,
    suggestion,
    isOXMember
  );

  const llmFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  );

  const virtualCareValue = getVirtualCareValue(
    pseudoVerticalsDetails,
    PseudoRollupCodeTableKeys.FILTER_NAME
  );

  const preferredFacilityFilter = getPreferredFacilityFilter(
    pseudoVerticals,
    pseudoVerticalsDetails
  );

  const enableBHAoeTypeahead = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD
  );

  const eapBHProgramFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EAP_FILTER_USING_BH_PROGRAM
  );

  const eapSpecialitySearch = getEmployeeAssistanceProgramValue(
    pseudoVerticalsDetails,
    PseudoRollupCodeTableKeys.FILTER_NAME
  );

  // Process pre-select EAP filter based on portal config or EAP pseudo code search or therapy drill down
  const { portalData } = useContext(PortalContext);
  const preSelectEapFilter =
    portalData?.portalConfig?.preSelectEapFilter ||
    acceptsEap ||
    eapSpecialitySearch;
  setupAoeCodesForEAP(eapSpecialitySearch, eapBHProgramFlag);

  const customParams = {
    search: searchTitle,
    ...(conditionAoeCodes.length > 0 && { conditionAoeCodes }),
    isConditionSearch: condition && conditionAoeCodes?.length > 0,
    typeaheadAoeCodes,
    enableBHAoeTypeahead,
    preSelectEapFilter,
    acceptsEap,
    providerType,
    includeSpecialityRollupCodes:
      !isUserTextSearch && includeSpecialityRollupCodes
        ? includeSpecialityRollupCodes
        : '',
    searchType,
    pcpIndicator: isPcpIndicator,
    isPCPLandingPage,
    useNewAgeAndGenderRollups: disablePCPIndAndANP,
    searchRadius: getSearchRadius(searchRadius),
    autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
    acceptingNewPatients,
    ...(dependentSeqNbr && {
      memberDemographics: {
        gender: dependentInfo?.demographics.gender,
        dateOfBirth: dependentInfo?.demographics.dateOfBirth,
      },
    }),
    coverageType,
    keyword,
    onlineRetailers,
    isExtendedSearchEnabled: true,
    isUserTextSearch,
    shouldGetHGData,
    ...(choosePCP && {
      reciprocityId: getNetworkIdsForPES(
        currentMember,
        coverageType,
        featureFlags
      ),
    }),
    gender: getFilterValue('gender'),
    virtualCare: getFilterValue('virtualCare'),
    pseudoRollupCode: pseudoVerticalsDetails?.isPseudoRollupSearch,
    preferredFacilities: preferredFacilityFilter?.length
      ? preferredFacilityFilter
      : undefined,
    ...(isEmpty(providerType) && { typeaheadSearch: searchTitle }),
    ...(virtualCareValue &&
      enableBHVirtualcare && {
        virtualCare: virtualCareValue,
      }),
  };

  const [categoryCount, getCategoryCountResults] =
    useSearchResultsCategoriesCount(customParams);
  const [parallelCategoryCountResults, getParallelCategoryCountResults] =
    useParallelSearchResultsCategoriesCount(customParams);
  const {
    data,
    error,
    providerIsLoading,
    providersResult,
    medGrpResult,
    organizationResult,
    orgIsLoading,
    medGrpIsLoading,
    medGrpData,
    providerHasError,
    getProviderSearchResults,
  } = useSearchResultsProviderResults(customParams);
  const allProviders = getAllProvidersData(data, value);

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'overview',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
    providerBlock: {
      type: convertCoverageType(coverageType),
    },
    impressionBlock: {
      type: 'provider',
      indicator:
        allProviders?.length > 0
          ? getIndicatorsForImpressions(
              allProviders,
              featureFlags,
              false,
              {
                suppressPremiumCare,
                ddpCodeUser,
                suppressFacilityFlags,
                memberHasRPK,
                isCountySearch,
              },
              currentMember
            )
          : '',
      providerTier: getTier1Indicator(showTierProviderTag, allProviders),
    },
  });

  const memberCoverageTypes = getCoverageTypes(currentMember);

  const {
    data: chipCategoryData,
    isLoading: isCategoryCountLoading,
    corelationId: categoryCountCoRelationId,
  } = categoryCount;

  const selectedCategory = Constants.CARE_CATEGORIES_TYPEAHEAD.find(
    (category) => category.label === value
  );

  const providerQueryTypes: any =
    parallelCategoryCountResults.specialityResults.filter(
      (item) => item.chipCategory === selectedCategory?.id
    )[0]?.providerTypes || [];

  const getProviderSearchPayload = (
    payloadWithRecriprocityId,
    isSpecialtySearch,
    searchType,
    isUserTextSearch,
    typeaheadAoeCodes,
    enableBHAoeTypeahead
  ) => {
    const codeByGroup = !pseudoVerticalsDetails?.isPseudoRollupSearch
      ? groupSpecialtyRollupCodes(
          includeSpecialityRollupCodes,
          searchType,
          isSpecialtySearch
        )
      : undefined;
    return getModifiedPayloadForProviderSearch(
      payloadWithRecriprocityId,
      codeByGroup,
      uesTranslation,
      search,
      keyword,
      isUserTextSearch,
      resolveCoverageType(coverageTypes),
      typeaheadAoeCodes,
      enableBHAoeTypeahead,
      searchType,
      bhProgramFacilityAgg
    );
  };

  const getDataForProviders = (isSpecialtySearch: boolean) => {
    setIsFetchingData(true);
    setCurrentChip(value);

    const searchTypeToUse = isSpecialtySearch
      ? Constants.SEARCH_TYPES.SPECIALTY
      : Constants.SEARCH_TYPES.NAME;

    const payloadWithRecriprocityId =
      getProviderSearchPayloadWithRecriprocityId(
        memberCoverageTypes,
        featureFlags,
        currentMember,
        coverageType,
        choosePCP,
        onlineRetailers
      );
    const payload = getProviderSearchPayload(
      payloadWithRecriprocityId,
      isSpecialtySearch,
      searchTypeToUse,
      isUserTextSearch,
      typeaheadAoeCodes,
      enableBHAoeTypeahead
    );
    const selectedCategoryPayload = payload.find(
      (item) => item.chipCategory === selectedCategory?.id
    );

    getProviderSearchResults({
      setShowFacilities,
      setShowProviderGroups,
      setShowProviders,
      providerSearchParams: customParams,
      choosePCP,
      cleanUpProviderCalls,
      pseudoVerticalsDetails,
      selectedCategory,
      selectedCategoryPayload,
      specialtySearch: isSpecialtySearch,
      searchType: searchTypeToUse,
      providerQueryTypes,
    });
    setIsFetchingData(false);
    setTypeaheadSearchStore({ providerQueryTypes });
  };

  useEffect(() => {
    if (error) {
      const errorStoreValues = {
        uiSection: linkName || originLinkNameForAnalytics,
      };
      setErrorStore(errorStoreValues);

      const urlParams = convertObjectToUrlParams(chipStore, {
        ...errorStoreValues,
      });

      navigate(
        `${
          errorCodeRouteMap[providerHasError?.status] ||
          errorCodeRouteMap['4xx']
        }${urlParams}`
      );
    }
  }, [error]);

  useEffect(() => {
    setPageTracked(false);
    data.isLoaded = false;
  }, [value]);

  const llmFlagValueFn = () =>
    pseudoVerticalsDetails?.isPseudoRollupSearch ? false : llmFlag;

  const categoryCountRequestParams: GetCategoryCountProps = {
    currentMember,
    onlineRetailers,
    featureFlags,
    pseudoVerticalsDetails,
    pseudoVerticals,
    includeSpecialityRollupCodes,
    searchType,
    specialtySearch,
    uesTranslation,
    coverageTypes: resolveCoverageType(coverageTypes),
    keyword,
    search,
    searchTerm,
    isMixedSuggestions,
    isUserTextSearch,
    llmFlag: llmFlagValueFn(),
    behavioralFacilityProgram: bhProgramFacilityAgg,
  };

  useEffect(() => {
    if (
      Object.keys(uesTranslation).length &&
      !choosePCP &&
      !autoCompleteParallelSearchFlow
    ) {
      getCategoryCountResults(categoryCountRequestParams);
    }
  }, [
    longitude,
    latitude,
    specialtySearch,
    JSON.stringify(uesTranslation),
    search,
    searchTerm,
  ]);

  const enabledChipForNameSearch = getFirstEnabledChip(
    parallelCategoryCountResults.nameResults
  );
  const enabledNameForSpecialitySearch = getFirstEnabledChip(
    parallelCategoryCountResults.specialityResults
  );

  const getCorrectChipCategory = () => {
    const sortedRollupCodesOfClickedSuggestion = typeaheadSearchStore?.vertical
      ?.split(',')
      ?.map((i) => i.trim())
      ?.sort()
      ?.join(',');

    const sortedTypeaheadPreferenceCodes = typeaheadPreferenceCodes?.map(
      (codes) => ({
        ...codes,
        rollupCodes: codes?.rollupCodes
          ?.split(',')
          ?.map((i) => i.trim())
          ?.sort()
          ?.join(),
      })
    );

    const foundCategories =
      sortedTypeaheadPreferenceCodes?.filter(
        (item) => item.rollupCodes === sortedRollupCodesOfClickedSuggestion
      ) || [];

    const uniqueChips = new Set();
    foundCategories?.forEach((obj) => {
      uniqueChips.add(obj?.chipCategory);
    });
    const isDuplicateFound = uniqueChips.size > 1;

    const isThisChipEnabled =
      parallelCategoryCountResults?.specialityResults?.some(
        (item) =>
          item.chipEnabled &&
          TypeaheadChipCategoriesMapping[item.chipCategory] ===
            foundCategories[0]?.chipCategory
      );

    if (foundCategories?.length > 0 && !isDuplicateFound && isThisChipEnabled) {
      return foundCategories[0]?.chipCategory;
    }
    return null;
  };

  const navigateToChipCategory = getCorrectChipCategory();

  const getPreferredChipCategoryParamValue = (chipName) =>
    Constants?.CARE_CATEGORIES_NAME_PAYLOAD?.find((i) => i.label === chipName)
      ?.chipCategory ?? '';

  useEffect(() => {
    if (
      Object.keys(uesTranslation).length &&
      !choosePCP &&
      autoCompleteParallelSearchFlow &&
      (specialtyCategory ? includeSpecialityRollupCodes : true)
    ) {
      getParallelCategoryCountResults(categoryCountRequestParams);
    }
  }, [
    longitude,
    latitude,
    JSON.stringify(uesTranslation),
    search,
    searchTerm,
    includeSpecialityRollupCodes,
  ]);

  useEffect(() => {
    if (chipCategoryData?.getChipCategoriesCount?.length) {
      const { getChipCategoriesCount } = chipCategoryData;
      const cValue = getFirstEnabledChip(getChipCategoriesCount);
      const selectedCoverageType = getSelectedCoverageType(cValue);
      if (!cValue) {
        setNoCategoryDataFound(true);
      }

      if (!!cValue && cValue === value) {
        setNoCategoryDataFound(false);
        getDataForProviders(
          specialtySearch || searchType == Constants.SEARCH_TYPES.SPECIALTY
        );
        setIsInitialChipSelected(true);
      } else {
        setStoreChipValue(compareAndReturn(cValue, ''));
        setIsInitialChipSelected(true);
      }
      setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
    }
  }, [
    categoryCountCoRelationId,
    JSON.stringify(chipCategoryData?.getChipCategoriesCount),
  ]);

  const hasParallelResultsLoaded = hasResultsLoadedForParallelCategoryCount(
    parallelCategoryCountResults
  );

  const [enableRemoveSearchParamsInUrl] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_REMOVE_SEARCH_PARAMS_IN_URL,
  ]);

  useEffect(() => {
    if (!autoCompleteParallelSearchFlow || !hasParallelResultsLoaded) {
      return;
    }

    const enabledChip =
      enabledChipForNameSearch || enabledNameForSpecialitySearch || '';
    const selectedCoverageType =
      enableNavigateToCorrectCategory && navigateToChipCategory
        ? getSelectedCoverageType(navigateToChipCategory)
        : getSelectedCoverageType(enabledChip);
    if (!enabledChip) {
      setNoCategoryDataFound(true);
    }

    const hasOnlySpecialitySearchEnabled = !enabledChipForNameSearch;

    if (enableNavigateToCorrectCategory && navigateToChipCategory) {
      setStoreChipValue(navigateToChipCategory);
      setIsInitialChipSelected(true);
      const prevParams = Object.fromEntries(params.entries());
      if (enableRemoveSearchParamsInUrl) {
        setSearchParams(
          {
            ...prevParams,
            chipValue: navigateToChipCategory,
            preferredChipCategory: getPreferredChipCategoryParamValue(
              navigateToChipCategory
            ),
          },
          {
            replace: true,
          }
        );
      }
    } else if (enabledChip === value && enabledChip != '') {
      setNoCategoryDataFound(false);
      setSpecialtySearch(hasOnlySpecialitySearchEnabled || specialtySearch);
      getDataForProviders(hasOnlySpecialitySearchEnabled || specialtySearch);
      setIsResultsLoading(false);
      setIsInitialChipSelected(true);
    } else {
      setStoreChipValue(enabledChip || chipValue);
      setIsInitialChipSelected(true);
      const prevParams = Object.fromEntries(params.entries());
      if (enableRemoveSearchParamsInUrl) {
        setSearchParams(
          {
            ...prevParams,
            chipValue: enabledChip,
            preferredChipCategory: getPreferredChipCategoryParamValue(
              enabledChip || chipValue
            ),
          },
          {
            replace: true,
          }
        );
      }
    }
    const searchStoreValues = {
      searchType:
        enabledChip && hasOnlySpecialitySearchEnabled
          ? Constants.SEARCH_TYPES.SPECIALTY
          : Constants.SEARCH_TYPES.NAME,
      isMixedSuggestions,
      providerQueryTypes,
    };
    setTypeaheadSearchStore(searchStoreValues);
    setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
  }, [
    autoCompleteParallelSearchFlow,
    JSON.stringify(parallelCategoryCountResults),
  ]);

  useEffect(() => {
    if (choosePCP) {
      setNoCategoryDataFound(false);
      getDataForProviders(searchType == Constants.SEARCH_TYPES.SPECIALTY);
      setIsResultsLoading(false);
      return;
    }

    if (autoCompleteParallelSearchFlow) {
      return;
    }

    const isCategoryDataFound = chipCategoryData?.getChipCategoriesCount?.some(
      (category) => category.chipEnabled
    );
    if (
      isCategoryDataFound &&
      Object.keys(uesTranslation).length &&
      !isCategoryCountLoading
    ) {
      setNoCategoryDataFound(false);
      getDataForProviders(
        specialtySearch || searchType == Constants.SEARCH_TYPES.SPECIALTY
      );
    }
  }, [
    choosePCP,
    value,
    autoCompleteParallelSearchFlow,
    JSON.stringify(uesTranslation),
    latitude,
    longitude,
  ]);
  useEffect(() => {
    if (!autoCompleteParallelSearchFlow || choosePCP) {
      return;
    }
    if (
      (!isInitialChipSelected && enabledChipForNameSearch) ||
      !parallelCategoryCountResults.isNameResultsLoaded ||
      !parallelCategoryCountResults.isSpecialityResultsLoaded ||
      Object.keys(uesTranslation).length === 0
    ) {
      return;
    }

    const enabledChip =
      enabledChipForNameSearch || enabledNameForSpecialitySearch || '';
    if (enabledChip) {
      setNoCategoryDataFound(false);
      const hasOnlySpecialitySearchEnabled = !enabledChipForNameSearch;
      setSpecialtySearch(hasOnlySpecialitySearchEnabled || specialtySearch);
      getDataForProviders(hasOnlySpecialitySearchEnabled || specialtySearch);
      setIsResultsLoading(false);
    } else {
      setNoCategoryDataFound(true);
    }
  }, [
    value,
    isInitialChipSelected,
    choosePCP,
    specialtySearch,
    autoCompleteParallelSearchFlow,
    JSON.stringify(uesTranslation),
  ]);

  useEffect(() => {
    const typeaheadStoreValues = {
      searchType:
        isMixedSuggestions && searchType === Constants.SEARCH_TYPES.SPECIALTY
          ? Constants.SEARCH_TYPES.NAME
          : searchType,
    };
    setSpecialtySearch(false);
    setTypeaheadSearchStore(typeaheadStoreValues);
    clearSearchFilters();
  }, []);

  useEffect(() => {
    if (enabledChipForNameSearch == '' && hasParallelResultsLoaded) {
      const typeaheadStoreValues = {
        searchType: Constants.SEARCH_TYPES.SPECIALTY,
        providerQueryTypes,
      };
      setSpecialtySearch(true);
      setTypeaheadSearchStore(typeaheadStoreValues);
    }
  }, [enabledChipForNameSearch, hasParallelResultsLoaded]);

  useEffect(() => {
    if (previous) {
      const searchStoreValues = {
        searchType:
          isMixedSuggestions && searchType === Constants.SEARCH_TYPES.SPECIALTY
            ? Constants.SEARCH_TYPES.NAME
            : searchType,
        previous: false,
        providerQueryTypes,
      };
      setTypeaheadSearchStore(searchStoreValues);
      setSpecialtySearch(false);
    }
  }, [previous]);
  const recentSearchArgs = {
    psxSearchTerm: search,
    pseudoVerticals,
    location,
    latitude,
    longitude,
    searchType,
    pesSearchTerm: getPesSearchTerm(
      bhProgramFacilityAgg,
      includeSpecialityRollupCodes,
      search
    ),
    isMixedSearchSuggestion: compareAndReturn(isMixedSuggestions, false),
    isUserTextSearch: compareAndReturn(isUserTextSearch, false),
    gender: getFilterValue('gender'),
    virtualCare: getFilterValue('virtualCare'),
    conditions: condition,
    specialtyCategories: specialtyCategory,
    bhProgramFacilityAgg,
    aoeCodes: typeaheadAoeCodes,
    behavioralProgramId: acceptsEap ? [getEapBHProgramId()] : null,
  };

  const isSuperUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SUPER_USER
  );
  const isGuestUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.IS_GUEST
  );
  const [, getSaveRecentSearches] = useSaveRecentSearches({});
  const [planYear] = usePlanYear();

  useEffect(() => {
    setSpecialtySearch(false);
  }, [search, searchTerm, location, longitude]);

  useEffect(() => {
    const { getChipCategoriesCount } = chipCategoryData;
    if (
      !isSuperUser &&
      getChipCategoriesCount?.length &&
      !specialtySearch &&
      !isCategoryCountLoading &&
      !isGuestUser
    ) {
      getSaveRecentSearches({
        variables: {
          ...recentSearchArgs,
          category: getCategoryFromChipCategoriesCount(getChipCategoriesCount),
          coverageType: compareAndReturn(coverageType, 'M'),
          planYear,
        },
      });
    }
  }, [isCategoryCountLoading]);

  useEffect(() => {
    if (
      !isSuperUser &&
      hasParallelResultsLoaded &&
      !isGuestUser &&
      recentSearchArgs.psxSearchTerm
    ) {
      const getChipCategoriesCount =
        enabledChipForNameSearch || !enabledNameForSpecialitySearch
          ? parallelCategoryCountResults.nameResults
          : parallelCategoryCountResults.specialityResults;

      getSaveRecentSearches({
        variables: {
          ...recentSearchArgs,
          category: getCategoryFromChipCategoriesCount(getChipCategoriesCount),
          coverageType: compareAndReturn(coverageType, 'M'),
          isMixedSearchSuggestion: !!enabledChipForNameSearch,
          searchType: enabledChipForNameSearch
            ? Constants.SEARCH_TYPES.NAME
            : Constants.SEARCH_TYPES.SPECIALTY,
          planYear,
        },
      });
    }
  }, [JSON.stringify(parallelCategoryCountResults)]);

  useEffect(() => {
    setIsSearchModified(false);
    setIsInitialChipSelected(true);
  }, [keyword]);

  const { isSpecialityResultsLoaded, isNameResultsLoaded } =
    parallelCategoryCountResults;

  useEffect(() => {
    let isAnEmptyResult;

    if (autoCompleteParallelSearchFlow) {
      isAnEmptyResult =
        isNameResultsLoaded &&
        isSpecialityResultsLoaded &&
        (specialtySearch
          ? !enabledNameForSpecialitySearch
          : !enabledChipForNameSearch);
    } else {
      isAnEmptyResult =
        chipCategoryData?.getChipCategoriesCount?.length &&
        getFirstEnabledChip(chipCategoryData?.getChipCategoriesCount) === '';
    }

    const emptySearchResult = isEmptyResult(
      choosePCP,
      isCategoryCountLoading ||
        providerIsLoading ||
        orgIsLoading ||
        medGrpIsLoading,
      value,
      data.totalCountOrganizations,
      data.totalCountMedicalGroup,
      data.totalCountPractitioners
    );

    const isEmptyResults =
      (data.isLoaded && emptySearchResult) ||
      noCategoryDataFound ||
      (hasOnlyPseudoRollupCodes ? isAnEmptyResult : false);
    setEmptyResults(isEmptyResults);
    if (isEmptyResults) {
      setIsResultsLoading(false);
    }
  }, [
    providerIsLoading,
    medGrpData,
    orgIsLoading,
    value,
    data.totalCountOrganizations,
    data.totalCountMedicalGroup,
    data.totalCountPractitioners,
    data.isLoaded,
    noCategoryDataFound,
    specialtySearch,
    isNameResultsLoaded,
    isSpecialityResultsLoaded,
  ]);

  useEffect(() => {
    const totalNumberOfResults = getNoOfResultsForAnalytics(data);

    if (
      !isLoading &&
      value &&
      currentChip === value &&
      !choosePCP &&
      totalNumberOfResults > 0
    ) {
      const adobeSearchMethod = compareAndReturn(searchMethod, 'guided');
      const adobeSearchTerm =
        adobeSearchMethod === 'guided'
          ? ''
          : compareAndReturn(searchTerm, search);
      const adobeEventBody = {
        term: adobeSearchTerm,
        type: getProviderType(providerType),
        linkName: originLinkNameForAnalytics,
        method: adobeSearchMethod,
        filters: value?.toLowerCase(),
        numberOfResults: getNoOfResultsForAnalytics(data),
        customAttributesBlock: {
          providerType: getProviderTypesFromDisplayedResults(data),
          searchLocation: getSearchLocation(storedLocation),
        },
      };

      adobePageTrackEvent();
      setPageTracked(true);

      if (!isSearchModified) {
        adobeStandardSearchEvent(adobeEventBody);
        setIsSearchModified(true);
      } else {
        adobeSearchModifiedEvent(adobeEventBody);
      }

      if (isMixedSuggestions && specialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message:
            'search instead for providers that include typed term in their name',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
      if (isMixedSuggestions && !specialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message: 'search instead for providers that can treat typed term',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }

      setCurrentChip('');
    }
  }, [
    providerIsLoading,
    medGrpIsLoading,
    orgIsLoading,
    currentChip,
    specialtySearch,
    value,
  ]);

  useEffect(() => {
    if (choosePCP) {
      adobePageTrackEvent();
      setPageTracked(true);
    }
  }, [choosePCP]);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key] =
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path + window.location.search;
    const updatedBreadCrumbUrls = pick(
      breadcrumbUrls,
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    );
    setBreadcrumbUrls(updatedBreadCrumbUrls);
    document.title = `${searchTitle} | ${Constants.SITE_NAME}`;
  }, [searchTitle]);

  useEffect(() => {
    document.title = value
      ? `${t(`${value}-results-for`)} ${searchTitle} | ${Constants.SITE_NAME}`
      : Constants.SITE_NAME;
  }, [value, searchTitle]);

  const noResultUrlParams = convertObjectToUrlParams(chipStore, {
    chipValue,
    includeSpecialityRollupCodes,
    linkName,
    medicalSpeciality,
    originLinkNameForAnalytics,
    search,
    searchType,
    sectionType,
    specialtyCode,
  });
  const showMedicalSpecialityNSRPage =
    medicalSpeciality &&
    chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS;

  useEffect(() => {
    if (emptyResults && showMedicalSpecialityNSRPage) {
      navigate(
        `${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${noResultUrlParams}`
      );
    }
  }, [showMedicalSpecialityNSRPage, emptyResults, noResultUrlParams]);

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: '',
    },
  ];

  const mobileScreen = useMediaQuery(phoneOnly);

  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );

  const isBHCare: boolean = coverageType?.includes(
    ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
  );

  const isLoading = IsSearchResultsLoading(
    orgIsLoading,
    providerIsLoading,
    medGrpIsLoading,
    noCategoryDataFound,
    isCategoryCountLoading,
    selectedCategory?.label,
    isFetchingData,
    isResultsLoading
  );

  const onSpecialtyResultClicked = (
    isSpecialtySearch: boolean,
    title: string = ''
  ) => {
    setSpecialtySearch(isSpecialtySearch);
    let chipToUse = '';

    if (autoCompleteParallelSearchFlow) {
      chipToUse = isSpecialtySearch
        ? getFirstEnabledChip(parallelCategoryCountResults.specialityResults)
        : getFirstEnabledChip(parallelCategoryCountResults.nameResults);

      const selectedCoverageType = getSelectedCoverageType(chipToUse);
      setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
      setStoreChipValue(chipToUse);
      setIsInitialChipSelected(true);
    } else {
      // In previous implementation, use effect for category call  have a dependency on this
      setStoreChipValue('');
      setStoreCoverageType('');
    }

    adobeLinkTrackEvent({
      name: title,
      location: 'body:result heading',
    });
    setAnalyticsState({ originLinkNameForAnalytics: title });

    const searchStoreValues = {
      searchType: isSpecialtySearch
        ? Constants.SEARCH_TYPES.SPECIALTY
        : Constants.SEARCH_TYPES.NAME,
      providerQueryTypes,
    };
    setTypeaheadSearchStore(searchStoreValues);
    setSpecialtySearch(isSpecialtySearch);
  };

  const getChipsToShowOnHeader = () => {
    if (!autoCompleteParallelSearchFlow) {
      return categoryCount?.data?.getChipCategoriesCount || [];
    }

    if (enabledChipForNameSearch && !specialtySearch) {
      return parallelCategoryCountResults.nameResults || [];
    }

    return parallelCategoryCountResults.specialityResults || [];
  };

  return (
    <React.Fragment>
      {!choosePCP ? (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          categoryCount={getChipsToShowOnHeader()}
          dataTestId="search-results-search-form"
          isMixedResultsPage
          showChips={!emptyResults}
          showChoosePCPHeader={false}
          showMemberSelection={!mobileScreen}
          showSearchInputBackButton={mobileScreen}
        />
      ) : (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          dataTestId="search-results-search-form"
          showChips={false}
          showChoosePCPHeader
          showMemberSelection={false}
        />
      )}
      {isLoading ? (
        <SectionSkeleton />
      ) : emptyResults && !showMedicalSpecialityNSRPage ? (
        <NullResultsPage
          emptyResults={emptyResults}
          onCommonSearchChipClicked={() => {
            setEmptyResults(false);
            setNoCategoryDataFound(false);
          }}
          searchTitle={searchTitle}
          setSpecialtySearch={setSpecialtySearch}
          specialtyCode={specialtyCode}
          specialtySearch={specialtySearch}
        />
      ) : (
        <SearchedResultSectionData
          acceptingNewPatients={acceptingNewPatients}
          areaOfExpertise={areaOfExpertise}
          categoryCount={getChipsToShowOnHeader()}
          choosePCP={choosePCP || false}
          contextValue={value}
          data={data}
          dependentSeqNbr={dependentSeqNbr || ''}
          eapCodeFlag={eapCodeFlag}
          facilitiesHeaders={organizationResult.headers}
          genderCode={genderCode}
          hasOnlyPseudoRollupCodes={hasOnlyPseudoRollupCodes}
          isBHCare={isBHCare}
          isMixedResultsPage
          isMixedSuggestions={typeaheadSearchStore?.isMixedSuggestions ?? false}
          isNameSearchEnabled={enabledChipForNameSearch !== ''}
          isPageTracked={isPageTracked}
          isSpecialtySearchEnabled={
            hasOnlyPseudoRollupCodes || enabledNameForSpecialitySearch !== ''
          }
          llmFlag={
            pseudoVerticalsDetails?.isPseudoRollupSearch ? false : llmFlag
          }
          medGrpIsLoading={medGrpIsLoading}
          medicalGroupHeaders={medGrpResult.headers}
          onSpecialtyResultClicked={onSpecialtyResultClicked}
          orgIsLoading={orgIsLoading}
          preSelectEapFilter={preSelectEapFilter}
          preferredFacility={customParams.preferredFacilities}
          providerHeaders={providersResult.headers}
          providerIsLoading={providerIsLoading}
          resultType={value}
          searchMethod={searchMethod}
          searchTerm={searchTerm}
          searchTitle={searchTitle}
          searchType={searchType}
          showChips={!emptyResults}
          showFacilities={showFacilities}
          showProviderGroups={isShowProviderGroups(
            showProviderGroups,
            currentMember?.lineOfBusiness,
            choosePCP
          )}
          showProviders={showProviders}
          specialtyCode={specialtyCode}
          specialtyCodeFromQuery={specialtyCodeFromQuery}
          specialtyResults={parallelCategoryCountResults?.specialityResults}
          specialtySearch={specialtySearch}
          userZip={userZip}
          virtualCare={virtualCareValue}
          virtualCareCode={virtualCareCode}
        />
      )}
      <NullPageContainerStyled hidden={!emptyResults}>
        {' '}
        <StillNeedHelp />
        {isOHCNS && <DisclaimersContainer />}
      </NullPageContainerStyled>
    </React.Fragment>
  );
};
