import { styled } from '@abyss/web/tools/styled';

export const ReferralBannerBoxBanner = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '8px 8px 8px 12px',
  borderTop: '1px solid #196ECF',
  borderRight: '1px solid #196ECF',
  borderBottom: '1px solid #196ECF',
  borderLeft: '4px solid #196ECF',
  width: '348px',
  height: 'auto',
  columnGap: '4px',
  alignSelf: 'stretch',
  borderRadius: '4px',
  backgroundColor: ' #EDF3FB',
});

export const ExternalLinkIcon = styled('div', {
  height: '24px',
  width: '100%',
  alignItems: 'flex-end',
  alignContent: 'center',
  alignSelf: 'stretch',
  paddingLeft: '4px',
});

export const ContactMyPCP = styled('div', {
  whiteSpace: 'nowrap',
});

export const ReferralBannerTextContainer = styled('div', {
  width: '299px',
  height: '43px',
  gap: '4px',
  size: '14px',
  fontWeight: '700',
  color: '#323333',
});

export const ReferralBannerContactPCP = styled('button', {
  marginLeft: '14px',
  cursor: 'pointer',
  display: 'flex',
  width: '123px',
  color: '#196ECF',
  lineHeight: '18px',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  fontWeight: '600',
  alignItems: 'center',
  '@screen < $sm': {
    fontSize: '12.64px',
    lineHeight: '16px',
  },
  '@screen < $md': {
    marginLeft: '0px',
  },
});
