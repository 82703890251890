import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  NEW_TAB_CONFIRMATION,
  PRACTITIONER_NAME_FOR_ANALYTICS,
} from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { handleLinkAndModalTrack } from '../../../../../common/Utils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { ResponseHeaders } from '../../../../../models/ResponseHeaders';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../../store/useDetailsStore/detailsStore';
import { returnSuppressFlag } from '../../../../../utils/featureSuppress';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../utils/providerNames.utils';
import { isVirtualVisitOnlyAddress } from '../../../../../utils/virtualVisitOnly';
import { ConfirmationModal } from '../CompareDrawer/Components/ConfirmationModal/ConfirmationModal';
import {
  AvatarShareCard,
  BoxStyled,
  ButtonCss,
  LocationIcon,
  PlaceHolderText,
  RemoveButtonIcon,
  RemoveButtonText,
  ShareBoxStyledPlaceHolders,
  ShareBoxTextAddress,
  ShareBoxTextName,
  addressFlexCss,
} from './ShareDrawer.styled';

type Props = {
  handleDetailsOnClick: (
    providerId: string,
    options?: Object,
    customAttributesBlock?: CustomAttributesBlock
  ) => void;
  removeItem: (item: string) => void;
  headers: ResponseHeaders;
  selected: number;
  selectedProviderList: {
    providerName: string;
    providerType: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    networkStatus: string;
    languagesSpoken: string[];
    acceptingNewPatients: boolean;
    gender: string;
    virtualCareOffered: boolean;
    address: {
      line: string[];
    };
    phones: {
      phone: string[];
    };
    locationId: string;
    imageLocation: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
  }[];
};

export const ShareDrawerDesktop = ({
  selected,
  selectedProviderList,
  removeItem,
  handleDetailsOnClick,
  headers,
}: Props) => {
  const modal = useOverlay('view-provider-modal');
  const { t } = useTranslation();
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const { coverageType, chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      chipValue: state.chipValue,
    }))
  );
  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );
  const showVirtualVisitOnlyAddress = (
    addressLine: string,
    suppressVirtualCareQuickCare: boolean
  ) =>
    suppressVirtualCareQuickCare &&
    isVirtualVisitOnlyAddress(chipValue, addressLine);
  const [clickedIndex, setClickedIndex] = useState(0);
  const imgRef = useRef<HTMLImageElement>(null);

  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(
      appendCoverageTypes(selectedProviderList[clickedIndex], coverageType)
    ),
    providerType: convertProviderTypeToAdobeType(
      selectedProviderList[clickedIndex]?.providerType
    ),
  };

  const handleProviderNameAndCarrotClick = (index) => {
    setClickedIndex(index);
    handleLinkAndModalTrack(
      PRACTITIONER_NAME_FOR_ANALYTICS,
      `modal:share card:position ${clickedIndex}`,
      NEW_TAB_CONFIRMATION,
      undefined,
      customAttributesBlock
    );
    modal?.open();
  };

  const handleClickCb = () => {
    modal.close();
    const detailsStoreValues = {
      specialtySearch: selectedProviderList[clickedIndex]?.speciality,
    };
    setDetailsStore(detailsStoreValues);
    handleDetailsOnClick(
      selectedProviderList[clickedIndex]?.providerId,
      {
        openInNewTab: true,
        providerType: selectedProviderList[clickedIndex]?.providerType,
      },
      customAttributesBlock
    );
  };

  return (
    <React.Fragment>
      <Layout.Group
        css={{
          'abyss-layout-group': {
            marginTop: selected ? '0px' : '$lg',
            marginLeft: '20px',
            overflow: 'auto',
          },
        }}
        space={12}
      >
        {selectedProviderList.slice(0, 5).map((selectedProvider, index) => {
          const isVirtualVisitOnly = selectedProvider
            ? showVirtualVisitOnlyAddress(
                selectedProvider.address?.line?.[0],
                isVirtualCareQuickCareSuppressed
              )
            : false;
          return (
            <div>
              <Flex
                alignItems="center"
                data-auto-testid="share-provider-card"
                data-testid="share-provider-card"
                direction="column"
              >
                <BoxStyled
                  align="start"
                  cssProps={{ selected }}
                  data-auto-testid={`share-card-${selectedProvider.providerId}`}
                  data-testid={`share-card-${selectedProvider.providerId}`}
                  key={selectedProvider.locationId}
                >
                  <AvatarShareCard>
                    {ImageComponent({
                      imgRef,
                      providerType: 'Practitioner',
                      imgSrc: selectedProvider?.imageLocation,
                    })}
                  </AvatarShareCard>
                  <Flex direction="column">
                    <Tooltip
                      align="center"
                      content={parseProviderName(
                        selectedProvider.providerName,
                        selectedProvider.providerType,
                        selectedProvider.primaryDegrees?.[0],
                        getNameAttrs(selectedProvider)
                      )}
                      position="top"
                      positionOffset={8}
                    >
                      <ShareBoxTextName
                        data-auto-testid="share-provider-name"
                        data-testid="share-provider-name"
                        onClick={() =>
                          handleProviderNameAndCarrotClick(
                            index,
                            selectedProvider.providerType
                          )
                        }
                      >
                        {parseProviderName(
                          selectedProvider.providerName,
                          selectedProvider.providerType,
                          selectedProvider.primaryDegrees?.[0],
                          {
                            firstName: selectedProvider.firstName,
                            middleName: selectedProvider.middleName,
                            lastName: selectedProvider.lastName,
                          }
                        )}
                      </ShareBoxTextName>
                    </Tooltip>
                    <Flex css={addressFlexCss}>
                      {!isVirtualVisitOnly && (
                        <IconMaterial css={LocationIcon} icon="location_on" />
                      )}
                      <Tooltip
                        align="center"
                        content={
                          !isVirtualVisitOnly &&
                          t(selectedProvider.address?.line?.[0])
                        }
                        position="top"
                        positionOffset={8}
                      >
                        <ShareBoxTextAddress>
                          {!isVirtualVisitOnly &&
                            t(selectedProvider.address?.line?.[0])}
                        </ShareBoxTextAddress>
                      </Tooltip>
                    </Flex>
                  </Flex>
                </BoxStyled>
              </Flex>
              <Layout.Group css={ButtonCss}>
                <Button
                  aria-label={`${t(
                    'SHARE_DRAWER_TEXT.REMOVE'
                  )} ${parseProviderName(
                    selectedProvider.providerName,
                    selectedProvider.providerType,
                    selectedProvider.primaryDegrees?.[0],
                    {
                      firstName: selectedProvider.firstName,
                      middleName: selectedProvider.middleName,
                      lastName: selectedProvider.lastName,
                    }
                  )}`}
                  data-auto-testid="button-remove"
                  data-testid="button-remove"
                  onClick={() => {
                    adobeLinkTrackEvent({
                      name: 'remove button',
                      location: `modal:share card:position ${index + 1}`,
                      type: 'internal',
                    });
                    removeItem(selectedProvider.locationId);
                  }}
                  variant="ghost"
                >
                  <IconMaterial
                    css={RemoveButtonIcon}
                    icon="remove_circle_outline"
                  />
                  <Text css={RemoveButtonText}>
                    {t('SHARE_DRAWER_TEXT.REMOVE')}
                  </Text>
                </Button>
              </Layout.Group>
              <ConfirmationModal
                closeButtonText={
                  t('COMPARE_DRAWER_CONFIRMATION_MODAL.VIEW_PROVIDER_BUTTON') ||
                  ''
                }
                dataForAnalytics={{
                  linkName: PRACTITIONER_NAME_FOR_ANALYTICS,
                  linkLocation: `modal:share card:position ${clickedIndex}`,
                  modalName: NEW_TAB_CONFIRMATION,
                  linkType: undefined,
                  customAttributesBlock,
                }}
                handleClickCb={() => handleClickCb()}
                isViewProvider
                locationForAnalytics={`modal:${NEW_TAB_CONFIRMATION}`}
                modal={modal}
                modalName="view-provider-modal"
              />
            </div>
          );
        })}
        {Array.from({
          length: Math.max(0, 5 - selectedProviderList.length),
        }).map((place, index) => (
          <Box
            css={ShareBoxStyledPlaceHolders}
            data-auto-testid="share-selections-container"
            data-testid="share-selections-container"
            index={index}
            key={place}
          >
            <Text
              css={PlaceHolderText}
              data-auto-testid="share-drawer-placeholder"
              data-testid="share-drawer-placeholder"
            >
              {t('SHARE_DRAWER_TEXT.CARD_PLACEHOLDER')}
            </Text>
          </Box>
        ))}
      </Layout.Group>
    </React.Fragment>
  );
};
