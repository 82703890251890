import { createTheme } from '@abyss/web/tools/theme';
import { OverlayProvider } from '@abyss/web/ui/OverlayProvider';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import { ReverseCoverageTypesCodes } from '../../common/Constants';
import { DetailsWidgetView } from '../../common/Widget/ProviderDetails';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { Configuration } from '../ProviderSearch/context/Configuration';
import { DateTimeConfig } from '../ProviderSearch/context/DateTimeConfig';
import { Internationalization } from '../ProviderSearch/context/Internationalization';
import { Obapi } from '../ProviderSearch/context/Obapi';
import { PlanSelector } from '../ProviderSearch/context/PlanSelector';
import { uhcThemeOverride } from '../themeOverrides/uhcThemeOverride';

export const ProviderDetailsWidget = (args) => {
  const theme = createTheme('uhc', uhcThemeOverride);
  const {
    'claim-system-type-code': claimSystemTypeCode,
    'coverage-type': coverageType,
    'ddp-code': ddpCode,
    'eligibility-system-type-code': eligibilitySystemTypeCode,
    latitude: latitude,
    lob: lob,
    'location-id': locationId,
    longitude: longitude,
    'out-of-pocket-cost': outOfPocketCost,
    'plan-variation-code': planVariationCode,
    'plan-year': planYear,
    'policy-id': policyId,
    'provider-id': providerId,
    'provider-type': providerType,
    'reciprocity-id': reciprocityId,
    'total-cost': totalCost,
    'membership-category': membershipCategory,
    population: population,
  } = args || {};

  const setIsWidget = useStore(StoreKeys.SET_IS_WIDGET);
  const setUserDdpCode = useStore(StoreKeys.SET_USER_DDP_CODE);

  const { setStoreCoverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      setStoreCoverageType: state.setCoverageType,
    }))
  );

  setIsWidget(true);
  setStoreCoverageType(coverageType);
  setUserDdpCode(ddpCode);

  const adjustedReciprocityId =
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
    reciprocityId === '0'
      ? 'Employer%20Guest'
      : reciprocityId;

  return (
    <Configuration>
      <PlanSelector>
        <DateTimeConfig>
          <Obapi>
            <Internationalization>
              <OverlayProvider>
                <ThemeProvider theme={theme}>
                  {providerId ? (
                    <DetailsWidgetView
                      claimSystemTypeCode={claimSystemTypeCode}
                      coverageType={coverageType}
                      ddpUserCode={ddpCode}
                      eligibilitySystemTypeCode={eligibilitySystemTypeCode}
                      latitude={latitude}
                      lob={lob}
                      locationId={locationId}
                      longitude={longitude}
                      membershipCategory={membershipCategory}
                      outOfPocketCost={outOfPocketCost}
                      planVariationCode={planVariationCode}
                      planYear={planYear}
                      policyId={policyId}
                      population={population}
                      providerId={providerId}
                      providerType={providerType || 'Practitioner'}
                      reciprocityId={adjustedReciprocityId}
                      totalCost={totalCost}
                    />
                  ) : (
                    <div>No Provider ID</div>
                  )}
                </ThemeProvider>
              </OverlayProvider>
            </Internationalization>
          </Obapi>
        </DateTimeConfig>
      </PlanSelector>
    </Configuration>
  );
};
