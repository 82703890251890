export const itemToShow = (name, costOption, showTierOnePlan, showTier2Plan) =>
  name !== 'inNetwork' &&
  (name === 'cost' ? costOption : true) &&
  (name === 'tierOneAndTwoProvider' ? showTier2Plan : true) &&
  (name === 'tierOneProvider' ? !showTier2Plan && showTierOnePlan : true);

export const getCheckboxLabel = (label) => {
  const checkboxLabel = label || '';
  return checkboxLabel;
};

export const isTieredOrVisionOrDental = (
  isTierOne: boolean,
  isDental: boolean,
  isVision: boolean
): boolean => isTierOne || isDental || isVision;

export const getInitialCheckedCount = (
  costOption,
  showTierOnePlan,
  showTier2Plan,
  items
): number =>
  items?.filter(
    (e) =>
      itemToShow(e.name, costOption, showTierOnePlan, showTier2Plan) &&
      e.isChecked === true
  ).length;
