import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOB } from '../../../../../../../../../../../api/src/services/Constants';
import { ConstantsLagoon } from '../../../../../../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../../../../../../common/ConstantsStyles';
import { TierProviderText } from '../../../../../../../../../common/ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails.style';
import { PSXIcon } from '../../../../../../../../../common/PSXIcon';
import { TierOneSnackCardPopoverStyles } from '../../../../../../../../../common/SnackCard/SnackCard.styles';
import { TieredDrawerStyles } from '../../../../../../../../../common/TierBenefitsCard/TierBenefitsCard.style';
import { handleLinkAndModalTrack } from '../../../../../../../../../common/Utils/adobeTrackUtils';
import { useLagoon } from '../../../../../../../../../hooks/useLagoon';
import { getCurrentMember } from '../../../../../../../../../utils/user.utils';
import { getStatusText } from '../../../../../ShareDrawer/utils';
import {
  StyledNullAttributeDiv,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../utility/compareDrawerConstants';

type Props = {
  isTieredProvider: boolean;
  isTier2Provider: boolean | undefined;
  indexForAnalytics: number | undefined;
};

export const TierOneProviderText = ({
  isTieredProvider,
  isTier2Provider,
  indexForAnalytics,
}: Props) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(phoneOnly);
  const currentMember = getCurrentMember();
  const [isOpen, setIsOpen] = useState(false);
  const indexAdobe =
    indexForAnalytics && indexForAnalytics >= 0 ? indexForAnalytics + 1 : 1;
  const locationForAdobe = `modal:compare card:position ${indexAdobe}`;
  const tierPopOverDataTestId: string = mobile
    ? 'Tier-provider-Drawer'
    : 'Tier-provider-popover';

  const tierOneProviderText = getStatusText(
    isTieredProvider,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.TRUE,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.FALSE,
    NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
  );

  const tier2ProviderText = getStatusText(
    isTier2Provider,
    ATTRIBUTE_STATUS_TEXT.TIER_TWO_PROVIDER.TRUE,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.FALSE,
    NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
  );

  useEffect(() => {
    if (isOpen) {
      const linkName: string = tierPopOverDataTestId;
      const modalName: string = isTieredProvider
        ? 'tier 1 providers'
        : 'tier 2 providers';
      handleLinkAndModalTrack(linkName, locationForAdobe, modalName);
    }
  }, [isOpen]);

  const data = useLagoon('ui-messaging')();
  const tierOneContentOne = find(data, {
    key: ConstantsLagoon.MEDICAL_SPECIALISTS.TIER_ONE_BENEFITS_ONE,
  });
  const tierContent = tierOneContentOne?.message;

  const tierOnePopoverContent =
    (tierContent ??
      (currentMember.lineOfBusiness === LOB.IFP && tierContent)) ||
    t('TIERED_BENEFITS.TIER_CONTENT');

  const tier2Content = find(data, {
    key: ConstantsLagoon.UI_MESSAGING.TIER_2_POPOVER_CONTENT,
  });
  const tierPopoverContent = isTieredProvider
    ? tierOnePopoverContent
    : tier2Content?.message;

  const tierText = isTieredProvider ? tierOneProviderText : tier2ProviderText;

  if (isTieredProvider === undefined && isTier2Provider === undefined) {
    return (
      <React.Fragment>
        {mobile ? (
          <Popover
            content={t(tierOneProviderText)}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            <StyledNullAttributeDiv>
              {t(tierOneProviderText)}
            </StyledNullAttributeDiv>
          </Popover>
        ) : (
          <StyledNullAttributeDiv>
            {t(tierOneProviderText)}
          </StyledNullAttributeDiv>
        )}
      </React.Fragment>
    );
  }

  if (!isTieredProvider && !isTier2Provider) {
    return <Text css={{ fontWeight: 'bold' }}>{t(tierOneProviderText)}</Text>;
  }

  return (
    <TierProviderText>
      {!mobile ? (
        (isTieredProvider || isTier2Provider) && (
          <Popover
            content={tierPopoverContent}
            css={TierOneSnackCardPopoverStyles}
            data-auto-testid={tierPopOverDataTestId}
            data-testid={tierPopOverDataTestId}
            onOpenChange={() => {
              setIsOpen(!isOpen);
            }}
            title={isTieredProvider ? 'Tier 1 Providers' : 'Tier 2 Providers'}
            width={214}
          >
            <Layout.Group>
              <Layout.Group css={{ backgroundColor: '#F0F8E4' }}>
                {isTieredProvider ? (
                  <PSXIcon icon="Group 204976975" size={16} />
                ) : (
                  <PSXIcon icon="tier_two" size={16} />
                )}
                <Text
                  css={{ fontWeight: 'bold' }}
                  data-auto-testid="Tier-provider-popover-text"
                  data-testid="Tier-provider-popover-text"
                >
                  {tierText}
                </Text>
              </Layout.Group>
              <IconMaterial
                data-auto-testid="Tier-provider-popover-textInfo"
                data-testid="Tier-provider-popover-textInfo"
                icon="info"
                size={16}
                variant="outlined"
              />
            </Layout.Group>
          </Popover>
        )
      ) : (
        <React.Fragment>
          <Drawer
            css={TieredDrawerStyles}
            data-auto-testid={tierPopOverDataTestId}
            data-testid={tierPopOverDataTestId}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            size="200"
            title={isTieredProvider ? 'Tier 1 Providers' : 'Tier 2 Providers'}
          >
            {tierPopoverContent}
          </Drawer>
          <Layout.Group>
            <Layout.Group css={{ backgroundColor: '#F0F8E4' }}>
              {isTieredProvider ? (
                <PSXIcon icon="Group 204976975" size={16} />
              ) : (
                <PSXIcon icon="tier_two" size={16} />
              )}
              <Text
                css={{ fontSize: '14px', fontWeight: 'bold' }}
                data-auto-testid="Tier-text"
                data-testid="Tier-text"
                onClick={() => setIsOpen(true)}
              >
                {tierText}
              </Text>
            </Layout.Group>
            <IconMaterial
              data-auto-testid="Tier-textInfo"
              data-testid="Tier-textInfo"
              icon="info"
              onClick={() => setIsOpen(true)}
              size={16}
              variant="outlined"
            />
          </Layout.Group>
        </React.Fragment>
      )}
    </TierProviderText>
  );
};
