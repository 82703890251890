import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import { usePrimaryCare } from '../../../hooks/usePrimaryCare';
import { useSpecialtiesResult } from '../../../hooks/useSpecialties';
import { ProviderDetails } from '../../../models/ProviderDetails';
import { useAnalyticsStore } from '../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../store/useDetailsStore';
import { DetailsStore } from '../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../store/useTypeaheadStore/typeaheadStore';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../utils/providerNames.utils';
import { getCurrentMember } from '../../../utils/user.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { ContentWrapper } from '../../ContentWrapper';
import { EAPCode } from '../../EAPCode';
import { KsAffiliatesBanner } from '../../KsAffliates/KsAffiliatesCard';
import { toShowKSBannerOnProviderDetailsAbout } from '../../KsAffliates/KsAffiliatesUtils';
import { getFeatureFlag } from '../../Utils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
  getIndicatorsForProviderDetailsAboutTabsImpressions,
  getSearchBlock,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs.styled';
import { BiographySection } from './BiographySection';
import { CollapseDetailsSectionRoot } from './CollapseProviderDetailsSection/CollapseDetailsSectionRoot';
import { PatientReviews } from './PatientReviews/PatientReviews';
import { ReferralBannerBox } from './ReferralBannerBox';

type Props = {
  providerDetails: ProviderDetails;
  tabTitle?: string;
  coverageType?: string;
  suppressReviews: boolean;
  locationKey?: string;
};

export const About = ({
  providerDetails,
  tabTitle,
  coverageType,
  suppressReviews,
  locationKey,
}: Props) => {
  const selectedLocation = providerDetails?.providerLocations?.find(
    (x) => x.locationKey === locationKey
  );
  const [isPageTracked, setPageTracked] = useState<boolean>(false);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const showReferralBanner = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REFERRAL_REQUIRED_BANNER
  );
  const allConfigs = useLagoon('config')();
  const KsAffliatedTins = allConfigs?.find(
    (row) => row.key === 'KELSEY_SEYBOLD_AFFILIATE_TIN'
  )?.value;

  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );
  const enableIFP = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_IFP
  );

  const isEnableL2CulturalCompetencies = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_L2_CULTURAL_COMPETENCIES
  );

  const isFrequentServicesProvidedEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_FREQUENT_SERVICES_PROVIDED
  );
  const isProviderExperienceSectionEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PROVIDER_EXPERIENCE_SECTION
  );

  const currentMember = getCurrentMember();
  const { data: pcpData } = usePrimaryCare();

  const tier =
    showTierProviderTag && selectedLocation?.isTieredProvider ? 'tier 1' : '';
  const { hasPCPServiceAvailability } = selectedLocation || {};

  let providerRole = 'specialist';
  if (hasPCPServiceAvailability) {
    providerRole += '|pcp';
  }
  const ksBanner =
    enableIFP &&
    toShowKSBannerOnProviderDetailsAbout(
      coverageType,
      providerDetails,
      currentMember,
      KsAffliatedTins
    );

  const { storeCoverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      storeCoverageType: state.coverageType,
    }))
  );
  const { typeaheadState } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadState: state.typeaheadSearchStore,
    }))
  );
  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );
  const { searchByProvider } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      searchByProvider: state.detailsState.searchByProvider,
    }))
  );
  const isBHCare = storeCoverageType?.includes(
    ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator: getIndicatorsForProviderDetailsAboutTabsImpressions(
        selectedLocation,
        featureFlags,
        coverageType
      ),
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty:
        convertSpecialtiesList(
          providerDetails?.defaultTranslatedValues?.specialties
        ) || '',
      tier,
      role: providerRole || '',
    },

    searchBlock: getSearchBlock(typeaheadState, linkName),
  });

  useEffect(() => {
    if (selectedLocation) {
      adobePageTrackEvent();
    }
    setPageTracked(true);
  }, [selectedLocation !== undefined]);

  const userState = selectedLocation?.address?.state;
  const { data: referralRequiredSpecialties = [] } = useSpecialtiesResult({
    isReferralRequired: true,
    userState,
  });

  const providerSpecialties = providerDetails?.specialties;

  const referralCheck = referralRequiredSpecialties?.find(
    (specialityWithReferral) =>
      specialityWithReferral?.name?.toLowerCase() ===
      providerSpecialties?.[0]?.toString()?.toLowerCase()
  );

  const result = referralRequiredSpecialties.filter((allSpecialties) =>
    providerSpecialties.find(
      (referralSpecialty) =>
        referralSpecialty?.toLocaleLowerCase() ===
        allSpecialties?.name?.toLocaleLowerCase()
    )
  );

  const isProviderSpeciality = (searchResults: string | undefined) =>
    searchResults && searchResults.length > 0;
  const searchBySpecialtyOrProvider = searchByProvider
    ? isProviderSpeciality(result)
    : referralCheck;
  const nameAttrs = getNameAttrs(providerDetails);

  return (
    <div style={{ overflow: 'auto hidden', marginTop: '8px' }}>
      {!!currentMember?.isReferralRequired &&
        searchBySpecialtyOrProvider &&
        showReferralBanner && (
          <ReferralBannerBox
            data-auto-testid="referral-banner-box"
            data-testid="referral-banner-box"
            pcpData={pcpData?.primaryCare}
            specialtyWithReferral={result}
          />
        )}
      {isBHCare && eapCodeFlag && isPageTracked && (
        <ContainerForAllSectionsStyled css={{ marginBottom: '8px' }}>
          <ContentWrapper>
            <EAPCode />
          </ContentWrapper>
        </ContainerForAllSectionsStyled>
      )}
      {ksBanner ? <KsAffiliatesBanner /> : null}
      <BiographySection
        cnsEthnicity={providerDetails?.cnsEthnicity}
        cnsRace={providerDetails?.cnsRace}
        content={providerDetails?.biographicData}
        data-auto-testid="about-tab-biography-section"
        data-testid="about-tab-biography-section"
        ethnicity={providerDetails?.ethnicity}
        gender={providerDetails?.gender}
        heading="BIOGRAPHY_SECTION.HEADING"
        language={providerDetails?.languagesSpoken}
        providerType={providerDetails?.providerType}
      />
      {!suppressReviews && (
        <PatientReviews
          providerDetails={providerDetails}
          selectedLocation={selectedLocation}
        />
      )}
      <CollapseDetailsSectionRoot
        areaOfExpertise={providerDetails?.areaOfExpertise}
        culturalCompetencies={
          isEnableL2CulturalCompetencies
            ? providerDetails?.culturalCompetencyCategories
            : providerDetails?.culturalCompetencies
        }
        educationInfo={providerDetails?.educationInfo}
        frequentServices={providerDetails?.servicesProvided}
        hospitalAffiliations={providerDetails?.hospitalAffiliations}
        isFrequentServicesProvidedEnabled={isFrequentServicesProvidedEnabled}
        isProviderExperienceSectionEnabled={isProviderExperienceSectionEnabled}
        licenseNumber={providerDetails?.licenseNumber}
        npi={providerDetails?.npi}
        providerName={parseProviderName(
          providerDetails?.providerName,
          providerDetails?.providerType,
          providerDetails?.primaryDegrees?.[0],
          nameAttrs
        )}
        providerType={providerDetails?.providerType}
        specialties={providerDetails?.specialties}
        userState={userState}
      />
    </div>
  );
};
