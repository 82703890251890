import { config } from '@abyss/web/tools/config';

import REPORT_INACCURATE_PROVIDER from './ReportInaccurateProvider.graphql';
import { useQuery } from '@abyss/web/hooks/useQuery';

export const useReportInaccurateProvider = (options) => {
 const [queryResults, queryAction] = useQuery(REPORT_INACCURATE_PROVIDER, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'reportInaccurateProvider',
    onCompleted: (result) => {
      if(options?.handleSuccess) {
        options?.handleSuccess();
      }
      return result;
    },
    onError: (error) => {
      if(options?.handleError) {
        options?.handleError();
      }
      throw error;
    }
  });

   return [queryResults, queryAction];
}
