import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePrint } from '@abyss/web/hooks/usePrint';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import {
  FilterCollectionModel,
  INITIAL_PAGE,
  PAGE_SIZE_DOWNLOAD,
  SHOULD_GET_HG_DATA,
} from '../../../../common/Constants';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { getFeatureFlag } from '../../../../common/Utils';
import { handleLinkAndModalTrack } from '../../../../common/Utils/adobeTrackUtils';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import {
  useGetTokenOrUrlParams,
  useInitializeStore,
} from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useFilterProviderResults } from '../../../../hooks/useProviderSearch';
import { CompareProvider } from '../../../../models/Provider';
import { ProviderSearchFilters } from '../../../../models/ProviderSearch';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { sleep } from '../../../../utils';
import { PrintView } from '../MapView/Print/PrintView';
import { hideFeedbackContainer } from '../MapView/ShareDrawer/utils';
import { ShareCompareButtonStyled } from './ShareCompareButton';

type Props = {
  text: string;
  view: String;
  setOpenBoxContents: (a: boolean) => void;
  setOpenShare: (a: boolean) => void;
  setOpenCompare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  isClicked: boolean;
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
  enableMapViewEnhancements: boolean;
  pageSize?: number;
  printPage?: number;
};

export const DownloadResultsButton = ({
  text,
  setOpenBoxContents,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  setOpenCompare,
  isClicked,
  setIsClicked,
  enableMapViewEnhancements,
  view,
  pageSize,
  printPage,
}: Props) => {
  const { t } = useTranslation();
  const print = usePrint();
  const textColor = isClicked ? '$tint1' : '$info1';
  const mobileScreen = useMediaQuery(phoneOnly);
  const urlParams = useGetTokenOrUrlParams();
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE);
  const featureFlags = useLagoon('feature-flags')();
  const showDownloadResultsButton = getFeatureFlag(
    featureFlags,
    'SHARE_PDF_ALL_RESULTS'
  );
  const { coverageType } = useInitializeStore(
    ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key
  );
  const shouldGetHGData = SHOULD_GET_HG_DATA;

  const { searchFilters: selectedFilters, setSearchFilters } =
    useContext(SearchFilterContext);
  const [filters, setFilters] =
    useState<ProviderSearchFilters>(selectedFilters);
  const { results = [] } = useFilterProviderResults({
    pageNumber,
    pageSize: pageSize || PAGE_SIZE_DOWNLOAD,
    selectedFilters: filters,
    coverageType,
    shouldGetHGData,
  });

  const handleAdobeButtonClickTrack = (buttonName) => {
    const linkName = `${buttonName} button`;
    const location = `body:${chipValue} view all results`;
    const modalName: string = `${buttonName} print PDF view`;
    handleLinkAndModalTrack(linkName, location, modalName);
  };

  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );

  const handleButton = async () => {
    setIsClicked(true);
    setOpenBoxContents(false);
    setOpenShare(false);
    setOpenCompare(false);
    setSelectedCheckbox({ checked: {} });
    setSelectedItems([]);
    await sleep(1000);
    hideFeedbackContainer(true);
    print.printPage(() => {}, {
      openNewWindow: false,
    });
    hideFeedbackContainer(false);
    handleAdobeButtonClickTrack('download results');
  };

  useEffect(() => {
    const handleAfterPrint = () => {
      setIsClicked(false);
    };
    window.onafterprint = handleAfterPrint;

    return () => {
      window.onafterprint = null;
    };
  }, []);

  useEffect(() => {
    if (JSON.stringify(selectedFilters) !== JSON.stringify(filters)) {
      setFilters(selectedFilters);
      setPageNumber(INITIAL_PAGE);
    }
  }, [JSON.stringify(selectedFilters)]);

  useEffect(() => {
    setPageNumber(printPage || INITIAL_PAGE);
  }, [printPage]);

  useEffect(() => {
    if (
      urlParams?.virtualCare &&
      !selectedFilters[FilterCollectionModel.VIRTUAL_CARE]?.value
    ) {
      const virtualCare = {
        label: t('FILTER_OPTIONS.VirtualCare.Y'),
        value: true,
        dnt_label: translationsEnJson.FILTER_OPTIONS.VirtualCare.Y,
      };
      setSearchFilters({
        ...selectedFilters,
        [FilterCollectionModel.VIRTUAL_CARE]: virtualCare,
      });
    }
  }, []);

  return (
    showDownloadResultsButton && (
      <ShareCompareButtonStyled
        aria-pressed={!!isClicked}
        color={!mobileScreen && isClicked ? 'primary' : 'nocolor'}
        css={
          mobileScreen &&
          !enableMapViewEnhancements && {
            '&.abyss-button-root': {
              justifyContent: 'start !important',
              border: 'solid 2px $primary1',
              '&:active, &:focus, &:hover': {
                backgroundColor: '#CCF2F7',
              },
            },
            width: '188px',
            justifyContent: 'start',
          }
        }
        data-auto-testid="filter-buttons-download-results"
        data-testid="filter-buttons-download-results"
        id="mybutton"
        onClick={handleButton}
        size={mobileScreen && !enableMapViewEnhancements ? '$sm' : '$md'}
        variant={
          mobileScreen && !enableMapViewEnhancements ? 'outline' : 'ghost'
        }
        view={view}
      >
        <Flex alignContent="center" alignItems="center" justify="center">
          <IconMaterial
            color={isClicked ? '$tint1' : '$info1'}
            icon="download"
          />
          <Text
            color={
              mobileScreen && !enableMapViewEnhancements
                ? '$primary1'
                : textColor
            }
            css={{ paddingLeft: '5px' }}
            data-auto-testid="filter-buttons-download-results"
            data-testid="filter-buttons-download-results"
            fontWeight={mobileScreen && !enableMapViewEnhancements && 600}
          >
            {text}
          </Text>
          {isClicked && <PrintView selectedProviderList={results} />}
        </Flex>
      </ShareCompareButtonStyled>
    )
  );
};
