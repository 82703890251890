import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompareProvider, Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { getDistance } from '../../utils/card.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { adobeSearchResultClickEvent } from '../AdobeTagging/adobeSearchResultClickEvent';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import {
  convertProviderTypeToAdobeType,
  formatProviderId,
  handleAdobeSearchResultClickAndModalEvent,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { MilesButton } from './DataCard.style';
import {
  IconMaterialMobile,
  IconMaterialWeb,
  MilesContainer,
  MobileMilesContainer,
  popoverMobileStyle,
} from './Miles.style';

type Props = {
  color?: string;
  distance: string;
  fontWeight?: string;
  headers?: ResponseHeaders;
  id?: string;
  popupContainer?: boolean;
  indexForAnalytics?: number;
  sectionType?: string;
  practitioner?: Provider;
  providerType?: string;
  locationForAnalytics?: string;
  selectedFilters?: string;
  selectedProvider?: CompareProvider | any;
  calledFrom?: string;
  searchTerm?: string;
  onClosePopup?: () => void;
  isCTADisplayLogic?: boolean;
  onMilesClick?: () => void;
};

export const Miles = ({
  color = '$interactive1',
  distance,
  fontWeight = '$bold',
  headers = { correlationId: '' },
  id,
  sectionType,
  indexForAnalytics,
  popupContainer,
  providerType,
  locationForAnalytics,
  practitioner = {},
  selectedFilters,
  calledFrom,
  searchTerm,
  selectedProvider = {},
  onClosePopup,
  isCTADisplayLogic = false,
  onMilesClick,
}: Props) => {
  const { t } = useTranslation();
  const distanceTextWithMiles: string = `${getDistance(distance)}
    mi`;
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);
  const distanceDirectionTestId: string = 'distance-direction';
  const resultCardLocationInfoPopOverDataTestId: string = mobileScreen
    ? 'provider-location-detail-miles-Info-drawer'
    : 'provider-location-detail-miles-Info-popover';
  const modalEventName: string = Constants.CONTENT_FILTERS.DISTANCE;

  const locationForAdobe = `modal:compare card:position ${indexForAnalytics}`;

  const linkTrack = () => {
    adobeLinkTrackEvent({
      name: modalEventName,
      location: locationForAdobe,
      type: 'internal',
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(selectedProvider),
        providerType: convertProviderTypeToAdobeType(
          selectedProvider?.providerType
        ),
      },
    });
  };
  const searchTrack = () => {
    adobeSearchResultClickEvent({
      filters: selectedFilters,
      linkName: 'distance',
      linkPosition: indexForAnalytics,
      linkLocation: locationForAnalytics,
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(practitioner),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
      term: searchTerm ?? '',
    });
  };
  const trackAdobeEvent = () => {
    if (calledFrom && calledFrom === 'comparecard') {
      linkTrack();
    } else {
      searchTrack();
    }
  };
  useEffect(() => {
    if (isOpen) {
      if (calledFrom && calledFrom === 'comparecard') {
        linkTrack();
        adobeModalTrackEvent(modalEventName);
      } else {
        handleAdobeSearchResultClickAndModalEvent({
          filters: selectedFilters,
          linkName: resultCardLocationInfoPopOverDataTestId,
          linkPosition: indexForAnalytics,
          linkLocation: locationForAnalytics,
          modalName: modalEventName,
          customAttributesBlock: {
            correlationId: headers.correlationId,
            providerId: formatProviderId(practitioner),
            providerType: convertProviderTypeToAdobeType(providerType),
          },
          term: searchTerm ?? '',
        });
      }
    }
  }, [isOpen]);

  const MilesWithInfoIcon = () => (
    <MilesButton
      className="icon-text-container"
      data-auto-testid={distanceDirectionTestId}
      data-testid={distanceDirectionTestId}
      onClick={onMilesClick}
    >
      <Text
        color={isCTADisplayLogic ? '$gray7' : color}
        css={{
          fontSize: popupContainer ? '$sm' : '$md',
          fontWeight: popupContainer ? '700' : '400',
          color: popupContainer ? '#196ECF' : '$gray7',
          lineHeight: '10px',
          '@screen < $sm': {
            fontSize: '$sm',
            lineHeight: '18px',
          },
          cursor: 'pointer',
        }}
        data-auto-testid={`data-card-distance-miles-count-${sectionType}-${id}`}
        data-testid={`data-card-distance-miles-count-${sectionType}-${id}`}
        fontWeight={isCTADisplayLogic ? '$small' : fontWeight}
      >
        {distanceTextWithMiles}
      </Text>
    </MilesButton>
  );

  return (
    <React.Fragment>
      {!mobileScreen ? (
        <MilesContainer>
          {isCTADisplayLogic && <MilesWithInfoIcon />}
          <Popover
            align="center"
            aria-label={`${distanceTextWithMiles} ${t(
              'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_DISTANCE'
            )}`}
            content={t('DISTANCE_POPOVER_CONTENT')}
            css={{
              '&:focus-visible': {
                margin: '5px',
              },
            }}
            data-auto-testid={resultCardLocationInfoPopOverDataTestId}
            data-testid={resultCardLocationInfoPopOverDataTestId}
            onClick={(event) => {
              trackAdobeEvent();
              event.stopPropagation();
            }}
            onOpenChange={() => {
              setIsOpen(!isOpen);
            }}
            open={isOpen}
            title={<Text size="$sm">{t('CONTENT_FILTERS.DISTANCE')}</Text>}
            width={220}
          >
            <Layout.Group space={4}>
              {!isCTADisplayLogic && <MilesWithInfoIcon />}
              <IconMaterial
                css={IconMaterialWeb}
                data-auto-testid="provider-location-detail-miles-Info"
                data-testid="provider-location-detail-miles-Info"
                icon="info"
                size={popupContainer ? 18 : 20}
                tabIndex={-1}
                variant="outlined"
              />
            </Layout.Group>
          </Popover>
        </MilesContainer>
      ) : (
        <MobileMilesContainer
          aria-label={`${distanceTextWithMiles} ${t(
            'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_DISTANCE'
          )}`}
        >
          <MilesButton
            className="icon-text-container"
            data-auto-testid={distanceDirectionTestId}
            data-testid={distanceDirectionTestId}
            onClick={onMilesClick}
          >
            <Text
              color={isCTADisplayLogic ? '$gray7' : color}
              css={{
                fontSize: popupContainer ? '$sm' : '$md',
                lineHeight: '10px',
                '@screen < $sm': {
                  fontSize: '$sm',
                  lineHeight: '16px',
                },
              }}
              data-auto-testid={`data-card-distance-miles-count-${sectionType}-${id}`}
              data-testid={`data-card-distance-miles-count-${sectionType}-${id}`}
              fontWeight={isCTADisplayLogic ? '$small' : fontWeight}
            >
              {distanceTextWithMiles}
            </Text>
            <IconMaterial
              aria-haspopup="dialog"
              css={IconMaterialMobile}
              data-auto-testid="provider-location-detail-miles-Info"
              data-testid="provider-location-detail-miles-Info"
              icon="info"
              onClick={(event) => {
                trackAdobeEvent();
                setIsOpen(true);
                event?.stopPropagation();
              }}
              size={18}
              variant="outlined"
            />
          </MilesButton>
          <Drawer
            css={popoverMobileStyle}
            data-auto-testid={resultCardLocationInfoPopOverDataTestId}
            data-testid={resultCardLocationInfoPopOverDataTestId}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              if (onClosePopup) onClosePopup();
            }}
            position="bottom"
            title={t('CONTENT_FILTERS.DISTANCE')}
          >
            <Drawer.Section>{t('DISTANCE_POPOVER_CONTENT')}</Drawer.Section>
          </Drawer>
        </MobileMilesContainer>
      )}
    </React.Fragment>
  );
};
