import { styled } from '@abyss/web/tools/styled';

export const TierBadgeContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '329px',
  '@screen < $sm': {
    width: '80vw',
  },
});

export const TierBadgeIcon = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#E5F0E5',
  padding: '4px 8px',
  borderRadius: '4px',
  gap: '4px',
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  justifyContent: 'flex-end !important',
  flexShrink: 0,
};

export const AddressWrapper = styled('div', {
  width: '200px',
});
