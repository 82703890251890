import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { ProviderLocation } from '../../../models/ProviderDetails';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import { getUiMessageFromLagoon } from '../../../utils/common';
import { returnSuppressFlag } from '../../../utils/featureSuppress';
import { shortenZipCode } from '../../../utils/providerDetails.utils';
import { capitalizeEachWord } from '../../../utils/providerNames.utils';
import {
  Constants,
  PremiumCareConstants,
  ReverseCoverageTypesCodes,
} from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import {
  landingDrawerIcon,
  mobileOnly,
  phoneOnly,
} from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  handleLinkAndModalTrack,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { ListItem } from '../About/CollapseProviderDetailsSection/CollapseProviderDetailsSection';
import {
  ContentText,
  PopoverContentContainer,
  PopoverIcon,
  StackDiv,
  TabContainer,
  boardCertificationsDescriptionStyle,
  drawerStyle,
  headerText,
  premiumCareSection,
  premiumTextIconStyle,
  qualityText,
  stackDivStyle,
} from './Quality.styled';

type Props = {
  boardCertifications?: string[];
  coverageType?: string;
  providerName: string;
  providerType: string;
  selectedLocation: ProviderLocation;
  tabTitle: string;
  organizationCode?: string[];
};

export const Quality = ({
  boardCertifications,
  coverageType,
  providerName,
  providerType,
  selectedLocation,
  tabTitle,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const { coverageType: widgetCoverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );
  const mobileScreen = useMediaQuery(mobileOnly);
  const WidgetDesktopScreen = !useMediaQuery(phoneOnly) && isWidget;
  const phoneScreen = useMediaQuery(phoneOnly);
  const phoneScreenNoWidget = useMediaQuery(phoneOnly);
  const { address, distance, isPremiumCarePhysician } = selectedLocation || {};
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerType
    )} details`,
    providerBlock: {
      type: convertCoverageType(coverageType),
      role:
        isPremiumCarePhysician ===
        Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM_CARE_YES
          ? Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM.toLowerCase()
          : '',
    },
  });
  useEffect(() => {
    if (providerName) adobePageTrackEvent();
  }, [providerName]);

  const { line: addressLine, city, state, postalCode } = address || {};
  const shortenedZip = shortenZipCode(postalCode);

  const locale = getLanguage().code;
  const premiumCareData = PremiumCareConstants[locale];
  const navigationURL = premiumCareData?.popoverURL ?? '';
  const premiumCarePhysicianContent = premiumCareData?.popoverContent ?? '';

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const [enableBoardCertifiedProvidersFF] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BOARD_CERTIFIED_PROVIDERS,
  ]);

  const isEnabledBoardCertifiedProviders =
    (coverageType === ReverseCoverageTypesCodes.MEDICAL ||
      widgetCoverageType === ReverseCoverageTypesCodes.MEDICAL) &&
    enableBoardCertifiedProvidersFF;

  const boardCertificationsSectionDescription = getUiMessageFromLagoon(
    ConstantsLagoon.UI_MESSAGING.BOARD_CERTIFICATIONS_SECTION_DESCRIPTION
  );

  useEffect(() => {
    if (isOpen) {
      const linkName: string = Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM;
      const linkLocation: string = `body:${convertProviderTypeToAdobeType(
        providerType
      )} details`;
      const modalName: string = Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const premiumCarePopoverContent = (
    <PopoverContentContainer>
      <ContentText
        data-auto-testid="premium-care-physician-info-text"
        data-testid="premium-care-physician-info-text"
      >
        <Text
          color="#323334"
          fontWeight={mobileScreen ? '500' : '400'}
          size={mobileScreen ? '$md' : '14px'}
        >
          {premiumCarePhysicianContent}
        </Text>
      </ContentText>
      <LinkWithTracking
        analyticsInfo={{
          name: 'Get my premium care',
          location: `modal:${Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM}`,
          type: 'download',
          destinationUrl: navigationURL,
        }}
        data-auto-testid="premium-care-physician-popover"
        data-testid="premium-care-physician-popover"
        href={navigationURL}
      >
        <Text
          color="#196ECF"
          css={{
            margin: '4px 0 4px 0',
            '@screen < $sm': {
              margin: '8px 0 8px 0',
            },
          }}
          fontWeight="700"
          size="$md"
        >
          {t('PROVIDER_DETAILS.QUALITY_TAB.POPOVER.POPOVER_LINK_TEXT')}
        </Text>
      </LinkWithTracking>
    </PopoverContentContainer>
  );

  const boardCertificationsList = (boardCertifications) =>
    boardCertifications?.length ? (
      boardCertifications?.map((certification) =>
        isEnabledBoardCertifiedProviders ? (
          <li key={`certification-${certification}`}>
            {boardCertificationItem(certification)}
          </li>
        ) : (
          boardCertificationItem(certification)
        )
      )
    ) : (
      <Text css={qualityText(mobileScreen)}>
        {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
      </Text>
    );

  const boardCertificationItem = (certification) => (
    <Text
      css={qualityText(mobileScreen)}
      key={`certification-${certification}`}
    >
      {capitalizeEachWord(certification)}
    </Text>
  );

  return (
    <TabContainer
      data-testid="provider-quality-tab"
      key={Constants.PROVIDER_DETAILS.QUALITY}
    >
      <Layout.Stack alignItems="center" grow space="-8">
        {isPremiumCarePhysician ===
          Constants.PROVIDER_DETAILS.QUALITY_TAB.PREMIUM_CARE_YES &&
          !suppressPremiumCare && (
            <StackDiv css={stackDivStyle(mobileScreen, WidgetDesktopScreen)}>
              <Layout.Group css={premiumCareSection(phoneScreen)} space="0">
                <IconMaterial
                  color="$primary1"
                  icon="favorite"
                  size={mobileScreen ? '20' : '24'}
                />
                <IconMaterial
                  color="$primary1"
                  css={premiumTextIconStyle(mobileScreen)}
                  icon="favorite"
                  size={mobileScreen ? '20' : '24'}
                />
                <Heading
                  css={headerText(mobileScreen)}
                  data-testid="premium-care-physician-heading"
                  display="h6"
                  offset={1}
                >
                  {t('PROVIDER_DETAILS.QUALITY_TAB.PREMIUM')}
                </Heading>
                <Drawer
                  css={landingDrawerIcon}
                  isOpen={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  position="bottom"
                  size="200"
                  title={t('PROVIDER_DETAILS.QUALITY_TAB.PREMIUM')}
                >
                  {premiumCarePopoverContent}
                </Drawer>
                {phoneScreenNoWidget ? (
                  <IconMaterial
                    css={drawerStyle}
                    data-auto-testid="premium-care-physician-drawer"
                    data-testid="premium-care-physician-drawer"
                    icon="info"
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    size={16}
                    variant="outlined"
                  />
                ) : (
                  <Popover
                    content={premiumCarePopoverContent}
                    data-testid="premium-care-physician-info"
                    onOpenChange={() => setIsOpen(!isOpen)}
                    open={isOpen}
                    title={t('PROVIDER_DETAILS.QUALITY_TAB.PREMIUM')}
                    width={363}
                  >
                    <PopoverIcon
                      icon="info"
                      size={mobileScreen ? '16' : '20'}
                      variant="outlined"
                    />
                  </Popover>
                )}
              </Layout.Group>
              <Layout.Stack alignItems="left" space="0">
                <Text css={qualityText(mobileScreen)}>
                  {addressLine?.join(', ')}
                </Text>
                <Text css={qualityText(mobileScreen)}>
                  {city}, {state} {shortenedZip} ({distance} {t('miles')})
                </Text>
              </Layout.Stack>
            </StackDiv>
          )}

        <StackDiv css={stackDivStyle(mobileScreen, WidgetDesktopScreen, true)}>
          <Grid space="0">
            <Grid.Col span={{ xs: '100%', sm: '100%', md: '70%' }}>
              <Layout.Stack alignItems="left" space="12">
                <Heading css={headerText(mobileScreen)} display="h4" offset={1}>
                  {t('PROVIDER_DETAILS.QUALITY_TAB.BOARD_CERTIFICATIONS_TITLE')}
                </Heading>
                {isEnabledBoardCertifiedProviders ? (
                  <>
                    <Text
                      css={boardCertificationsDescriptionStyle(mobileScreen)}
                    >
                      {boardCertificationsSectionDescription}
                    </Text>
                    <ListItem>
                      {boardCertificationsList(boardCertifications)}
                    </ListItem>
                  </>
                ) : (
                  boardCertificationsList(boardCertifications)
                )}
              </Layout.Stack>
            </Grid.Col>
          </Grid>
        </StackDiv>
      </Layout.Stack>
    </TabContainer>
  );
};
