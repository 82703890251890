import { useCallback, useEffect } from 'react';

import { Constants, LOB, ProviderTypes } from '../../../../common/Constants';
import { useProviderResults } from '../../../../hooks/useProviderSearch';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { getProviderSearchParams } from '../../../../utils/providerSearch.utils';
import { getCurrentMember } from '../../../../utils/user.utils';
import { checkError, formatData, getDataForMixedResults } from './searchUtils';

type GetProviderSearchResultsProps = {
  setShowFacilities: (x: boolean) => any;
  setShowProviderGroups: (x: boolean) => any;
  setShowProviders: (x: boolean) => any;
  providerSearchParams: any;
  choosePCP?: boolean;
  cleanUpProviderCalls: boolean;
  pseudoVerticalsDetails: any;
  selectedCategory: any;
  selectedCategoryPayload: any;
  specialtySearch: boolean;
  searchType: string;
  providerQueryTypes: any[];
};

export const useSearchResultsProviderResults = (customParams: any) => {
  const [providersResult, getProvidersResult] =
    useProviderResults(customParams);
  const [organizationResult, getOrgResult] = useProviderResults(customParams);
  const [medGrpResult, getMedGroupResult] = useProviderResults(customParams);
  const currentMember = getCurrentMember();
  const setIsTier1Plan = useStore(StoreKeys.SET_TIER1_PLAN_FLAG);
  const {
    data: practitionerData,
    isLoading: providerIsLoading,
    error: providerHasError,
  } = providersResult;

  const {
    data: orgdata,
    isLoading: orgIsLoading,
    error: orgHasError,
  } = organizationResult;

  const {
    data: medGrpData,
    isLoading: medGrpIsLoading,
    error: medGrpHasError,
  } = medGrpResult;

  const data: any = formatData(practitionerData, orgdata, medGrpData);
  const { isTieredPlan } = data || {};
  const error: boolean =
    !medGrpIsLoading &&
    !orgHasError &&
    !providerHasError &&
    checkError(providerHasError, orgHasError, medGrpHasError);

  useEffect(() => {
    setIsTier1Plan(isTieredPlan);
  }, [isTieredPlan]);

  const getProviderSearchResults = useCallback(
    ({
      providerSearchParams,
      setShowFacilities,
      setShowProviderGroups,
      setShowProviders,
      choosePCP = false,
      cleanUpProviderCalls,
      pseudoVerticalsDetails,
      selectedCategory,
      selectedCategoryPayload,
      specialtySearch,
      searchType,
      providerQueryTypes,
    }: GetProviderSearchResultsProps) => {
      const canFetchData = selectedCategory || choosePCP;
      if (!canFetchData) {
        return;
      }

      const callProvidersResult = (params, code) => {
        const enableProviderSearch =
          providerQueryTypes?.length > 0
            ? providerQueryTypes.filter(
                (item) =>
                  ProviderTypes[item.providerType] === params.providerType
              ).length > 0
            : true;

        const enableProviderGroupSearch =
          enableProviderSearch &&
          (!choosePCP || currentMember.lineOfBusiness === LOB.ENI);
        const enableFacilitySearch = enableProviderSearch && !choosePCP;

        const providerTypeMapping = {
          P: enableProviderSearch
            ? () => {
                getProvidersResult(params);
                setShowProviders(true);
              }
            : () => {},
          M: enableProviderGroupSearch
            ? () => {
                getMedGroupResult(params);
                setShowProviderGroups(true);
              }
            : () => {},
          O: enableFacilitySearch
            ? () => {
                getOrgResult(params);
                setShowFacilities(true);
              }
            : () => {},
        };

        providerTypeMapping[code]();
      };

      const { practitionerParams, orgParams, medGrpParams } =
        getProviderSearchParams(
          providerSearchParams,
          selectedCategoryPayload,
          specialtySearch ? Constants.SEARCH_TYPES.SPECIALTY : searchType,
          choosePCP,
          cleanUpProviderCalls,
          providerQueryTypes
        );

      getDataForMixedResults({
        callProvidersResult,
        setShowFacilities,
        setShowProviderGroups,
        setShowProviders,
        medGrpParams,
        orgParams,
        practitionerParams,
        choosePCP,
        cleanUpProviderCalls,
        pseudoVerticalsDetails,
        searchType: providerSearchParams.searchType,
        selectedCategory,
        memberLob: currentMember.lineOfBusiness,
        selectedCategoryPayload,
      });
    },
    [getProvidersResult, getOrgResult, getMedGroupResult]
  );

  return {
    data,
    error,
    medGrpIsLoading,
    medGrpResult,
    providerIsLoading,
    medGrpData,
    providersResult,
    providerHasError,
    orgIsLoading,
    organizationResult,
    getProviderSearchResults,
  };
};
