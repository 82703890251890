export const EmpirePolicyAlertStyling = {
  '.abyss-alert-title': {
    lineHeight: '22px',
    color: '#323333',
  },
  '.abyss-alert-container': {
    height: '52px',
    borderWidth: '1px 0px 1px 0px',
  },
  '.abyss-alert-header-container': {
    marginLeft: 'auto',
    maxWidth: '1200px',
    marginRight: 'auto',
    alignItems: 'center',
  },
  '.abyss-icon-symbol ': {
    fontSize: '20px !important',
    paddingTop: '2px',
  },
  '.abyss-alert-children-container': {
    lineHeight: '18px',
    fontSize: '14px',
    fontWeight: '400',
    color: '#323333',
  },
  '.abyss-alert-icon-container': {
    marginRight: '4px',
  },
  '.abyss-alert-header-text-container': {
    flexDirection: 'row',
    '@media (max-width: 593px)': {
      flexDirection: 'column',
    },
    '@screen < $sm': {
      flexDirection: 'column',
    },
  },
};
