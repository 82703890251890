import { isHospitals } from '../../../../utils/providerDetails.utils';

type EmpirePolicyProps = {
  policyNumber: string;
  hasMedicalCoverage?: boolean;
  allConfigs: any;
  featureInclusion: any;
  organizationCode: string | string[];
};
/** US7777523: Display Banner on Hospital details page(Location) for Empire plans - if member has Medical coverage,
 * if is Hospital,
 * if member has Empire policy and
 * if Empire policy number matches with fetureInclusion lagoon table - then return true to display banner.
 */
export const toShowEmpirePolicyHospitalBanner = ({
  policyNumber,
  hasMedicalCoverage = true,
  allConfigs,
  featureInclusion,
  organizationCode,
}: EmpirePolicyProps) => {
  if (hasMedicalCoverage) {
    const hospitalsOrgCodes = allConfigs?.find(
      (row) => row.key === 'EMPIRE_POLICY_HOSPITAL_ORG_CODES'
    )?.value;

    const isHospital = isHospitals(organizationCode, hospitalsOrgCodes);
    const empirePolicy = featureInclusion
      ?.find((row) => row.dntFeature === 'AnthemBlueCross_Disclaimer')
      ?.policyIds.includes(policyNumber);
    if (isHospital && empirePolicy) {
      return true;
    }
  }
  return false;
};
