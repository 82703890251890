import { Alert } from '@abyss/web/ui/Alert';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import {
  AlertStyling,
  LimitedProviderBannerStyling,
} from '../../../common/DetailsPageHeader/DetailsPageHeader.styled';
import { getFeatureFlag } from '../../../common/Utils';
import { Footer } from '../../../frontends/ProviderSearch/routes/Footer';
import { useLagoon } from '../../../hooks/useLagoon';
import { useProviderDetailsWidget } from '../../../hooks/useProviderDetails';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { ProviderType } from '../../../models/ProviderDetails';
import {
  UserDDPCode,
  getNetworkStatus,
  getSelectedLocationIndex,
} from '../../../utils/providerDetails.utils';
import {
  formatProviderNameWithFirstMiddleLastName,
  getNameAttrs,
} from '../../../utils/providerNames.utils';
import { CardWrapper, CardWrapperDetailsPage } from '../../CardWrapper';
import { ProviderTabs, SHOULD_GET_HG_DATA } from '../../Constants';
import { DisclaimersContainer } from '../../DisclaimersContainer/DisclaimersContainer';
import { DetailsWidgetHeader } from './DetailsWidgetHeader';
import { DetailsWidgetNoDetails } from './DetailsWidgetNoDetails';
import { DetailsWidgetSkeleton } from './DetailsWidgetSkeleton';
import { DetailsWidgetTabs } from './DetailsWidgetTabs';
import { WidgetDisclaimersWrapper } from './DetailsWidgetView.styled';
import { ErrorHandlingWidget } from './ErrorHandlingWidget';
import { About } from './WidgetPages/About';
import { Locations } from './WidgetPages/Locations';
import { Quality } from './WidgetPages/Quality';

type Props = {
  claimSystemTypeCode?: string;
  coverageType: string;
  ddpUserCode: UserDDPCode;
  eligibilitySystemTypeCode?: string;
  latitude: string;
  lob: string;
  locationId: string;
  longitude: string;
  outOfPocketCost?: string;
  planVariationCode?: string;
  planYear: string;
  policyId: string;
  providerId: string;
  providerType: ProviderType;
  reciprocityId: string;
  totalCost?: string;
  membershipCategory?: string;
  population?: string;
};

export const DetailsWidgetView = ({
  claimSystemTypeCode,
  coverageType,
  ddpUserCode,
  eligibilitySystemTypeCode,
  latitude,
  lob,
  locationId,
  longitude,
  outOfPocketCost,
  planVariationCode,
  planYear,
  policyId,
  providerId,
  providerType,
  reciprocityId,
  totalCost,
  membershipCategory,
  population,
}: Props) => {
  const defaultActiveTab =
    providerType === ProviderType.ORGANIZATION
      ? ProviderTabs.LOCATIONS
      : ProviderTabs.ABOUT;
  const [activeTab, setActiveTab] = useState<ProviderTabs>(defaultActiveTab);
  const [retry, toggleRetry] = useState<boolean>(false);

  const suppressionLagoonData = useLagoon('feature-supression')();
  const setUIFeatureSuppressionState = useStore(
    StoreKeys.SET_UI_FEATURE_SUPPRESSION
  );
  const shouldGetHGData = SHOULD_GET_HG_DATA;

  const {
    data: providerData,
    error,
    isLoading,
  } = useProviderDetailsWidget({
    claimSystemTypeCode,
    coverageType,
    eligibilitySystemTypeCode,
    latitude,
    lob,
    locationId,
    longitude,
    planVariationCode,
    planYear,
    policyId,
    providerId,
    providerType,
    reciprocityId,
    shouldGetHGData,
    membershipCategory,
    population,
  });

  const handleRetry = () => toggleRetry(!retry);

  useEffect(() => {
    setUIFeatureSuppressionState({
      suppressionLagoonData,
      memberPolicy: policyId,
    });
  }, []);

  const providerDetails =
    providerData.facilityDetails?.providerDetails ||
    providerData?.providerDetails?.providerDetails;

  if (isLoading) {
    return <DetailsWidgetSkeleton />;
  }

  if (!providerDetails) {
    return <DetailsWidgetNoDetails />;
  }

  if (error) {
    return <ErrorHandlingWidget handleRetry={handleRetry} />;
  }

  const {
    areaOfExpertise,
    biographicData,
    boardCertifications,
    culturalCompetencies,
    educationInfo,
    ethnicity,
    gender,
    hospitalAffiliations,
    imageLocation,
    healthGradeRating,
    languagesSpoken,
    licenseNumber,
    npi,
    organizationType = [],
    primaryDegrees,
    providerLocations = [],
    providerName,
    specialties,
    surveyCount,
    virtualCareOffered,
    organizationCode = [],
  } = providerDetails;

  const selectedIndex = getSelectedLocationIndex(providerLocations, locationId);
  const selectedLocation = providerLocations?.[selectedIndex] || {};

  const {
    address,
    ddpCode: ddpFacilityCode,
    facilityProviderSpecialties = {},
    network,
  } = selectedLocation;
  const { isPreferredLabNetwork, isFreeStandingFacility } =
    facilityProviderSpecialties;
  const networkStatus = getNetworkStatus(network?.status);
  const nameAttrs = getNameAttrs(providerDetails);

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const lagoonData = useLagoon('ui-messaging')();

  const limitedAccessBanner = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.LIMITED_ACCESS_TO_PROVIDER_BANNER,
  });
  const isLimitedAccessToProvider: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.LIMITED_ACCESS_TO_PROVIDER_BANNER_AND_SCHEDULE
  );

  const getActiveTabContent = () => {
    switch (activeTab) {
      case ProviderTabs.ABOUT:
        return (
          <About
            areaOfExpertise={areaOfExpertise}
            biographicData={biographicData}
            culturalCompetencies={culturalCompetencies}
            educationInfo={educationInfo}
            ethnicity={ethnicity}
            gender={gender}
            hospitalAffiliations={hospitalAffiliations}
            languagesSpoken={languagesSpoken}
            licenseNumber={licenseNumber}
            npi={npi}
            primaryDegree={primaryDegrees?.[0]}
            providerName={formatProviderNameWithFirstMiddleLastName(
              providerDetails
            )}
            specialties={specialties}
            userState={address?.state}
          />
        );
      case ProviderTabs.LOCATIONS:
        return (
          <Locations
            latitude={latitude}
            locationId={locationId}
            longitude={longitude}
            providerDetails={providerDetails}
          />
        );
      case ProviderTabs.QUALITY:
        return (
          <Quality
            boardCertifications={boardCertifications}
            organizationCode={organizationCode}
            providerName={providerName}
            providerType={providerType}
            selectedLocation={selectedLocation}
          />
        );
      default:
        return <React.Fragment />;
    }
  };

  return (
    <React.Fragment>
      <CardWrapperDetailsPage>
        <DetailsWidgetHeader
          coverageType={coverageType}
          ddpFacilityCode={ddpFacilityCode}
          ddpUserCode={ddpUserCode}
          imageLocation={imageLocation}
          inNetwork={networkStatus}
          isFreeStandingFacility={isFreeStandingFacility}
          isOxPreferredLab={selectedLocation?.isOxPreferredLab}
          isPreferredLabNetwork={isPreferredLabNetwork}
          nameAttrs={nameAttrs}
          organizationType={organizationType?.[0]}
          outOfPocketCost={outOfPocketCost}
          primarySpeciality={specialties?.[0]}
          professionalDesignation={primaryDegrees?.[0]}
          providerName={providerName}
          providerType={providerType}
          rating={healthGradeRating}
          surveyCount={surveyCount}
          totalCost={totalCost}
          virtualCareOffered={virtualCareOffered}
        />
      </CardWrapperDetailsPage>
      {isLimitedAccessToProvider &&
        selectedLocation?.hospitalBasedProviderIndicator && (
          <Alert
            css={{ ...AlertStyling, ...LimitedProviderBannerStyling }}
            data-testid="limited-access-to-provider-banner"
            title={limitedAccessBanner?.title}
            variant="info"
          >
            {limitedAccessBanner?.message}
          </Alert>
        )}
      <CardWrapperDetailsPage>
        <DetailsWidgetTabs
          providerType={providerType}
          setActiveTab={setActiveTab}
        />
        {getActiveTabContent()}
      </CardWrapperDetailsPage>
      <WidgetDisclaimersWrapper>
        <DisclaimersContainer
          isViewAllDisclaimer
          isVisionCare={coverageType === 'V'}
          showPremiumCareDisclaimer={coverageType !== 'B'}
        />
      </WidgetDisclaimersWrapper>
      <CardWrapper backgroundColor="rgb(244, 246, 250)" isWidget>
        <Footer />
      </CardWrapper>
    </React.Fragment>
  );
};
