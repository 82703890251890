import { isEvergreenDate, isSixtyDaysRemaining } from '../../utils/date.utils';

export const checkTerminationDate = (
  displayTerminationDateEnabled = false,
  endDate
) =>
  displayTerminationDateEnabled &&
  endDate &&
  isSixtyDaysRemaining(endDate) &&
  !isEvergreenDate(endDate)
    ? true
    : false;
