import { storage } from '@abyss/web/tools/storage';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import {
  CareCategories,
  Constants,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { useErrorStore } from '../../store/useErrorStore';
import { ErrorStore } from '../../store/useErrorStore/errorStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { useFeatureFlag } from '../useFeatureFlag';

export const useManageBrowserHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  const [
    enableRetailnStoreValuesInSessionStorage,
    enableRemoveSearchParamsInUrl,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_RETAIN_STORE_VALUES_IN_SESSION_STORAGE,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_REMOVE_SEARCH_PARAMS_IN_URL,
  ]);

  const [historyKey, setHistoryKey] = useSessionStorage<number>(
    Constants.STORAGE_KEYS.SESSION.BROWSER_HISTORY_KEY,
    0
  );

  const [, setEnableRemoveParamsInUrl] = useSessionStorage<boolean>(
    Constants.STORAGE_KEYS.SESSION.ENABLE_REMOVE_SEARCH_PARAMS_IN_URL,
    false
  );

  const { typeaheadSearchStore, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state) => {
      const typedState = state as TypeaheadState;
      return {
        typeaheadSearchStore: typedState.typeaheadSearchStore,
        setTypeaheadSearchStore: typedState.setTypeaheadSearchStore,
      };
    })
  );

  const { pcpSearchState, setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      pcpSearchState: state.pcpSearchState,
      setPCPSearchState: state.setPCPSearchState,
    }))
  );

  const { analyticsState, setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      analyticsState: state.analyticsState,
      setAnalyticsState: state.setAnalyticsState,
    }))
  );

  const { searchStore, setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      searchStore: state.searchStore,
      setSearchStore: state.setSearchStore,
    }))
  );

  const { detailsStore, setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      detailsStore: state.detailsState,
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const { errorStore, setErrorStore } = useErrorStore(
    useShallow((state: ErrorStore) => ({
      errorStore: state.errorState,
      setErrorStore: state.setErrorStore,
    }))
  );

  const {
    chipValue,
    coverageType,
    lob,
    locale,
    openRecentActivity,
    openSpecialtyDrawer,
    openAllSpecialtyDrawer,
    setChipValue,
    setCoverageType,
    setLob,
    setLocale,
    setOpenRecentActivity,
    setOpenSpecialtyDrawer,
    setOpenAllSpecialtyDrawer,
  } = useChipStore(useShallow((state: ChipState) => state));

  const chipStore = {
    chipValue,
    coverageType,
    lob,
    locale,
    openRecentActivity,
    openSpecialtyDrawer,
    openAllSpecialtyDrawer,
  };

  const setChipStore = (chipStore) => {
    const {
      chipValue,
      coverageType,
      lob,
      locale,
      openRecentActivity,
      openSpecialtyDrawer,
      openAllSpecialtyDrawer,
    } = chipStore || {};

    if (chipValue) {
      setChipValue(chipValue);
    }
    if (coverageType) {
      setCoverageType(coverageType);
    }
    if (lob) {
      setLob(lob);
    }
    if (locale) {
      setLocale(locale);
    }
    if (openRecentActivity) {
      setOpenRecentActivity(openRecentActivity);
    }
    if (openSpecialtyDrawer) {
      setOpenSpecialtyDrawer(openSpecialtyDrawer);
    }
    if (openAllSpecialtyDrawer) {
      setOpenAllSpecialtyDrawer(openAllSpecialtyDrawer);
    }
  };

  const addParamKeyAndStoreValuesInSessionStorage = () => {
    const baseRoute = location.pathname === '/';
    const nextKey = (historyKey || 0) + 1;
    const nextValues = baseRoute
      ? {
          chipStore: {
            chipValue: CareCategories.ALL,
            coverageType: ReverseCoverageTypesCodes.MEDICAL,
          },
        }
      : {
          typeaheadSearchStore,
          pcpSearchState,
          analyticsState,
          searchStore,
          detailsStore,
          errorStore,
          chipStore,
        };
    const stringValue = `store-${nextKey}`;
    storage.session.set(stringValue, nextValues);
    setHistoryKey(nextKey);
    const updatedParams = {
      ...params,
      [Constants.BROWSER_HISTORY_PARAM_KEY]: nextKey.toString(),
    };
    setSearchParams(updatedParams, { replace: true });
  };

  const resetSessionStorage = () => {
    setHistoryKey(0);
    for (const key in sessionStorage) {
      if (key.startsWith('store-')) {
        sessionStorage.removeItem(key);
      }
    }
    navigate('/', { replace: true });
  };

  const handleLocationChange = () => {
    if (Constants.BROWSER_HISTORY_PARAM_KEY in params) {
      const stringValue = `store-${
        params[Constants.BROWSER_HISTORY_PARAM_KEY]
      }`;
      if (stringValue in sessionStorage) {
        const parsedValues = storage.session.get(stringValue);
        const {
          typeaheadSearchStore,
          pcpSearchState,
          analyticsState,
          searchStore,
          detailsStore,
          errorStore,
          chipStore,
        } = parsedValues;
        setTypeaheadSearchStore(typeaheadSearchStore);
        setPCPSearchState(pcpSearchState);
        setAnalyticsState(analyticsState);
        setSearchStore(searchStore);
        setDetailsStore(detailsStore);
        setErrorStore(errorStore);
        setChipStore(chipStore);
      } else {
        resetSessionStorage();
      }
    } else {
      addParamKeyAndStoreValuesInSessionStorage();
    }
  };

  // To be removed after cleanup
  useEffect(() => {
    if (enableRemoveSearchParamsInUrl) {
      setEnableRemoveParamsInUrl(true);
    } else {
      setEnableRemoveParamsInUrl(false);
    }
  }, []);

  useEffect(() => {
    if (enableRetailnStoreValuesInSessionStorage) {
      handleLocationChange();
    }
  }, [location]);

  const updateCurrentStoreValues = (updateValues: object, key: string) => {
    if (enableRetailnStoreValuesInSessionStorage) {
      if (Constants.BROWSER_HISTORY_PARAM_KEY in params) {
        const stringValue = `store-${
          params[Constants.BROWSER_HISTORY_PARAM_KEY]
        }`;

        if (stringValue in sessionStorage) {
          const parsedValues = storage.session.get(stringValue);
          storage.session.set(stringValue, {
            ...parsedValues,
            [key]: updateValues,
          });
        }
      }
    }
  };

  useEffect(() => {
    updateCurrentStoreValues(typeaheadSearchStore, 'typeaheadSearchStore');
  }, [JSON.stringify(typeaheadSearchStore)]);

  useEffect(() => {
    updateCurrentStoreValues(pcpSearchState, 'pcpSearchState');
  }, [JSON.stringify(pcpSearchState)]);

  useEffect(() => {
    updateCurrentStoreValues(analyticsState, 'analyticsState');
  }, [JSON.stringify(analyticsState)]);

  useEffect(() => {
    updateCurrentStoreValues(searchStore, 'searchStore');
  }, [JSON.stringify(searchStore)]);

  useEffect(() => {
    updateCurrentStoreValues(detailsStore, 'detailsStore');
  }, [JSON.stringify(detailsStore)]);

  useEffect(() => {
    updateCurrentStoreValues(errorStore, 'errorStore');
  }, [JSON.stringify(errorStore)]);

  useEffect(() => {
    updateCurrentStoreValues(chipStore, 'chipStore');
  }, [JSON.stringify(chipStore)]);
};
