import { storage } from '@abyss/web/tools/storage';
import { createTheme } from '@abyss/web/tools/theme';
import { OverlayProvider } from '@abyss/web/ui/OverlayProvider';
import { PrintProvider } from '@abyss/web/ui/PrintProvider';
import { RouterProvider } from '@abyss/web/ui/RouterProvider';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { ToastProvider } from '@abyss/web/ui/Toast';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Constants } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { CountySearchContextProvider } from '../../context/CountySearchContext';
import { SearchFilterContextProvider } from '../../context/SearchFilterContext';
import { ErrorBoundary } from '../../errors/ErrorBoundary';
import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { registerEvents } from '../../tools/events';
import { loginNotRequired, validateSession } from '../../utils/session.utils';
import { uhcThemeOverride } from '../themeOverrides/uhcThemeOverride';
import { Analytics } from './context/Analytics';
import { Configuration } from './context/Configuration';
import { DateTimeConfig } from './context/DateTimeConfig';
import { EducationalContentModal } from './context/EducationalContentModal/EducationalContentModal';
import { GlobalNav } from './context/GlobalNav';
import { Internationalization } from './context/Internationalization';
import { LanguageNotSupportedModal } from './context/LanguageNotSupportedModal/LanguageNotSupportedModal';
import { LivePerson } from './context/LivePerson';
import { Obapi } from './context/Obapi';
import { PlanSelector } from './context/PlanSelector';
import { SearchLocation } from './context/SearchLocation';
import { TimeoutModal } from './context/TimeoutModal';
import { createRouter } from './createRouter';
import { Routes } from './routes';

const theme = createTheme('uhc', uhcThemeOverride);

export const App = () => {
  const sessionId = useStore(StoreKeys.SESSION_ID);
  const setSessionId = useStore(StoreKeys.SET_SESSION_ID);
  const location = useLocation();
  const isStandAloneExp = loginNotRequired();

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const enableTimeTesting = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIMEMACHINE_TESTING
  );

  const handleValidateSession = () => {
    if (!enableTimeTesting) {
      validateSession(sessionId, setSessionId);
    }
  };

  useEffect(() => {
    // Validate on window focus
    window.addEventListener('focus', handleValidateSession);
    return () => {
      window.removeEventListener('focus', handleValidateSession);
    };
  }, []);

  useEffect(() => {
    // Validate on location change
    handleValidateSession();
  }, [location]);

  const { IS_PSX_VIEWED } = Constants.STORAGE_KEYS.LOCAL;

  useEffect(() => {
    handleValidateSession();
    storage.local.set(IS_PSX_VIEWED, true);
  }, []);

  return (
    <ErrorBoundary>
      <PlanSelector>
        <DateTimeConfig>
          <Obapi>
            <Internationalization>
              <Analytics>
                <GlobalNav>
                  <PrintProvider>
                    <OverlayProvider>
                      <ThemeProvider theme={theme}>
                        {!isStandAloneExp && <TimeoutModal />}
                        <ToastProvider
                          containerWidth="26rem"
                          position="top-right"
                        />
                        <EducationalContentModal />
                        <LanguageNotSupportedModal />
                        <LivePerson />
                        <SearchFilterContextProvider>
                          <CountySearchContextProvider>
                            <SearchLocation>
                              <Routes />
                            </SearchLocation>
                          </CountySearchContextProvider>
                        </SearchFilterContextProvider>
                      </ThemeProvider>
                    </OverlayProvider>
                  </PrintProvider>
                </GlobalNav>
              </Analytics>
            </Internationalization>
          </Obapi>
        </DateTimeConfig>
      </PlanSelector>
    </ErrorBoundary>
  );
};

const router = createRouter(App);

export const ProviderSearch = () => {
  useEffect(() => {
    registerEvents();
  }, []);

  return (
    <ErrorBoundary>
      <Configuration>
        <RouterProvider router={router} />
      </Configuration>
    </ErrorBoundary>
  );
};
