import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';

export const ChipContainer = styled('div', {
  display: 'inline-flex',
  overflow: 'auto',
  scrollBehavior: 'smooth',
  paddingRight: '40px',
  '@screen < $md': {
    gap: '8px',
  },
  '@screen >= $md': {
    gap: '12px',
  },
  scrollbarColor: 'transparent transparent' /* Hide scrollbar for Firefox */,
  /**
   * This CSS class override div styled + add pseudo class to hide the horizontal scrollbar,
   * but keet its functionality.
   * Only for this div container.
   * */
  '&.hide-scrollbar': {
    display: 'inline-flex',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '@screen < $md': {
      gap: '8px',
    },
    '@screen >= $md': {
      gap: '12px',
    },
  },
  '&.hide-scrollbar::-webkit-scrollbar': {
    display: 'contents' /* Hide scrollbar for Chrome, Safari and Opera */,
  },
});

export const Wrapper = styled(Flex, {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  '@screen < $sm': {
    marginTop: '8px',
  },
  '@screen < $md': {
    marginTop: '8px',
  },
  '@screen >= $md': {
    paddingTop: '12px',
  },
});

export const drawerHeadingStyle = {
  color: '#323334 !important',
  fontStyle: 'normal',
  fontSize: '20.25px !important',
  lineHeight: '24px !important',
};

export const ChipMenuIcon = styled('div', {
  display: 'inline-flex',
  '@screen < $md': {
    marginRight: '8px',
  },
  '@screen >= $md': {
    marginRight: '12px',
  },
});

export const CareCategoriesText = styled('h4', {
  color: '#323334 !important',
  paddingBottom: '36px',
});

export const WrapperStyle = {
  height: '52px',
  '@screen < $sm': {
    height: '43px',
  },
};

export const WrapperHeightStyle = {
  '@screen < $sm': {
    height: '43px',
  },
  '@screen < $md': {
    height: '52px',
    paddingLeft: '16px',
  },
  '@screen >= $md': {
    height: '84px',
  },
};

export const MenuList = styled('div', {
  margin: '50px 0 0 10px',
  background: '$primary2',
  border: '1px solid $primary1',
  borderRadius: '12px',
  padding: '$lg',
  position: 'absolute',
  zIndex: 99,
  minWidth: '260px',
});

export const chipSelectorDrawerMobile = {
  'abyss-modal-close-button': {
    '@screen < $md': {
      marginTop: '15px',
      marginRight: '15px',
    },
    '@screen < $sm': {
      marginTop: '8px',
      marginRight: '10px',
    },
  },
  'abyss-modal-header-container': {
    '@screen < $md': {
      padding: '$lg $lg 32px',
    },
    '@screen < $sm': {
      padding: '$md $md 28px',
    },
  },
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
    height: 'auto !important',
  },
  'abyss-modal-content-title': {
    padding: 0,
  },
  'abyss-modal-body': {
    '@screen < $md': {
      padding: '4px $lg $lg',
    },
    '@screen < $sm': {
      padding: '4px $md $md',
    },
  },
};

export const ChangeButton = styled(Button, {
  width: '100%',
  marginTop: '10px',
  borderradius: '24px',
  lineHeight: '20px',
  fontWeight: '$semibold',
  textAlign: 'center',
  '&.abyss-button-content-container': {
    margin: '0',
  },
});

export const hamburgerButton = {
  'abyss-button-root': {
    padding: '0px 0px 0px 0px',
    border: 'none',
    outline: 'none',
    '&:hover, &:focus, &:active': {
      backgroundColor: '#FBFCFE',
      border: 'none',
      outline: 'none',
    },
    '@screen < $sm': {
      paddingBottom: '7px',
    },
  },
};

export const chipsCategory = {
  color: '$primary1',
  fontWeight: '$semibold',
  lineHeight: '24px',
  fontSize: '18px',
};

export const ChipCategoryButton = styled(Button, {
  dynamic: ({ disabled }) => ({
    '&.abyss-button-root': {
      marginLeft: '5px',
      color: '$primary2',
      cursor: 'pointer',
      height: '40px',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      '&:hover, &:focus, &.active': {
        backgroundColor: !disabled && '$primary1 !important',
        color: '$primary2 !important',
      },
      '@screen < $sm': {
        height: '32px',
        padding: '6px $md',
      },
    },
  }),
});

export const ChipButtonText = styled('div', {
  dynamic: ({ isActive, disabled }) => ({
    color: isActive ? '$primary2' : disabled ? 'gray' : '$primary1',
    '&:hover, &:focus': {
      color: !disabled && '$primary2',
    },
  }),
  whiteSpace: 'nowrap',
  fontWeight: '$semibold',
  fontSize: '$md',
  lineHeight: '$md',
  '@screen < $sm': {
    fontWeight: '$bold',
    fontSize: '14px',
  },
});
