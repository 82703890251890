import { useTranslation } from 'react-i18next';
import { Constants } from '../Constants';

export const useFeedbackFormOptions = () => {
  const { t } = useTranslation();

  const infoOptions = {
    [Constants.FEEDBACK_FORM_TYPES.PROVIDER_DETAILS]: {
      label: t('FEEDBACK_FORM.PROVIDER_INFORMATION'),
      title: t('FEEDBACK_FORM.PROVIDER_INFORMATION'),
      options: [
        {
          label: t('FEEDBACK_FORM.NAME'),
          value: 'name',
        },
        {
          label: t('FEEDBACK_FORM.SPECIALTY'),
          value: 'specialty',
        },
        {
          label: t('FEEDBACK_FORM.IN_NETWORK_STATUS'),
          value: 'insurancePlanAccepted',
        },
        {
          label: t('FEEDBACK_FORM.PROVIDER_GROUP'),
          value: 'medicalGroupAffiliation',
        },
        {
          label: t('FEEDBACK_FORM.OTHER_PROVIDER_DETAILS_INCORRECT'),
          value: 'otherProviderDetails',
        },
      ],
    },
    [Constants.FEEDBACK_FORM_TYPES.FACILITY_DETAILS]: {
      label: t('FEEDBACK_FORM.FACILITY_INFORMATION'),
      title: t('FEEDBACK_FORM.FACILITY_INFORMATION'),
      options: [
        {
          label: t('FEEDBACK_FORM.NAME'),
          value: 'name',
        },
        {
          label: t('FEEDBACK_FORM.IN_NETWORK_STATUS'),
          value: 'insurancePlanAccepted',
        },
        {
          label: t('FEEDBACK_FORM.OTHER_FACILITY_DETAILS_INCORRECT'),
          value: 'otherProviderDetails',
        },
      ],
    },
    [Constants.FEEDBACK_FORM_TYPES.PROVIDER_GROUP_DETAILS]: {
      label: t('FEEDBACK_FORM.PROVIDER_GROUP_INFORMATION'),
      title: t('FEEDBACK_FORM.PROVIDER_GROUP_INFORMATION'),
      options: [
        {
          label: t('FEEDBACK_FORM.NAME'),
          value: 'name',
        },
        {
          label: t('FEEDBACK_FORM.IN_NETWORK_STATUS'),
          value: 'insurancePlanAccepted',
        },
        {
          label: t('FEEDBACK_FORM.OTHER_PROVIDER_GROUP_DETAILS_INCORRECT'),
          value: 'otherProviderDetails',
        },
      ],
    },
  };

  const locationInformationOptions = {
    label: t('FEEDBACK_FORM.LOCATION_INFORMATION'),
    title: t('FEEDBACK_FORM.LOCATION_INFORMATION'),
    options: [
      {
        label: t('FEEDBACK_FORM.ADDRESS'),
        value: 'address',
      },
      {
        label: t('FEEDBACK_FORM.PHONE_NUMBER'),
        value: 'phone',
      },
      {
        label: t('FEEDBACK_FORM.HOURS'),
        value: 'hours',
      },
      {
        label: t('FEEDBACK_FORM.WEBSITE'),
        value: 'website',
      },
      {
        label: t('FEEDBACK_FORM.PRACTICE_LIMITATIONS'),
        value: 'acceptingNewPatients',
      },
      {
        label: t('FEEDBACK_FORM.BUSINESS_STATUS'),
        value: 'closed',
      },
      {
        label: t('FEEDBACK_FORM.OTHER_LOCATION_DETAILS_INCORRECT'),
        value: 'otherLocationDetails',
      },
    ],
  };

  return { infoOptions, locationInformationOptions };
};
