import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { PSXIcon } from '../PSXIcon';
import { TieredDrawerStyles } from '../TierBenefitsCard/TierBenefitsCard.style';
import { getFeatureFlag } from '../Utils';
import {
  TierCardText,
  TierInfoBox,
  TierOneSnackCardPopoverStyles,
} from './SnackCard.styles';

type Props = {
  isTierTwo?: boolean;
  addSpaceForTierBadge?: boolean;
};

export const TierTwo = ({ isTierTwo, addSpaceForTierBadge = false }: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isTierDrawerOpen, setIsTierDrawerOpen] = useState(false);

  const data = useLagoon('ui-messaging')();
  const tier2Content = find(data, {
    key: ConstantsLagoon.UI_MESSAGING.TIER_2_POPOVER_CONTENT,
  });
  const tier2ContentTitle = tier2Content?.title;
  const tier2ContentText = tier2Content?.message;

  const featureFlags = useLagoon('feature-flags')();
  const showTier2Badge: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_TWO_PROVIDER
  );

  if (!isTierTwo || !showTier2Badge) {
    return addSpaceForTierBadge ? <div style={{ height: '24px' }} /> : null;
  }

  return (
    <div style={{ height: '24px' }}>
      <Drawer
        css={TieredDrawerStyles}
        data-auto-testid="tier-two-snackcard-drawer"
        data-testid="tier-two-snackcard-drawer"
        isOpen={isTierDrawerOpen}
        onClose={() => setIsTierDrawerOpen(false)}
        position="bottom"
        size="200"
        title={tier2ContentTitle}
      >
        {tier2ContentText}
      </Drawer>
      {mobileScreen ? (
        <TierInfoBox
          data-auto-testid="tier-two-snackcard-drawer"
          data-testid="tier-two-snackcard-drawer"
          onClick={() => setIsTierDrawerOpen(true)}
        >
          <PSXIcon icon="tier_two" isScreenReadable={true} size={16} />
          <TierCardText>{t('Tier 2 Provider')}</TierCardText>
        </TierInfoBox>
      ) : (
        <Popover
          content={tier2ContentText}
          css={TierOneSnackCardPopoverStyles}
          data-auto-testid="tier-two-snackcard-drawer-content"
          data-testid="tier-two-snackcard-drawer-content"
          position="bottom"
          title={tier2ContentTitle}
          width={214}
        >
          <TierInfoBox>
            <PSXIcon icon="tier_two" isScreenReadable={true} size={16} />
            <TierCardText>{t('Tier 2 Provider')}</TierCardText>
          </TierInfoBox>
        </Popover>
      )}
    </div>
  );
};
