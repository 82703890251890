import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { find } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { Constants } from '../../../Constants';
import { mobileOnly } from '../../../ConstantsStyles';
import {
  CollapseCardHeaderStyle,
  CollapseSubSection,
  collapseSection,
} from '../../../ProviderDetailTabs/About/About.styled';
import { CopyToClipBoard } from '../../../ProviderDetailTabs/ProviderLocationsDetails/CopyToClipBoard';

export interface ProviderListItem {
  title: string;
  value: string | string[] | JSX.Element[];
  translation?: {
    title: string;
  };
  canCopy?: boolean;
}

export type Props = {
  collapseHeader: string;
  collapseContent?: string[] | null;
  providerGroupDetail?: Array<ProviderListItem> | null;
  accordionItemValue: string;
};

export const ModalContent = styled(Markdown, {
  fontSize: '14px',
  lineHeight: '18px',
  color: '#323334',
});

export const ModalContainer = styled(Modal, {
  '.abyss-modal-content-container': {
    width: 'auto',
    height: 'auto',
    maxWidth: '572px',
    maxHeight: '797px',
  },
  '.abyss-modal-content-title': {
    lineHeight: '40px',
    fontSize: '32.43px',
    fontWeight: '600',
    '@screen < $sm': {
      lineHeight: '36px',
      fontSize: '28.83px',
      fontWeight: '600',
    },
  },
});

export const CollapseProviderDetailsSection = ({
  collapseHeader,
  collapseContent,
  providerGroupDetail,
  accordionItemValue,
}: Props) => {
  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const enableCulturalCompetency = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CULTURAL_COMPETENCY_TRAINING_SECTION
  );
  const data = useLagoon('ui-messaging')();
  const culturalCompetencyModalContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.CULTURAL_COMPETENCY_TOOLTIP_INFO_ICON,
  });
  const [isOpen, setIsOpen] = useState(false);
  const mobile = useMediaQuery(mobileOnly);
  const listProviderDetail = () => {
    const fontSize = mobile ? '$md' : '$lg';
    return (
      <CollapseSubSection>
        {providerGroupDetail?.map((item) => (
          <Flex direction="column">
            <Text
              color="$gray7"
              data-auto-testid={`${item.title
                .replace(/ /g, '-')
                .toLocaleLowerCase()}`}
              data-testid={`${item.title
                .replace(/ /g, '-')
                .toLocaleLowerCase()}`}
              fontWeight={mobileOnly ? '$semibold' : '$bold'}
              size={fontSize}
            >
              {item?.translation?.title}
            </Text>
            <Layout.Stack alignItems="left">
              {(typeof item.value === 'string' ? [item.value] : item.value).map(
                (value) => (
                  <Flex
                    css={{
                      'abyss-icon-material': {
                        cursor: 'pointer',
                      },
                      gap: '4px',
                    }}
                  >
                    <Text
                      color={item?.canCopy ? '$info1' : '$gray7'}
                      data-auto-testid={`${item.title
                        .replace(/ /g, '-')
                        .toLocaleLowerCase()}`}
                      data-testid={
                        item?.value?.[0] ===
                        t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
                          ? `${item.title
                              .replace(/ /g, '-')
                              .toLocaleLowerCase()}-not-available`
                          : `${item.title
                              .replace(/ /g, '-')
                              .toLocaleLowerCase()}-subtext`
                      }
                      fontWeight={item?.canCopy ? '$bold' : '$medium'}
                      size={fontSize}
                    >
                      {value}
                    </Text>
                    {item?.value?.[0] !==
                      Constants.PROVIDER_GROUP_DETAILS.ABOUT_TAB.DETAILS_SECTION
                        .NOT_AVAILABLE &&
                      item?.canCopy && (
                        <CopyToClipBoard
                          label={`Copy ${item.title} to clipboard`}
                          text={value}
                          title={item.title}
                        />
                      )}
                  </Flex>
                )
              )}
            </Layout.Stack>
          </Flex>
        ))}
      </CollapseSubSection>
    );
  };

  return (
    <React.Fragment>
      <Layout.Group css={collapseSection(false, false, mobile)}>
        {mobile ? (
          <Accordion
            css={CollapseCardHeaderStyle(isWidget, collapseHeader)}
            defaultValue={accordionItemValue}
            isCollapsible
          >
            <Accordion.Item value={accordionItemValue}>
              <Accordion.Trigger>
                <Layout.Group>
                  <Accordion.Header>{t(collapseHeader)}</Accordion.Header>
                  {collapseHeader === 'Cultural competency training' &&
                    enableCulturalCompetency && (
                      <Flex
                        css={{
                          width: '0px',
                        }}
                        direction="row"
                      >
                        <Flex
                          aria-label="Tooltip information"
                          css={{
                            marginRight: '20px',
                            display: 'flex',
                            cursor: 'pointer',
                          }}
                          data-testid="cultural-competency-tooltip-button"
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          <IconMaterial
                            css={{
                              'abyss-icon-material': {
                                '@screen < $sm': {
                                  marginTop: '3px',
                                },
                              },
                            }}
                            icon="info"
                            size={mobile ? 18 : 24}
                            variant="outlined"
                          />
                        </Flex>
                        <ModalContainer
                          isOpen={isOpen}
                          onClose={() => setIsOpen(false)}
                          title={culturalCompetencyModalContent?.title}
                        >
                          <Modal.Section>
                            <ModalContent>
                              {culturalCompetencyModalContent?.message}
                            </ModalContent>
                          </Modal.Section>
                        </ModalContainer>
                      </Flex>
                    )}
                </Layout.Group>
              </Accordion.Trigger>
              <Accordion.Content>
                {collapseHeader ===
                t(
                  'PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE'
                )
                  ? listProviderDetail()
                  : accordionItemValue === 'cultCompetencies' &&
                    enableCulturalCompetency
                  ? collapseContent?.map((item) => (
                      <li style={{ paddingLeft: '12px' }}>{item}</li>
                    ))
                  : collapseContent?.map((item) => item)}
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        ) : (
          <Layout.Stack alignItems="left" space={12}>
            <Layout.Group>
              <Heading
                data-auto-testid={`${collapseHeader
                  .replace(/ /g, '-')
                  .toLocaleLowerCase()}-heading`}
                data-testid={`${collapseHeader
                  .replace(/ /g, '-')
                  .toLocaleLowerCase()}-heading`}
                display="h4"
                offset={1}
              >
                {collapseHeader}
              </Heading>
              {collapseHeader === 'Cultural competency training' &&
                enableCulturalCompetency && (
                  <Flex
                    css={{
                      width: '20px',
                    }}
                    direction="row"
                  >
                    <Flex
                      aria-label="Tooltip information"
                      css={{
                        marginRight: '20px',
                        display: 'flex',
                        cursor: 'pointer',
                      }}
                      data-testid="cultural-competency-tooltip-button"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <IconMaterial
                        css={{
                          'abyss-icon-material': {
                            '@screen < $sm': {
                              marginTop: '3px',
                            },
                          },
                        }}
                        icon="info"
                        size={mobile ? 18 : 24}
                        variant="outlined"
                      />
                    </Flex>
                    <ModalContainer
                      isOpen={isOpen}
                      onClose={() => setIsOpen(false)}
                      title={culturalCompetencyModalContent?.title}
                    >
                      <Modal.Section>
                        <ModalContent>
                          {culturalCompetencyModalContent?.message}
                        </ModalContent>
                      </Modal.Section>
                    </ModalContainer>
                  </Flex>
                )}
            </Layout.Group>
            {collapseHeader ===
            t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE')
              ? listProviderDetail()
              : accordionItemValue === 'cultCompetencies' &&
                enableCulturalCompetency
              ? collapseContent?.map((item) => (
                  <li style={{ paddingLeft: '16px' }}>
                    <Text
                      color="#4B4D4F"
                      data-auto-testid="cultCompetencies-type"
                      data-testid="cultCompetencies-type"
                      fontWeight="$medium"
                      key={`expand-section-${item}`}
                      size="$lg"
                    >
                      {item}
                    </Text>
                  </li>
                ))
              : collapseContent?.map((item) => (
                  <Text
                    color="#4B4D4F"
                    data-auto-testid="specialty-type"
                    data-testid="specialty-type"
                    fontWeight="$medium"
                    key={`expand-section-${item}`}
                    size="$lg"
                  >
                    {item}
                  </Text>
                ))}
          </Layout.Stack>
        )}
      </Layout.Group>
    </React.Fragment>
  );
};
