import { FilterCollectionModel } from '../../../common/Constants';
import {
  Option,
  ProviderFiltersType,
  ProviderSearchFilters,
} from '../../../models/ProviderSearch';

const splitArrayValues = (array) => {
  const newArray: String[] = [];
  array.map((x) => {
    if (x.includes(',')) {
      const parts = x.split(',');

      parts.map((part) => {
        newArray.push(part);
      });
    } else {
      newArray.push(x);
    }
  });

  return newArray;
};

const valuePresent = (x, selectedAOEFilterArraySplit) => {
  const xValueSplit = splitArrayValues([x.value]);
  let aoeCodePresent = false;

  xValueSplit.map((value) => {
    if (selectedAOEFilterArraySplit.includes(value)) {
      aoeCodePresent = true;
    }
  });

  return aoeCodePresent;
};

export const setTranslationsForPreSelectedFilters = (
  filters: ProviderFiltersType[],
  selectedFilters: ProviderSearchFilters
): ProviderSearchFilters => {
  // For Aoe Filters, the lagoon will not have the translated content for the filters. Hence update the options with the translated filter response.
  if (
    selectedFilters[FilterCollectionModel.SPECIALTY_CODE] === undefined &&
    selectedFilters[FilterCollectionModel.AREA_OF_EXPERTISE] === undefined &&
    selectedFilters[FilterCollectionModel.GENDER_CODE] == undefined
  ) {
    return selectedFilters;
  }

  let aoeTranslatedOptions: Option[] = [];
  let specialtyCodeTranslatedOptions: Option[] = [];
  let genderCodeTranslatedOptions: Option[] = [];

  const translatedAOEFilters = filters.find(
    (x) => x.model === FilterCollectionModel.AREA_OF_EXPERTISE
  );
  const translatedSpecialtyCodeFilters = filters.find(
    (x) => x.model === FilterCollectionModel.SPECIALTY_CODE
  );

  const translatedGenderCodeFilters = filters.find(
    (x) => x.model === FilterCollectionModel.GENDER_CODE
  );

  if (translatedAOEFilters) {
    const selectedAOEFilterSet = new Set(
      selectedFilters[FilterCollectionModel.AREA_OF_EXPERTISE]?.map((x) =>
        String(x.value)
      ) || []
    );

    const selectedAOEFilterArray = Array.from(selectedAOEFilterSet);
    const selectedAOEFilterArraySplit = splitArrayValues(
      selectedAOEFilterArray
    );

    aoeTranslatedOptions =
      translatedAOEFilters?.options
        ?.filter((x: Option) => valuePresent(x, selectedAOEFilterArraySplit))
        .map((x) => ({ ...x })) || [];
  }

  if (translatedSpecialtyCodeFilters) {
    const selectedSpecialtyCodeFilterSet = new Set(
      selectedFilters[FilterCollectionModel.SPECIALTY_CODE]?.map((x) =>
        String(x.value)
      ) || []
    );

    const selectedSpecialtyCodeFilterArray = Array.from(
      selectedSpecialtyCodeFilterSet
    );
    const selectedSpecialtyCodeFilterArraySplit = splitArrayValues(
      selectedSpecialtyCodeFilterArray
    );

    specialtyCodeTranslatedOptions =
      translatedSpecialtyCodeFilters?.options
        ?.filter((x: Option) =>
          valuePresent(x, selectedSpecialtyCodeFilterArraySplit)
        )
        .map((x) => ({ ...x })) || [];
  }

  if (translatedGenderCodeFilters) {
    const selectedGenderCodeFilterSet = new Set(
      selectedFilters[FilterCollectionModel.GENDER_CODE]?.map((x) =>
        String(x.value)
      ) || []
    );

    const selectedGenderCodeFilterArray = Array.from(
      selectedGenderCodeFilterSet
    );
    const selectedGenderCodeFilterArraySplit = splitArrayValues(
      selectedGenderCodeFilterArray
    );

    genderCodeTranslatedOptions =
      translatedGenderCodeFilters?.options
        ?.filter((x: Option) =>
          valuePresent(x, selectedGenderCodeFilterArraySplit)
        )
        .map((x) => ({ ...x })) || [];
  }

  return {
    ...selectedFilters,
    AreaOfExpertise: aoeTranslatedOptions,
    SpecialtyCode: specialtyCodeTranslatedOptions,
    GenderCode: genderCodeTranslatedOptions,
  };
};
