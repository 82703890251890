import { Icon } from '@abyss/web/ui/Icon';
import React from 'react';

export const PrintPreviewTierTwoIcon = () => (
  <Icon size="13px">
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 204976976">
        <g id="Group 204976974">
          <circle
            cx="8.0026"
            cy="7.99967"
            fill="black"
            id="Ellipse 807"
            r="6.66667"
          />
          <path
            d="M6.296 4.0115V4.4297H5.6058V6.2793H5.1128V4.4297H4.4294V4.0115H6.296ZM7.04466 4.0115V6.2793H6.55166V4.0115H7.04466ZM9.18459 4.0115V4.4297H7.97759V4.9159H9.08599V5.3069H7.97759V5.8611H9.20839V6.2793H7.48459V4.0115H9.18459ZM9.56311 4.0115H10.6579C10.8993 4.0115 11.0319 4.0387 11.1577 4.1135C11.3481 4.2257 11.4535 4.4195 11.4535 4.6541C11.4535 4.9159 11.3209 5.1233 11.0931 5.2083C11.3447 5.2865 11.4161 5.4361 11.4501 5.9597C11.4637 6.1195 11.4841 6.2011 11.5181 6.2521L11.5385 6.2793H11.0217C10.9911 6.2215 10.9775 6.1535 10.9639 5.9733C10.9401 5.4973 10.8551 5.3885 10.5015 5.3885H10.0561V6.2793H9.56311V4.0115ZM10.0561 4.4025V5.0247H10.5287C10.6919 5.0247 10.7837 5.0077 10.8483 4.9601C10.9265 4.9057 10.9673 4.8207 10.9673 4.7119C10.9673 4.4977 10.8381 4.4025 10.5491 4.4025H10.0561Z"
            fill="white"
            id="TIER"
          />
          <path
            d="M9.73956 11.8535V12.7207H6.12393C6.19742 11.6404 6.43258 11.3391 8.05668 10.2662C8.53435 9.93549 8.70338 9.69298 8.70338 9.32554C8.70338 8.89931 8.41677 8.6127 7.99789 8.6127C7.47612 8.6127 7.21891 8.96545 7.19686 9.69298H6.23417V9.58275C6.23417 8.45838 6.93231 7.74554 8.03463 7.74554C9.02672 7.74554 9.71751 8.36284 9.71751 9.2594C9.71751 9.67094 9.58524 10.0237 9.30598 10.3176C9.12961 10.5161 9.10021 10.5307 8.44617 10.979C7.79947 11.4053 7.54961 11.6184 7.38058 11.8903C7.71863 11.8682 7.90235 11.8609 8.2257 11.8609L9.73956 11.8535Z"
            fill="white"
            id="2"
          />
        </g>
      </g>
    </svg>
  </Icon>
);
