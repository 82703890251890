export const optumThemeOverride = {
  theme: {
    colors: {
      pageBodyBgColor: '$white',
      neutralGray3: '#F3F3F3',
      neutralGray2: '#F2F2F2',
      neutralGray5: '#6E7072',
      cardBorder: '$neutralGray3',
      lightBlue: '#D9F6FA',
      bgHeaderColor: '$lightBlue',
      bgSubHeaderColor: '$lightBlue',
      bgProviderDetailsColor: '#FBF9F4',
      icColor: '#196ECF',
      tcolor: '#002677',
      tabsContainerBackgroundColor: '#DAF6FA',
      tabButtonBackgroundColor: '#FFFFFF',
      tabSelectionTextColor: '#002677',
      whereToGetHelpCardBg1: '$lightBlue',
      whereToGetHelpCardBg2: '$lightBlue',
      whereToGetHelpCardBg3: '$lightBlue',
      milesTextColor: '$interactive1',
      tabBottomBorderColor: '$neutralGray3',
      headerCardWrapperBorderColor: '$lightBlue',
      virtualVisitIconColor: '$interactive1',
      searchBorderColor: '$gray5',
      recentVisitCardSliderBg: '$lightBlue',
      memeberInitialsBgColor: '#FFFFFF',
      memeberInitialsTextColor: '#424242',
      mapViewContainerBgColor: '$white',
    },
    fontSizes: {
      dateInfoText: '16px',
    },
    lineHeights: {},
    space: {
      wrapperPadding: '24px 0',
      headerWrapperPadding: '24px 0 0 0',
      tabButtonsPadding: '32px 0 0 0',
      recentSearchBottomGap: 0,
      recentActivityTopPadding: '0px',
      popoverContainerTop: '0px',
    },
    fontWeights: {
      normalFW: 'normal',
      boldFW: 'bold',
      memeberInitialsFW: '700',
    },
    borderWidths: {
      recentActivityBorder: '0px',
    },
    sizes: {
      iconBrandSize: '64px',
      dataCardForMapIconSize: '42px',
    },
  },
};
