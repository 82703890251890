import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Print } from '@abyss/web/ui/Print';
import isEmpty from 'lodash/isEmpty';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Constants } from '../../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../../common/ConstantsLagoon';
import { RatingText } from '../../../../../../common/DataCard/DataCard.style';
import { getFeatureFlag } from '../../../../../../common/Utils';
import { getTierProviderEligibility } from '../../../../../../common/Utils/memberUtils/memberUtils';
import { PortalContext } from '../../../../../../context/PortalContext';
import { useCoverageType } from '../../../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../../../models/Provider';
import { useChipStore } from '../../../../../../store/useChipStore';
import { ChipState } from '../../../../../../store/useChipStore/chipStore';
import {
  formatProviderStartDate,
  isProviderStartDateInFuture,
} from '../../../../../../utils/date.utils';
import {
  returnSuppressFlag,
  suppressCostEstimatesForPolicies,
} from '../../../../../../utils/featureSuppress';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../../utils/providerNames.utils';
import { getShowCost } from '../../../../../../utils/showCost.utils';
import { getCurrentMember } from '../../../../../../utils/user.utils';
import { isVirtualVisitOnlyAddress } from '../../../../../../utils/virtualVisitOnly';
import { isDistanceAttributeEmpty } from '../../CompareDrawer/Components/CompareCard/CompareExpandedCard/Attributes/Location/Distance';
import {
  ATTRIBUTE_STATUS_TEXT,
  CompareCheckboxInfo,
  NULL_ATTRIBUTE_STATE,
} from '../../CompareDrawer/utility/compareDrawerConstants';
import { getStatusText } from '../../ShareDrawer/utils';
import { PrintEducationAttribute } from './PrintEducationAttribute';
import {
  PrintViewDivider,
  PrintViewProviderDetailsXSText,
} from './PrintExpandedCard.styled';
import { PrintHoursOfOperation } from './PrintHoursOfOperation';
import { PrintPcpServiceAvailability } from './PrintPcpServiceAvailability';
import { PrintPracticeLimitations } from './PrintPracticeLimitations';
import { PrintPremiumCareProviderText } from './PrintPremiumCareProviderText';
import { PrintServicesOffered } from './PrintServicesOffered';
import { PrintVirtualCareOfferedAttribute } from './PrintVirtualCareOfferedAttribute';

type Props = {
  item: CompareProvider;
  compareCheckboxes: CompareCheckboxInfo[];
};

export const PrintExpandedCard = ({ item, compareCheckboxes }: Props) => {
  const { t } = useTranslation();
  const formattedLanguage = item?.languagesSpoken?.filter(Boolean).join(', ');

  const languages = !formattedLanguage
    ? NULL_ATTRIBUTE_STATE.LANGUAGES_SPOKEN
    : formattedLanguage;

  const isRatingAvailable = !Number.isNaN(
    parseFloat(item.healthGradeRating?.toString())
  );
  const rating = Math.trunc(item.healthGradeRating);
  const { portalData } = useContext(PortalContext);
  const portalShowCost = getShowCost(portalData);

  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );
  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );
  const isVirtualVisitOnlyAddressLine = (
    addressLine: string,
    suppressVirtualCareQuickCare: boolean
  ) =>
    suppressVirtualCareQuickCare &&
    isVirtualVisitOnlyAddress(chipValue, addressLine);
  const shouldShowVirtualVisitAddress = isVirtualVisitOnlyAddressLine(
    item?.address?.line[0],
    isVirtualCareQuickCareSuppressed
  );

  const ratingToPrint = isRatingAvailable
    ? Number(item.healthGradeRating).toFixed(1)
    : item.healthGradeRating || NULL_ATTRIBUTE_STATE.HEALTH_GRADE_RATINGS;

  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);

  const coverageType = useCoverageType();

  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    coverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );
  const featureFlags = useLagoon('feature-flags')();
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );

  const displayInNetworkBadge = isProviderStartDateInFuture(
    item?.networkStartDate
  );

  const isSpecialityPresent: boolean = !isEmpty(item.speciality);
  const providerSpecialty: string = isSpecialityPresent
    ? item.speciality
    : t(NULL_ATTRIBUTE_STATE.SPECIALTY);

  const isAddressPresent =
    !isEmpty(item.address) && item.address.line[0] !== '';

  const formatAddress = (add) => `${add.line[0]},
    ${add.city},
    ${add.state}`;

  const displayedAddressText = isAddressPresent
    ? formatAddress(item.address)
    : t(NULL_ATTRIBUTE_STATE.ADDRESS);

  const displayedDistance = isDistanceAttributeEmpty(item.distance)
    ? NULL_ATTRIBUTE_STATE.DISTANCE
    : `${parseFloat(item.distance).toFixed(1)} ${t('miles')}`;

  const acceptingNewPatientsText = getStatusText(
    item.acceptingNewPatients,
    ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.TRUE,
    ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.FALSE,
    NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
  );

  const isGenderEmpty = isEmpty(item.gender);

  const providerGender: string = !isGenderEmpty
    ? item.gender.charAt(0).toUpperCase().concat(item.gender.slice(1))
    : NULL_ATTRIBUTE_STATE.GENDER;

  const currentMember = getCurrentMember();
  const showTier2Badge: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_TWO_PROVIDER
  );
  const isTier2Plan = currentMember?.isIFPTieredPlan && showTier2Badge;

  const tieredProvider = item?.isTieredProvider;

  const isDisplayTier1Identifier = getTierProviderEligibility(
    currentMember,
    tieredProvider
  );

  const tierOneProviderText = getStatusText(
    isDisplayTier1Identifier,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.TRUE,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.FALSE,
    NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
  );

  const tierText = getStatusText(
    tieredProvider,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.TRUE,
    getStatusText(
      item?.isTier2Provider,
      ATTRIBUTE_STATUS_TEXT.TIER_TWO_PROVIDER.TRUE,
      ATTRIBUTE_STATUS_TEXT.TIER_TWO_PROVIDER.FALSE,
      ATTRIBUTE_STATUS_TEXT.TIER_TWO_PROVIDER.FALSE
    ),
    NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
  );

  const eveningAppointmentsText = getStatusText(
    item?.hasEveningAppointments,
    ATTRIBUTE_STATUS_TEXT.OFFERS_EVENING_APPOINTMENTS.TRUE,
    ATTRIBUTE_STATUS_TEXT.OFFERS_EVENING_APPOINTMENTS.FALSE,
    NULL_ATTRIBUTE_STATE.OFFERS_EVENING_APPOINTMENTS
  );

  const weekendAppointmentsText = getStatusText(
    item?.hasWeekendAppointment,
    ATTRIBUTE_STATUS_TEXT.OFFERS_WEEKEND_APPOINTMENTS.TRUE,
    ATTRIBUTE_STATUS_TEXT.OFFERS_WEEKEND_APPOINTMENTS.FALSE,
    NULL_ATTRIBUTE_STATE.OFFERS_WEEKEND_APPOINTMENTS
  );

  const getCost = (cost: string | undefined) => {
    switch (cost) {
      case '$$$':
        return `$$$ - ${t('COST.ABOVE_AVERAGE')}`;
      case '$$':
        return `$$ - ${t('COST.AVERAGE')}`;
      case '$':
        return `$ - ${t('COST.BELOW_AVERAGE')}`;
      default:
        return `${t('COST.INFO_NOT_AVAILABLE')}`;
    }
  };

  return (
    <React.Fragment key={item.locationId}>
      <Flex
        css={{
          '&.abyss-flex-root': {
            width: '20%',
          },
        }}
        direction="column"
      >
        <div
          style={{
            padding: '10px',
            margin: '4px',
            width: '100%',
          }}
        >
          <Print.Value>
            {parseProviderName(
              item?.providerName,
              item?.providerType,
              item?.primaryDegrees?.[0],
              getNameAttrs(item)
            )}
          </Print.Value>
          {compareCheckboxes?.find((record) => record.name === 'specialties')
            ?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {providerSpecialty}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}
          {compareCheckboxes?.find((record) => record.name === 'inNetwork') && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <Layout.Group direction="row">
                {displayInNetworkBadge && displayInNetworkBadgeEnabled ? (
                  <PrintViewProviderDetailsXSText>
                    {`${t(
                      'DISPLAY_IN_NETWORK_BADGE.IN_NETWORK_BADGE_PCP'
                    )} ${formatProviderStartDate(item?.networkStartDate)}`}
                  </PrintViewProviderDetailsXSText>
                ) : (
                  <React.Fragment>
                    <IconMaterial
                      color={item?.networkStatus ? '$success1' : ''}
                      icon="check_circle"
                      size="12px"
                    />
                    <PrintViewProviderDetailsXSText>
                      {item?.networkStatus
                        ? t('In-network')
                        : t('Not-In-Network')}
                    </PrintViewProviderDetailsXSText>
                  </React.Fragment>
                )}
              </Layout.Group>
            </React.Fragment>
          )}

          {compareCheckboxes?.find((record) => record.name === 'reviews')
            ?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <Layout.Group space="">
                {Constants.RATE_LIMITING.map((param) => (
                  <span key={param}>
                    {isRatingAvailable && (
                      <IconMaterial
                        className="star-icon"
                        css={{
                          'abyss-icon': {
                            fill: param < rating ? '$secondary2' : '$black',
                            stroke: param < rating ? '$starBorder' : '',
                          },
                        }}
                        icon={param < rating ? 'star_rate' : 'star_border'}
                        size="12px"
                      />
                    )}
                  </span>
                ))}

                {isRatingAvailable ? (
                  <RatingText className="text" color="$primary1" size="12.64px">
                    {ratingToPrint}
                  </RatingText>
                ) : (
                  <PrintViewProviderDetailsXSText>
                    {ratingToPrint}
                  </PrintViewProviderDetailsXSText>
                )}
              </Layout.Group>
            </React.Fragment>
          )}

          {compareCheckboxes?.find((record) => record.name === 'distance')
            ?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {!shouldShowVirtualVisitAddress && displayedAddressText}
              </PrintViewProviderDetailsXSText>
              <br />
              <PrintViewProviderDetailsXSText>
                {!shouldShowVirtualVisitAddress && displayedDistance}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {compareCheckboxes?.find(
            (record) => record.name === 'languagesSpoken'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {languages !== NULL_ATTRIBUTE_STATE.LANGUAGES_SPOKEN
                  ? languages
                  : t(languages)}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {compareCheckboxes?.find(
            (record) => record.name === 'acceptingNewPatients'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {acceptingNewPatientsText}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {compareCheckboxes?.find((record) => record.name === 'gender')
            ?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {providerGender}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {compareCheckboxes?.find((record) => record.name === 'cost')
            ?.isChecked &&
            portalShowCost &&
            costEnableFlag &&
            !suppressCostEstimates && (
              <React.Fragment>
                <PrintViewDivider orientation="horizontal" />
                <PrintViewProviderDetailsXSText>
                  {getCost(item.costIndicator)}
                </PrintViewProviderDetailsXSText>
              </React.Fragment>
            )}

          {/* HOURS OF OPERATION */}
          {compareCheckboxes.find(
            (record) => record.name === 'hoursOfOperation'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintHoursOfOperation selectedProvider={item} />
            </React.Fragment>
          )}
          {compareCheckboxes?.find(
            (record) => record.name === 'tierOneProvider'
          )?.isChecked &&
            !isTier2Plan && (
              <React.Fragment>
                <PrintViewDivider orientation="horizontal" />
                <PrintViewProviderDetailsXSText>
                  {tierOneProviderText}
                </PrintViewProviderDetailsXSText>
              </React.Fragment>
            )}
          {compareCheckboxes.find(
            (record) => record.name === 'tierOneAndTwoProvider'
          )?.isChecked &&
            isTier2Plan && (
              <React.Fragment>
                <PrintViewDivider orientation="horizontal" />
                <PrintViewProviderDetailsXSText>
                  {tierText}
                </PrintViewProviderDetailsXSText>
              </React.Fragment>
            )}

          {/* EDUCATION */}
          {compareCheckboxes.find((record) => record.name === 'education')
            ?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintEducationAttribute educationInfo={item.educationInfo} />
            </React.Fragment>
          )}

          {/* VIRTUAL CARE OFFERED */}
          {compareCheckboxes.find(
            (record) => record.name === 'virtualCareOffered'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintVirtualCareOfferedAttribute
                virtualCareOffered={item.virtualCareOffered}
              />
            </React.Fragment>
          )}

          {/* PREMIUM CARE PROVIDER */}
          {compareCheckboxes.find(
            (record) => record.name === 'premiumCareProvider'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintPremiumCareProviderText
                premiumCareProvider={item.premiumCareProvider}
              />
            </React.Fragment>
          )}

          {/* PCP SERVICES */}
          {compareCheckboxes.find(
            (record) => record.name === 'hasPCPServiceAvailability'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintPcpServiceAvailability
                pcpServicesAvaliable={item.hasPCPServiceAvailability}
              />
            </React.Fragment>
          )}
          {/* Provider Ethnicity */}
          {compareCheckboxes.find(
            (record) => record.name === 'providerEthnicityExpress'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {item?.providerEthnicityExpress ??
                  t(NULL_ATTRIBUTE_STATE.PROVIDER_ETHNICITY)}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {/* Express Access Provider */}
          {compareCheckboxes.find(
            (record) => record.name === 'expressAccessProvider'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {item?.accessProvider
                  ? t(
                      'CONTENT_FILTERS.ALL_FILTERS_DRAWER.EXPRESS_ACCESS_PROVIDER'
                    )
                  : t(NULL_ATTRIBUTE_STATE.EXPRESS_ACCESS_PROVIDER)}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {compareCheckboxes.find((record) => record.name === 'eapAccepted')
            ?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {item?.isEap
                  ? t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.EAP_ACCEPTED')
                  : '--'}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {/* Offers evening appointments */}
          {compareCheckboxes.find(
            (record) => record.name === 'hasEveningAppointments'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {eveningAppointmentsText}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {/* Offers weekend appointments */}
          {compareCheckboxes.find(
            (record) => record.name === 'hasWeekendAppointment'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintViewProviderDetailsXSText>
                {weekendAppointmentsText}
              </PrintViewProviderDetailsXSText>
            </React.Fragment>
          )}

          {/* Practice Limitations */}
          {compareCheckboxes.find(
            (record) => record.name === 'practiceLimitations'
          )?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintPracticeLimitations
                practiceLimitations={item.practiceLimitations}
              />
            </React.Fragment>
          )}

          {/* Serives Offered */}
          {compareCheckboxes.find((record) => record.name === 'servicesOffered')
            ?.isChecked && (
            <React.Fragment>
              <PrintViewDivider orientation="horizontal" />
              <PrintServicesOffered servicesOffered={item.servicesOffered} />
            </React.Fragment>
          )}
        </div>
      </Flex>
    </React.Fragment>
  );
};
