import { styled } from '@abyss/web/tools/styled';

export const TitleStyle = styled('span', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

export const HeadingStyle = styled('span', {
  color: '#4B4D4F',
  fontSize: '18px',
  fontWeight: 'bold',
  marginRight: '4px',
  whiteSpace: 'nowrap',
});

export const SubTitleStyle = styled('span', {
  color: '#196ECF',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '18px !important',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
});

export const textStyle = {
  marginTop: '20px',
  display: 'flex',
  alignItems: 'center',
};

export const titleTextStyle = {
  fontSize: '22.78px',
  color: '#4B4D4F',
  '@screen < $md': {
    fontSize: '20.25px !important',
  },
};
