import { config } from '@abyss/web/tools/config';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { useEffect, useState } from 'react';

import { useGeoLocationStorage } from '../../hooks/useGeoLocationStorage';
import { useLagoon } from '../../hooks/useLagoon';
import { getUiMessageFromLagoon } from '../../utils/common';
import { getCurrentMember } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { cardHeaderContainerStyle } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { OnlineSpecialtyGroupCard } from './OnlineSpecialtyGroupsCard';

type OnlineSpecialtyGroupProps = {
  'data-testid'?: string;
};

export const OnlineSpecialtyGroup = ({
  'data-testid': testId = 'online-specialty-group',
}: OnlineSpecialtyGroupProps) => {
  const marketPlaceContent = useLagoon(Constants.LAGOON_TABLE.MARKET_PLACE)();
  const currentMember = getCurrentMember();

  const marketPlaceHeading = getUiMessageFromLagoon(
    ConstantsLagoon.UI_MESSAGING.MARKET_PLACE_HEADING
  );

  const marketPlaceDescription = getUiMessageFromLagoon(
    ConstantsLagoon.UI_MESSAGING.MARKET_PLACE_DESCRIPTION
  );

  const containerForAllSectionStyle = {
    height: 'auto',
  };
  const [marketPlaceObj, setMarketPlaceObj] = useState([]);
  const geoLocation = useGeoLocationStorage();
  const { stateCode } = geoLocation;

  const getImageUrl = (imageName: string) =>
    `${config('CDN_BASE_URL')}/cdn/assets/images/${imageName}.png`;

  const BEND_PNG = getImageUrl('bend');
  const AFFECT_PNG = getImageUrl('affect');
  const BRIGHTSIDE_PNG = getImageUrl('brightside');
  const CHARLIE_PNG = getImageUrl('charlie');
  const EQUIP_PNG = getImageUrl('equip');
  const RIA_HEALTH_PNG = getImageUrl('ria_health');
  const TALKIATRY = getImageUrl('talkiatry');

  const iconUrls = {
    Bend: BEND_PNG,
    Equip: EQUIP_PNG,
    'Ria Health': RIA_HEALTH_PNG,
    Brightside: BRIGHTSIDE_PNG,
    Charlie: CHARLIE_PNG,
    'Affect Therapeutics': AFFECT_PNG,
    Talkiatry: TALKIATRY,
  };

  useEffect(() => {
    if (marketPlaceContent) {
      const updatedMarketPlaceObjArray = marketPlaceContent.map((item) => ({
        ...item,
        iconUrl: iconUrls[item.dntTitle] || '',
      }));

      const filteredMarketPlaceContent = updatedMarketPlaceObjArray?.filter(
        (item) => {
          const licensedStates = item.licensedStates
            ? item.licensedStates.split(',').map((state) => state.trim())
            : [];

          const suppressedPolicyIds = item.suppressedPolicyIds
            ? item.suppressedPolicyIds.split(',').map((id) => id.trim())
            : [];

          return (
            item.isActive &&
            !suppressedPolicyIds.includes(currentMember.policyNumber) &&
            (licensedStates.length === 0 || licensedStates.includes(stateCode))
          );
        }
      );
      setMarketPlaceObj(filteredMarketPlaceContent);
    }
  }, [stateCode]);

  return marketPlaceObj.length ? (
    <ContainerForAllSectionsStyled css={containerForAllSectionStyle}>
      <ContentWrapper>
        <Flex
          alignItems="flex-end"
          css={cardHeaderContainerStyle}
          justify="start"
        >
          <Heading
            data-auto-testid={testId}
            data-testid={testId}
            display="h4"
            offset={1}
          >
            {marketPlaceHeading}
          </Heading>
          <Text
            color="$gray7"
            css={{
              'abyss-text-root': {
                fontSize: '$md',
                paddingTop: '$xs',
                marginBottom: '40px',
              },
            }}
            data-auto-testid="online-specialty-group-description"
            data-testid="online-specialty-group-description"
          >
            {marketPlaceDescription}
          </Text>
        </Flex>
        <OnlineSpecialtyGroupCard
          data-auto-testid="where-to-get-help-card"
          data-testid="where-to-get-help-card"
          marketPlaceObj={marketPlaceObj}
        />
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  ) : null;
};
