import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Markdown } from '@abyss/web/ui/Markdown';
import { find } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { AlertStyling } from '../../../../common/DetailsPageHeader/DetailsPageHeader.styled';
import { useLagoon } from '../../../../hooks/useLagoon';
import { EmpirePolicyAlertStyling } from './FacilityDetails.styled';

export const FacilityBanner = () => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isVisible, setIsVisible] = useState(true);
  const [isEmpirePolicyDrawerOpen, setIsEmpirePolicyDrawerOpen] =
    useState(false);
  const uiMessaging = useLagoon('ui-messaging')();
  const empirePlicyBannerContent = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.ANTHEM_BLUE_CROSS_DISCLAIMER,
  });
  const empirePolicyDisclaimerPopupContent = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.ANTHEM_BLUE_CROSS_DISCLAIMER_POPUP,
  });

  const StyledDrawer = styled(Drawer, {
    zIndex: '999 !important',
    '.abyss-modal-header-container': {
      width: 'fit-content',
    },
    '.abyss-modal-content-container': {
      borderRadius: 0,
      width: '408px',
      '@screen < $sm': {
        height: '70vh !important',
        width: '100%',
        borderRadius: '20px 20px 0px 0px',
        border: '1px solid $primary1',
      },
    },
    '.abyss-modal-close-button': {
      '@screen < $sm': {
        top: '12px',
        right: '12px',
      },
    },
    '.abyss-modal-body': {
      padding: '8px 16px 8px 16px',
    },
  });
  const buttonStyles = {
    margin: '$sm $lg $sm $md',
    float: 'right',
  };

  const renderEmpirePolicyCoverageDetailsDrawer = () => (
    <>
      {isEmpirePolicyDrawerOpen ? (
        <StyledDrawer
          data-testid="coverage-details-drawer"
          isOpen={isEmpirePolicyDrawerOpen}
          onClose={() => setIsEmpirePolicyDrawerOpen(false)}
          position={mobileScreen ? 'bottom' : 'right'}
          size={mobileScreen && 'fit-content'}
          title={empirePolicyDisclaimerPopupContent?.title}
        >
          <Markdown data-testid="coverage-details-drawer-message">
            {empirePolicyDisclaimerPopupContent?.message}
          </Markdown>
          <Button
            css={{ 'abyss-button-root': { ...buttonStyles } }}
            data-auto-testid="close-empire-policy-drawer-button"
            data-testid="close-empire-policy-drawer-button"
            onClick={() => setIsEmpirePolicyDrawerOpen(false)}
            size="$md"
          >
            {t(
              'ANTHEM_BLUE_CROSS_DISCLAIMER.ANTHEM_BLUE_CROSS_DISCLAIMER_POPUP.CLOSE_BUTTON'
            )}
          </Button>
        </StyledDrawer>
      ) : null}
    </>
  );

  const renderAlertContent = () => (
    <>
      {renderEmpirePolicyCoverageDetailsDrawer()}
      <Markdown>{empirePlicyBannerContent?.message}</Markdown>
      <Link
        css={!mobileScreen && { marginLeft: '10px' }}
        data-auto-testid="view-coverage-details"
        data-testid="view-coverage-details"
        isStandardAnchor
        onClick={() => {
          setIsEmpirePolicyDrawerOpen(true);
        }}
        size="$md"
      >
        {t('ANTHEM_BLUE_CROSS_DISCLAIMER.LINK_TEXT')}
      </Link>
    </>
  );
  return (
    <>
      <Alert
        css={!mobileScreen && EmpirePolicyAlertStyling}
        data-testid="anthem-blue-cross-banner"
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(!isVisible);
        }}
        title={empirePlicyBannerContent?.title}
        variant="info"
      >
        <Layout.Stack>
          {mobileScreen ? (
            renderAlertContent()
          ) : (
            <Layout.Group
              css={{
                'abyss-layout-group': {
                  '& > div > div': {
                    lineHeight: '1px',
                    alignSelf: 'center',
                  },
                },
              }}
            >
              {renderAlertContent()}
            </Layout.Group>
          )}
        </Layout.Stack>
      </Alert>
    </>
  );
};
