import { Tooltip } from '@abyss/web/ui/Tooltip';

import { Address as ProviderAddress } from '../../models/ProviderDetails';
import {
  AddressText,
  AddressWrapper,
  Dot,
  addressTextToolTipStyles,
  popupAddressTextToolTipStyles,
} from './DataCard.style';

type Props = {
  address?: ProviderAddress;
  id?: string;
  popupContainer?: boolean;
  sectionType?: string;
  isCTADisplayLogic?: boolean;
};

export const Address = ({
  popupContainer,
  address,
  id,
  sectionType,
  isCTADisplayLogic = false,
}: Props) => {
  const { line } = address || {};
  const addressLine = line?.join(', ');
  if (!line) return null;
  return (
    <AddressWrapper>
      {!isCTADisplayLogic && !popupContainer && <Dot />}
      <Tooltip
        content={addressLine}
        css={
          popupContainer
            ? popupAddressTextToolTipStyles
            : addressTextToolTipStyles
        }
        position="top"
        positionOffset={8}
      >
        <AddressText
          color={popupContainer ? '$gray7' : isCTADisplayLogic ? '$interactive1' : '$gray7'}
          css={{ marginTop: '0px !important', fontSize: popupContainer ? '$sm' : '', padding: popupContainer ? '0px 10px' : '' }}
          data-auto-testid={`data-card-address-${sectionType}-${id}`}
          data-testid={`data-card-address-${sectionType}-${id}`}
          fontWeight={popupContainer ? '$small' : isCTADisplayLogic ? '$bold' : '$small'}
        >
          {addressLine}
        </AddressText>
      </Tooltip>
      {isCTADisplayLogic && !popupContainer && <Dot />}
    </AddressWrapper>
  );
};
