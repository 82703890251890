import { Flex } from '@abyss/web/ui/Flex';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import { PortalContext } from '../../../../context/PortalContext';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  ProviderFiltersType,
  ProviderSearchFilters,
} from '../../../../models/ProviderSearch';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { validateMnrTieredBenefitEligibility } from '../../../../utils/benefit.utils';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { portalShouldShowVirtualVisits } from '../../../../utils/portalConfig.utils';
import {
  getCurrentMember,
  isMNRMember,
  isNotMNRMember,
} from '../../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  EAP_LABEL,
  EAP_LABEL_ES,
  EAP_OPTION_LABEL,
  FilterCollectionModel,
  ReverseCoverageTypesCodes,
} from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { isCnsMember } from '../../../Utils/memberUtils/memberUtils';

const { PROVIDER, PROVIDER_GROUPS, FACILITY } = Constants.RESULT_SECTION;
const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
const { MEDICAL, VISION } = ReverseCoverageTypesCodes;

const eapPresent = (selectedFilters) =>
  (selectedFilters?.AreaOfExpertise || []).findIndex(
    (option) => option.dnt_label === EAP_OPTION_LABEL
  ) !== -1;

const getEAPOptionDetails = (providerFilters) => {
  const filter = providerFilters.find(
    (filter) => filter.model === FilterCollectionModel.AREA_OF_EXPERTISE
  );
  return filter?.options?.find(
    (option) => option.dnt_label === EAP_OPTION_LABEL
  );
};

interface Props {
  sectionType: string | undefined;
  selectedFilters: Omit<
    ProviderSearchFilters,
    'PageNumber' | 'AutoSearchRadius'
  >;
  setSelectedFilters: (data: ProviderSearchFilters) => void;
  selectedFiltersContainerHeight: number;
  providerFilters?: ProviderFiltersType[];
}

export const AllFilterToggleSwitchGroup = ({
  sectionType,
  selectedFilters,
  setSelectedFilters,
  selectedFiltersContainerHeight,
  providerFilters = [],
}: Props) => {
  const { t } = useTranslation();

  const tierBenefits = useLagoon('tier-benefit')();

  const [tierOneFilterCompareFeatureFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.TIER_ONE_FILTER_COMPARE,
  ]);
  const suppressPremiumCare: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const isTier1Plan = useStore(StoreKeys.TIER1_PLAN_FLAG);

  const coverageType = useCoverageType();
  const currentMember = getCurrentMember();
  const { lineOfBusiness, population, isIFPTieredPlan } = currentMember || {};
  const isCns = isCnsMember(lineOfBusiness);
  const notMNRMember = isNotMNRMember(population);
  const { portalData } = useContext(PortalContext);
  const portalLockEAPfilter = portalData?.portalConfig?.lockEapFilter;
  const { providerType = '' } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      providerType: state.typeaheadSearchStore.providerType,
    }))
  );

  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );

  const isVirtualCareUnetRailroadSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_RAILROAD
  );

  const suppressVirtualCare =
    isVirtualCareQuickCareSuppressed ||
    (coverageType !== ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
      isVirtualCareUnetRailroadSuppressed);
  const showTier1FilterToggle = () => {
    let showTier1Filter = false;
    if (isCns) {
      return false;
    }
    // for non-MNR members, if TIER_ONE_FILTER_COMPARE is ON, suppress Tier1 identifier based on 'isTier1Plan' field from snackCard api response
    // and for MNR members, suppress Tier1 identifier based on plan configuration in lagoon
    if (isMNRMember(lineOfBusiness)) {
      showTier1Filter = validateMnrTieredBenefitEligibility(
        currentMember,
        tierBenefits,
        providerType
      );
    } else {
      showTier1Filter = isIFPTieredPlan ? false : !!isTier1Plan;
    }
    // If the feature_flag TIER_ONE_FILTER_COMPARE is ON, then suppress Tier1
    // identifier based on 'isTier1Plan' field from snackCard api response
    return tierOneFilterCompareFeatureFlag ? showTier1Filter : true;
  };

  const [enableExpressAccessProviderFilter] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EXPRESS_ACCESS_PROVIDER_FILTER,
  ]);
  const [enableEmployeeAssistanceProgramAcceptedFilter] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS
      .ENABLE_EMPLOYEE_ASSISTANCE_PROGRAM_ACCEPTED_FILTER,
  ]);
  const isVirtualCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_EMPIRE
  );

  const portalShowVirtualVisits = portalShouldShowVirtualVisits();

  const filters = [
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.EXPRESS_ACCESS_PROVIDER'),
      allFilterDrawerOrder: 5,
      model: FilterCollectionModel.EXPRESS_ACCESS_PROVIDER_INDICATOR,
      sections: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER
          .EXPRESS_ACCESS_PROVIDER,
      show: enableExpressAccessProviderFilter,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.EAP_OFFERED'),
      allFilterDrawerOrder: 5,
      model: FilterCollectionModel.AREA_OF_EXPERTISE,
      sections: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]:
          notMNRMember && getEAPOptionDetails(providerFilters)
            ? [PROVIDER, PROVIDER_GROUPS]
            : [],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.EAP_OFFERED,
      show: enableEmployeeAssistanceProgramAcceptedFilter,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.TIER_INDICATOR'),
      model: FilterCollectionModel.TIERED_PROVIDER,
      allFilterDrawerOrder: 1,
      sections: {
        [MEDICAL]: [PROVIDER, FACILITY, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.TIER_INDICATOR,
      show: showTier1FilterToggle(),
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.PREMIUM_CARE_PHYSICIAN'),
      model: FilterCollectionModel.PREMIUM_CARE_PHYSICIAN,
      allFilterDrawerOrder: 2,
      sections: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER
          .PREMIUM_CARE_PHYSICIAN,
      show: !suppressPremiumCare,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.PLATINUM_PROVIDER'),
      model: FilterCollectionModel.PLATINUM_PROVIDER_INDICATOR,
      allFilterDrawerOrder: 4,
      sections: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.PLATINUM_PROVIDER,
      show: !isCns,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE'),
      model: FilterCollectionModel.VIRTUAL_CARE,
      allFilterDrawerOrder: 3,
      sections: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE,
      show:
        !isVirtualCareSuppressed &&
        portalShowVirtualVisits &&
        !suppressVirtualCare,
    },
  ];

  const toggleFilters = filters.filter(
    ({ sections, show = true }) =>
      sections[coverageType]?.includes(sectionType) && show
  );

  // Virtual Care filter selected fropm chipFilter will be an array.
  const resolveVirtualCareToggleStatus = (
    model: FilterCollectionModel.VIRTUAL_CARE
  ) =>
    Array.isArray(selectedFilters[model])
      ? selectedFilters[model]?.[0]?.value || false
      : selectedFilters[model]?.value || false;

  let toggleStatus = {};
  let toggleDisabledStatus = {};

  toggleFilters.forEach((x) => {
    let toggleOn: boolean;
    let toggleDisabled: boolean = false;
    if (
      enableEmployeeAssistanceProgramAcceptedFilter &&
      (x.label.includes(EAP_LABEL) || x.label.includes(EAP_LABEL_ES))
    ) {
      toggleOn = eapPresent(selectedFilters) ? true : false;
      toggleDisabled = portalLockEAPfilter;
    } else if (x.model === FilterCollectionModel.VIRTUAL_CARE) {
      toggleOn = resolveVirtualCareToggleStatus(x.model);
    } else {
      toggleOn = selectedFilters[x.model]?.value || false;
    }
    toggleStatus = { ...toggleStatus, [x.label]: toggleOn };
    toggleDisabledStatus = {
      ...toggleDisabledStatus,
      [x.label]: toggleDisabled,
    };
  });

  const onToggleChange = (e, model, dnt_label, label) => {
    if (toggleDisabledStatus[label]) {
      return;
    }
    adobeLinkTrackEvent({
      name: `${dnt_label}:${e.target.checked ? 'check' : 'uncheck'}`,
      location: `modal:all filters drawer`,
      type: 'checkbox',
    });

    if (
      enableEmployeeAssistanceProgramAcceptedFilter &&
      model === FilterCollectionModel.AREA_OF_EXPERTISE
    ) {
      const EAPOption = getEAPOptionDetails(providerFilters);

      const updatedValue = e.target.checked
        ? [...(selectedFilters?.AreaOfExpertise || []), EAPOption]
        : selectedFilters.AreaOfExpertise?.filter(
            (option) => option.dnt_label !== EAP_OPTION_LABEL
          );
      setSelectedFilters({
        ...selectedFilters,
        [model]: updatedValue,
      });
    } else if (model === FilterCollectionModel.VIRTUAL_CARE) {
        setSelectedFilters({
          ...selectedFilters,
          [model]: {
            label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.OFFERS_VIRTUAL_CARE'),
            value: e.target.checked,
            dnt_label,
          },
        });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [model]: {
          label,
          value: e.target.checked,
          dnt_label,
        },
      });
    }

    if (toggleStatus.hasOwnProperty(label)) {
      toggleStatus[label] = !toggleStatus[label];
    }
  };

  toggleFilters.sort((a, b) => a.allFilterDrawerOrder - b.allFilterDrawerOrder);

  return (
    /**
     * This logic is to push down the container in a dynamic way trigger by selected filters current height,
     * It will set marginTop to push container down.
     * */
    <div
      style={{
        marginTop: `${selectedFiltersContainerHeight - 12}px`,
      }}
    >
      {toggleFilters.length ? (
        <Flex
          css={{
            '&.abyss-flex-root': {
              width: '100%',
              display: 'flex',
              gap: '24px',
              flexDirection: 'column',
              padding: '$lg',
              borderBottom: '1px solid $gray4',
            },
            '.abyss-toggle-switch-root': {
              margin: 0,
            },
            '.abyss-toggle-switch-label': {
              lineHeight: '20px',
            },
          }}
        >
          {toggleFilters.map(({ model, label, dnt_label }) => (
            <ToggleSwitch
              data-auto-testid="switch"
              data-testid={`${model}_test`}
              isChecked={toggleStatus[label]}
              isDisabled={toggleDisabledStatus[label]}
              key={label}
              label={label}
              onChange={(e) => onToggleChange(e, model, dnt_label, label)}
              size="$sm"
            />
          ))}
        </Flex>
      ) : null}
    </div>
  );
};
