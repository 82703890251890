import { useRouter } from '@abyss/web/hooks/useRouter';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CommonlySearchedCard } from '../../../../common/CommonlySearched';
import { Constants } from '../../../../common/Constants';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import { getSearchLocation } from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useFilterProviderResults } from '../../../../hooks/useProviderSearch';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useSearchStore } from '../../../../store/useSearchStore';
import { SearchState } from '../../../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { NoSpecialtyResult } from './NoSpecialtyResult';
import { NullPageContainerStyled } from './NullSpecialtyPage.styles';

export type NullResultsPageProps = {
  searchTitle: string;
};

export const NullResultPageForSpecialty = () => {
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'null specialty results',
    sitesectionLevel1: `${Constants.ADOBE_TRACKING.VIEWALL_SITESECTION1}`,
    sitesectionLevel2: `${Constants.ADOBE_TRACKING.VIEWALL_SITESECTION2}`,
  });
  const { longitude, latitude } = useGeoLocationStorage();
  const [newLatitude, setNewLatitude] = useState(latitude);
  const [newLongitude, setNewLongitude] = useState(longitude);
  const { navigate } = useRouter();

  const { search } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
    }))
  );
  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );
  const { searchRadius } = useSearchStore(
    useShallow((state: SearchState) => ({
      searchRadius: state.searchStore.searchRadius,
    }))
  );
  const { totalResultsCount } = useFilterProviderResults({
    shouldGetHGData: true,
    selectedFilters: {
      searchRadius,
    },
  });

  /**  DE920761 & DE967656 fix: When location changed on null results page, latitute and longitude changes, old latitude and longitude differs from new one.
 so, if totalResultsCount also changes, then navigating back to Mapview page where it came from, already API call is happening when location is changed.*/
  useEffect(() => {
    if (newLatitude !== latitude || newLongitude !== longitude) {
      setNewLatitude(newLatitude);
      setNewLongitude(newLongitude);
      if (totalResultsCount > 0) {
        navigate(-1);
      }
    }
  }, [latitude, longitude, totalResultsCount]);
  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const { t } = useTranslation();
  const storedLocation = getGeoLocationFromStorage();

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: ``,
    },
  ];

  useEffect(() => {
    adobeStandardSearchEvent({
      linkName,
      numberOfResults: 0,
      method: 'guided',
      customAttributesBlock: {
        searchLocation: getSearchLocation(storedLocation),
      },
    });
  }, []);
  return (
    <React.Fragment>
      <PSXHeader
        breadcrumbs={breadcrumbs}
        dataTestId="no-specialty-page"
        showChips={false}
        showChoosePCPHeader={false}
        showMemberSelection={false}
      />
      <NullPageContainerStyled>
        <NoSpecialtyResult
          data-auto-testid="null-specialty-page"
          data-testid="null-specialty-page"
          searchName={search}
        />
        <CommonlySearchedCard />
        <StillNeedHelp />
      </NullPageContainerStyled>
    </React.Fragment>
  );
};
