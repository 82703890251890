import { Constants } from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { returnSuppressFlag } from './featureSuppress';

export const shouldSuppressVirtualVisitOnlyOption = (chipValue: string) => {
  const isSpecialistOrBehavioralHealth: boolean =
    chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS ||
    chipValue === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH;
  const isVirtualCareQuickCareSuppressed: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );
  return isSpecialistOrBehavioralHealth && isVirtualCareQuickCareSuppressed;
};

export const isVirtualVisitOnlyAddress = (
  chipValue: string,
  addressLine: string
) => {
  const virtualVisitPrefix: string = Constants.VIRTUAL_VISIT;
  const isSpecialistOrBehavioralHealth: boolean =
    chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS ||
    chipValue === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH;
  return (
    isSpecialistOrBehavioralHealth &&
    addressLine?.toLowerCase().startsWith(virtualVisitPrefix.toLowerCase())
  );
};
