import { SHA256, enc } from 'crypto-js';
import { isNil } from 'lodash';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import toUpper from 'lodash/toUpper';

import { FeatureFlags } from '../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import {
  displayPremiumCareIdentifier,
  displayTier1Identifier,
  displayTier2Identifier,
} from '../../../common/DataCard/DataCardUtils';
import { getGeoLocationFromStorage } from '../../../common/PSXHeader/SearchBar/utils';
import { isOutOfCounty } from '../../../common/SnackCardContainer/utils';
import {
  isVirtualCareAvailableInLocation,
  isVirtualCareIconFeatureEnabledOnDetailsHeader,
  showVirtualIndicatorForCards,
} from '../../../utils/indicator.utils';
import {
  UserDDPCode,
  displayPreferredFacilityOnCards,
  getPremiumCarePhysician,
  isShowPreferredFacilityBadge,
} from '../../../utils/providerDetails.utils';
import { getCurrentMember } from '../../../utils/user.utils';
import { adobeImpressionTrackEvent } from '../../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../../AdobeTagging/adobeModalTrackEvent';
import { adobeSearchResultClickEvent } from '../../AdobeTagging/adobeSearchResultClickEvent';
import {
  Constants,
  ProviderTypes,
  TypeaheadProviderTypes,
} from '../../Constants';
import { getFeatureFlag } from '../getFeatureFlag';
import { getTierProviderEligibility } from '../memberUtils/memberUtils';

export type CustomAttributesBlock = {
  correlationId: string;
  providerId: string;
  providerType: string;
};

export type AdobeTaggingSuppressionFlags = {
  suppressFacilityFlags: {
    suppressPreferredFacility: boolean;
    suppressPreferredFacilityPLN: boolean;
    suppressPreferredFacilityDDP: boolean;
    suppressPreferredFacilityFSF: boolean;
  };
  memberHasRPK: boolean;
  suppressPremiumCare: boolean;
  ddpCodeUser?: string;
  isCountySearch: boolean;
};

export const handleLinkAndModalTrack = (
  linkName: string,
  linkLocation: string,
  modalName: string,
  linkType?: string,
  customAttributesBlock?: CustomAttributesBlock
) => {
  adobeLinkTrackEvent({
    name: linkName,
    location: linkLocation,
    type: linkType || 'internal',
    customAttributesBlock,
  });
  adobeModalTrackEvent(modalName);
};

export function getUrlPathForAnalytics() {
  const urlPath = window?.location?.pathname?.split('/');
  return urlPath?.slice(0, -1)?.join('/') || '';
}

export const getFormattedFilters = (selectedFilters) => {
  if (isEmpty(selectedFilters)) return '';

  const keys = Object.keys(selectedFilters);
  const formattedFilters = keys?.map((key) => {
    const filterValues = selectedFilters[key];
    if (Array.isArray(filterValues) && filterValues?.length) {
      return `${key?.toLowerCase()}:${filterValues?.map((val) =>
        val?.dnt_label?.toLowerCase()
      )}`;
    }
    if (isObject(filterValues)) {
      return filterValues?.dnt_label === undefined
        ? ''
        : `${key?.toLowerCase()}:${filterValues?.dnt_label?.toLowerCase()}`;
    }
    return '';
  });
  return formattedFilters
    ? formattedFilters
        .join('|')
        .replace(/^\|+|\|+$/g, '')
        .replaceAll('||', '|')
    : '';
};

export const handleAdobeSearchResultClickAndModalEvent = (input) => {
  adobeSearchResultClickEvent({
    filters: input.filters,
    linkName: input.linkName,
    linkPosition: input.linkPosition,
    linkLocation: input.linkLocation,
    customAttributesBlock: input.customAttributesBlock,
    term: input.term,
  });
  adobeModalTrackEvent(input.modalName);
};

export function internalConvertProviderTypeToAdobeType(internalProviderType) {
  if (
    internalProviderType?.toLowerCase() ===
    ProviderTypes?.PRACTITIONER.toLowerCase()
  )
    return 'provider';
  if (
    internalProviderType?.toLowerCase() ===
      ProviderTypes?.ORGANIZATION.toLowerCase() ||
    internalProviderType?.toLowerCase() ===
      ProviderTypes?.FACILITY.toLowerCase() ||
    internalProviderType?.toLowerCase() ===
      TypeaheadProviderTypes?.MEDICAL_HEALTH_SUPPLIES.toLowerCase()
  )
    return 'facilities';
  if (
    internalProviderType?.toLowerCase() ===
      ProviderTypes.MEDICAL_GROUP.toLowerCase() ||
    internalProviderType?.toLowerCase() ===
      ProviderTypes?.MEDICALGROUP.toLowerCase() ||
    internalProviderType?.toLowerCase() === ProviderTypes?.GROUP.toLowerCase()
  )
    return 'medical group';

  return undefined;
}

export function convertProviderTypeToAdobeType(internalProviderType) {
  const name = internalConvertProviderTypeToAdobeType(internalProviderType);
  return name || internalProviderType?.toLowerCase();
}

export function getDisplayedProviderCardsCount(count, results) {
  const maxResultsToDisplay =
    Constants.RESULT_SECTION.MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN;
  if (results > maxResultsToDisplay) {
    return count + maxResultsToDisplay;
  }
  if (results > 0 && results <= maxResultsToDisplay) {
    return count + results;
  }
  // return the displayed result count in mixedResultCards screen for analytics tracking
  return count;
}

export const convertSpecialtiesList = (details: string[]): string => {
  let specialtyList = '';
  const specialties = details;
  for (let i = 0; i < specialties?.length; i++) {
    specialtyList += specialties[i].toLowerCase().replaceAll('-', ' ');
    if (i !== specialties?.length - 1) {
      specialtyList += '|';
    }
  }
  return specialtyList;
};

export const getNameForLinkTrack = (
  searchName: string | undefined,
  providerType: string | undefined
) => {
  const linkName = internalConvertProviderTypeToAdobeType(providerType);
  return linkName ? `${linkName} name` : searchName;
};

export const getTypeAheadCategory = (providerType: ProviderTypes) =>
  providerType ? 'providers' : 'specialty categories';

export const convertCoverageType = (
  coverageType: string | undefined
): string => {
  switch (coverageType) {
    case 'B':
      return 'behavioral';
    case 'D':
      return 'dental';
    case 'V':
      return 'vision';
    default:
      return 'medical';
  }
};

// data-auto-testid & page name MAP for navigation history comparison
export const testIdAndPageNameMap = {
  'care-options-heading': 'all',
  'primary-care-container': 'primary care',
  'browse-common-specialities-section': 'medical specialists',
  'browse-services-section-main-heading': 'behavioral health',
  'browse-health-supplies-section': 'health supplies',
  'browse-facilities-heading': 'facilities',
  'result-section-popover-icon': 'mixed results',
  'viewall-container': 'viewall',
  'provider-details-container': 'provider details',
  'choose-pcp-location-container': 'choose pcp location',
  'choose-pcp-success-container': 'choose pcp success',
};

export const pageRanksForBrowserBackButton = [
  { pageName: 'all', rank: 1 },
  { pageName: 'mixed results', rank: 2 },
  { pageName: 'viewall', rank: 3 },
  { pageName: 'primary care', rank: 2 },
  { pageName: 'medical specialists', rank: 2 },
  { pageName: 'behavioral health', rank: 2 },
  { pageName: 'health supplies', rank: 2 },
  { pageName: 'facilities', rank: 2 },
  { pageName: 'vision', rank: 2 },
  { pageName: 'provider details', rank: 4 },
  { pageName: 'choose pcp location', rank: 3 },
  { pageName: 'choose pcp success', rank: 4 },
];

export const formatProviderId = (provider) => {
  const {
    tin = '',
    providerId = '',
    locationId = '',
    npi = '',
    coverageType = '',
  } = provider;

  const formattedNpi = npi || 'NoNPI';

  const defaultTin = tin || locationId.split('-')[1];

  const formattedTin = !Number.isNaN(+defaultTin)
    ? SHA256(defaultTin).toString(enc.Hex)
    : 'NoTin';

  const addressId = locationId.split('-')[3];

  return `${providerId}:${formattedNpi}:${formattedTin}:${addressId}:${coverageType}`;
};

export const appendCoverageTypes = (provider, tokenData) => ({
  ...provider,
  coverageType: tokenData?.coverageType,
});

export const convertTypeaheadProviderIdAndType = (typeaheadData) => {
  if (!typeaheadData.providerType) {
    const slicedRollupCodes =
      typeaheadData.vertical?.split(',').slice(0, 10).join() || undefined;
    return {
      providerId: `${typeaheadData.suggestion}:${slicedRollupCodes}:${typeaheadData?.coverageType}`,
      providerType: 'speciality category',
    };
  }
  return {
    providerId: formatProviderId(typeaheadData),
    providerType: internalConvertProviderTypeToAdobeType(
      typeaheadData.providerType
    ),
  };
};

export const locationSearchImpressionTrackEvent = (
  term: string,
  noOfResults: number
) => {
  adobeImpressionTrackEvent({
    searchTerm: term,
    type: 'location search',
    message:
      noOfResults > 0
        ? 'results found for your search'
        : 'no results found for your search',
  });
};

export const getSearchMethod = (tokenData) =>
  tokenData?.searchMethod || 'guided';

export const getSearchTerm = (tokenData) =>
  getSearchMethod(tokenData) === 'guided'
    ? ''
    : tokenData?.searchTerm || tokenData?.search;

export const getSearchBlock = (typeaheadState, linkName) => ({
  term: getSearchTerm(typeaheadState)?.toLowerCase() ?? '',
  type: 'provider',
  linkName: linkName?.toLowerCase().replaceAll('-', ' ') ?? '',
  method: getSearchMethod(typeaheadState)?.toLowerCase() ?? '',
});

const setLocationIndicator = (
  locationId: any,
  indicators: Set<string>,
  providerName: any
) => {
  if (
    locationId &&
    locationId.endsWith('NoTin-T-NoAddress') &&
    !indicators.has(
      `${Constants.ADOBE_TRACKING.VIRTUAL_CARE_NATIONAL_PROVIDER}${providerName}`
    )
  ) {
    indicators.add(
      `${Constants.ADOBE_TRACKING.VIRTUAL_CARE_NATIONAL_PROVIDER}${providerName}`
    );
  }
};

const setVirtualCareIndicator = (
  providerType: any,
  virtualCareOffered: any,
  featureFlags: { key: string; active: boolean }[],
  indicators: Set<string>
) => {
  if (
    showVirtualIndicatorForCards(
      providerType,
      { virtualCareOffered },
      featureFlags
    ) &&
    !indicators.has(
      Constants.ADOBE_TRACKING.VIRTUAL_CARE_OFFERED_LOCAL_PROVIDER
    )
  ) {
    indicators.add(
      Constants.ADOBE_TRACKING.VIRTUAL_CARE_OFFERED_LOCAL_PROVIDER
    );
  }
};

const setCostIndicator = (
  enableCost: boolean,
  costIndicator: any,
  skipCostIndicator: boolean,
  indicators: Set<string>
) => {
  if (
    enableCost &&
    costIndicator &&
    skipCostIndicator !== true &&
    !indicators.has(Constants.ADOBE_TRACKING.COST_ESTIMATE_DISPLAYED)
  ) {
    indicators.add(Constants.ADOBE_TRACKING.COST_ESTIMATE_DISPLAYED);
  }
};

export const getIndicatorsForImpressions = (
  allProviders: any,
  featureFlags: { key: string; active: boolean }[],
  skipCostIndicator: boolean = false,
  adobeTaggingSupressionFlags: AdobeTaggingSuppressionFlags = {} as any,
  currentMember: any
) => {
  const indicators = new Set<string>([]);
  const { name, stateCode } = getGeoLocationFromStorage();

  const isOutsideCounties = allProviders.map((provider) =>
    isOutOfCounty(
      adobeTaggingSupressionFlags.isCountySearch,
      name,
      stateCode,
      provider
    )
  );
  const enableCost: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED
  );
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const preferredFacilityFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PREFERRED_FACILITY_IDENTIFICATION
  );

  if (
    isOutsideCounties.includes(true) &&
    indicators.add(Constants.ADOBE_TRACKING.OUT_OF_COUNTY)
  )
    indicators.add(Constants.ADOBE_TRACKING.OUT_OF_COUNTY);

  allProviders.forEach(
    ({
      locationId,
      providerName,
      virtualCareOffered,
      costIndicator,
      providerType,
      isPremiumCarePhysician,
      isTieredProvider,
      isTier2Provider,
      ddpCode,
      isPreferredLab,
      isFreeStandingFacility,
    }) => {
      setLocationIndicator(locationId, indicators, providerName);

      setVirtualCareIndicator(
        providerType,
        virtualCareOffered,
        featureFlags,
        indicators
      );

      setCostIndicator(
        enableCost,
        costIndicator,
        skipCostIndicator,
        indicators
      );

      const tieredProvider = displayTier1Identifier(isTieredProvider);
      const isDisplayTier1Identifier = getTierProviderEligibility(
        currentMember,
        tieredProvider
      );

      const isDisplayTier2Identifier =
        currentMember?.isIFPTieredPlan &&
        displayTier2Identifier(isTier2Provider);

      const showPremiumCare = displayPremiumCareIdentifier(
        !adobeTaggingSupressionFlags.suppressPremiumCare,
        getPremiumCarePhysician(isPremiumCarePhysician),
        isDisplayTier1Identifier,
        isDisplayTier2Identifier,
        adobeTaggingSupressionFlags.memberHasRPK,
        false
      );

      if (showPremiumCare) {
        indicators.add(Constants.ADOBE_TRACKING.PREMIUM_CARE_PROVIDER);
      }

      const showBenefitsBadge = isShowPreferredFacilityBadge(
        adobeTaggingSupressionFlags.suppressFacilityFlags,
        preferredFacilityFlag,
        ddpCode,
        (adobeTaggingSupressionFlags.ddpCodeUser as UserDDPCode) || undefined,
        isPreferredLab,
        undefined,
        undefined,
        isFreeStandingFacility,
        undefined
      );

      const showTier1Identifier =
        showTierProviderTag && isDisplayTier1Identifier;

      if (
        displayPreferredFacilityOnCards(
          showPremiumCare,
          showBenefitsBadge,
          showTier1Identifier,
          isDisplayTier2Identifier
        )
      ) {
        indicators.add(Constants.ADOBE_TRACKING.PREFERRED_FACILITY);
      }
    }
  );
  return Array.from(indicators).join('|').toLocaleLowerCase();
};

export const getIndicatorsForProviderDetailsAboutTabsImpressions = (
  currentLocation,
  featureFlags: { key: string; active: boolean }[],
  coverageType: string | undefined
) => {
  const indicators: string[] = [];
  const enableCost: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED
  );

  if (
    isVirtualCareIconFeatureEnabledOnDetailsHeader(
      featureFlags,
      coverageType
    ) &&
    isVirtualCareAvailableInLocation(currentLocation)
  ) {
    indicators.push(
      Constants.ADOBE_TRACKING.VIRTUAL_CARE_OFFERED_LOCAL_PROVIDER
    );
  }

  if (enableCost && currentLocation?.costIndicator) {
    indicators.push(Constants.ADOBE_TRACKING.COST_ESTIMATE_DISPLAYED);
  }

  return indicators?.join('|').toLocaleLowerCase();
};

export const getSearchLocation = (location) => {
  // We cannot use full address, so follow this logic.
  //
  // - return zipCode if it exists
  // - if no zipCode and location is an address return stateCode
  // - all other cases return the name.
  if (location?.zipCode) {
    return location.zipCode;
  } else {
    if (
      includes(toUpper(location?.id), toUpper(Constants.LOCATION_TYPE_ADDRESS))
    ) {
      return location?.stateCode;
    }

    return location?.name;
  }
};

export const getTier1Indicator = (
  showTierProviderTag: boolean,
  providers: any
) => {
  const isTier1Provider = displayTier1Identifier(
    (providers || []).some(
      (x) => !isNil(x.isTieredProvider) && x.isTieredProvider
    )
  );
  const isTieredIndicatorShown =
    showTierProviderTag &&
    getTierProviderEligibility(getCurrentMember(), isTier1Provider);

  return isTieredIndicatorShown ? Constants.ADOBE_TRACKING.TIER_ONE : '';
};
