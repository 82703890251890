import {
  GuestMemberInfo,
  GuestPortalMemberInfo,
} from './GuestPortalMemberInfo';
import {
  Coverage,
  CoverageEffDateRange,
  Eligibility,
  MemberCoverage,
  TypeCode,
} from './PortalMemberInfo';

const coverageEffDateRange: CoverageEffDateRange = {
  startDate: '',
  endDate: '2099-12-31',
};

const typeCode: TypeCode = {
  desc: 'MEDICAL',
  coverageTypeCode: 'M',
};

const coverageType: Coverage = {
  coverageEffDateRange,
  planVariationCode: '0001',
  typeCode,
  reciprocityId: '52',
};

const memberHealthCoverage: MemberCoverage = {
  coverageType: [coverageType],
};

const eligibility: Eligibility = {
  memberHealthCoverage,
};

const guestMemberInfo: GuestMemberInfo = {
  eligibility: [eligibility],
  lineOfBusiness: 'E&I',
  policyNumber: '0168504',
};

export const mockMedicalGuestPortalMemberInfo: GuestPortalMemberInfo = {
  memberInfo: [guestMemberInfo],
};
