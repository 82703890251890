import { LOB, PopulationType } from '../../../common/Constants';
import { MemberInfo } from '../../PSXHeader/ObapiDemo/memberProfile';
import { dateFormatUTC } from '../datesUtils/formatDate';

interface BigN {
  FirstName: string;
  LastName: string;
  DOB: string;
  MemberNumber: string;
  GroupNumber: string;
  portalSource: string;
  userName: string;
  id: string;
}

export const isMnrMember = (lob) => lob === LOB.MNR;
export const isEniMember = (lob) => lob === LOB.ENI;
export const isMnrCosmosMember = (lob, population) =>
  lob === LOB.MNR && population === PopulationType.COSMOS;
export const isCnsMember = (lob) => lob === LOB.CNS;
export const isIfpMember = (population: PopulationType | undefined) =>
  population === PopulationType.IFP;

export const findLoggedInMember = (
  profiles: MemberInfo[],
  bigN: BigN
): number => {
  if (
    !profiles ||
    !bigN ||
    !bigN.FirstName ||
    !bigN.LastName ||
    bigN.MemberNumber?.length === 0 ||
    isMnrMember(profiles?.[0]?.lineOfBusiness)
  ) {
    return 0;
  }

  const index = profiles.findIndex((profile) => {
    const { firstName, lastName } = profile.demographics?.name;
    const { dateOfBirth } = profile.demographics;
    return (
      firstName.toUpperCase() === bigN.FirstName.toUpperCase() &&
      lastName.toUpperCase() === bigN.LastName.toUpperCase() &&
      dateOfBirth === dateFormatUTC(bigN.DOB, 'yyyy-MM-dd')
    );
  });
  return index === -1 ? 0 : index;
};

export const getTierProviderEligibility = (
  currentMember,
  isTieredProvider
): boolean =>
  currentMember?.isIFPMember
    ? currentMember?.isIFPTieredPlan && isTieredProvider
    : isTieredProvider;
