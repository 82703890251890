export const removeAccents = (str) =>
  str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const removeLeadingZeroes = (str?: string): string =>
  str?.replace(/^0+/, '') || '';

export const trimSpaces = (str?: string): string => str?.trim() || '';

export const toCapitalCase = (str: string): string =>
  str?.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
