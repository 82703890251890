import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const CardContainer = styled(Card, {
  boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
  flex: 'none',
  gap: '8px',
  borderRadius: '12px',
  border: '1px solid $neutralGray2',
  marginTop: '0 !important',
  width: '408px',
  height: '230px',
  '@screen < $sm': {
    height: '210px !important',
  },
  '&.active': {
    border: '2px solid $tint3',
  },
  '&.abyss-card-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  '.account-icon': {
    width: '48px',
    height: '48px',
  },
  '.abyss-heading-root': {
    fontSize: '$lg',
    fontWeight: '$bold',
    lineHeight: '$lg',
    paddingLeft: '$sm',
    '@screen < $md': {
      fontSize: '$sm',
    },
  },
  '.text': {
    fontSize: '$sm',
    lineHeight: '$md',
  },
  '.arrow_forward': {
    width: '12px',
  },
  '.check_circle, .star-icon ': {
    height: '13.33px',
    width: '13.33px',
  },
  '@screen < $md': {
    '.location-icon': {
      width: '20px',
    },
    '.bottom-section': {
      padding: '$sm $sm',
      '.abyss-text-root': {
        fontSize: '$xs',
      },
    },
    '.text': {
      fontSize: '$extraSmallText',
      fontWeight: '$medium',
    },
  },
});

export const DataContainer = styled('div', {
  display: 'grid',
  width: '100%',
  fontSize: '0px',
  border: 'none !important',
  flexDirection: 'column',
  height: 'auto !important',
  '@screen < $sm': {
    width: '345px',
  },
});

export const SkeletonContainerDesktop = styled('div', {
  background: 'white',
  boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
  borderRadius: '12px',
  border: '1px solid $neutralGray2',
  width: '408px',
});

export const SkeletonContainerMobile = styled('div', {
  background: 'white',
  boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
  borderRadius: '12px',
  border: '1px solid $neutralGray2',
  minHeight: '176px',
  minWidth: '320px',
});

export const NetworkWrapper = styled(Flex, {
  marginTop: '0px',
  marginRight: '2px',
  gap: '5px',
  alignItems: 'center',
  '@screen < $sm': {
    marginTop: '2px',
  },
});

export const AvatarDataCard = styled('div', {
  width: '48px',
  height: '48px',
  minWidth: '48px',
  minHeight: '48px',
  borderRadius: '50%',
  overflow: 'hidden',
});

export const VoiceOnlyText = styled('p', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  userSelect: 'none',
  width: '1px',
});
export const RatingTextWrapper = styled('div', {
  display: 'inline-flex',
  position: 'relative',
});

export const BrandIconWrapper = styled(Flex, {
  paddingLeft: '$md',
});

export const DataCardAvatarContainer = styled('div', {
  position: 'relative',
});

export const providerNameToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '240px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $md': {
      maxWidth: '200px',
    },
    '@screen < $sm': {
      maxWidth: '185px',
    },
  },
};
export const specialityNameToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '155px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $sm': {
      maxWidth: '122px',
      fontSize: '$extraSmallText',
      lineHeight: '16px',
      paddingTop: '2px',
    },
    '@media (max-width: 390px)': {
      maxWidth: '100px',
    },
  },
};

export const popupSpecialityNameToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '$sm',
    textWrap: 'nowrap',
    fontWeight: '400',
    '@screen < $sm': {
      maxWidth: '122px',
      fontSize: '$extraSmallText',
      lineHeight: '16px',
      paddingTop: '2px',
    },
    '@media (max-width: 390px)': {
      maxWidth: '100px',
    },
  },
};

export const addressTextToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $sm': {
      height: '20px',
      fontSize: '$smallText',
      maxWidth: '175px',
    },
    '@media (max-width: 390px)': {
      maxWidth: '140px',
    },
  },
};

export const popupAddressTextToolTipStyles = {
  'abyss-tooltip-trigger': {
    padding: '4px 0px 4px 0px',
    fontSize: '$sm',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $sm': {
      height: '20px',
      fontSize: '$smallText',
      maxWidth: '150px',
    },
    '@media (max-width: 390px)': {
      maxWidth: '140px',
    },
  },
};

export const virtualVistsToolTipStyles = {
  'abyss-tooltip-trigger': {
    maxWidth: '175px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $sm': {
      fontSize: '$extraSmallText',
      lineHeight: '16px',
      maxWidth: '95px',
    },
  },
};

export const providerNameDataCardTextStyles = {
  '&.abyss-text-root': {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $md': {
      maxWidth: '200px',
    },
  },
};

export const popupContainerDataCardTextStyles = {
  '&.abyss-text-root': {
    paddingTop: '8px',
    marginTop: '8px',
    marginbottom: '-8px',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@screen < $md': {
      maxWidth: '151px',
    },
  },
};

export const displayVirtualVisitStyle = {
  'abyss-flex-root': {
    display: 'flex',
    borderRadius: '100px',
    border: '1px solid $white',
    background: '$primary1',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '0px',
    left: '36px',
    zindex: 1,
  },
};

export const HeadingWrapper = styled(Layout.Group, {
  cursor: 'pointer',
  paddingLeft: '8px',
});

export const HeadingExternalWrapper = styled(Layout.Group, {
  cursor: 'pointer',
  paddingLeft: '8px',
  marginBottom: '8px',
  marginRight: '-4px',
  height: '42px',
});

export const FacilityText = styled(Text, {
  '&.abyss-text-root': {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    fontSize: '20.25px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'left',
  },
});

export const PriceText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$sm',
  },
  '@screen < $md': {
    '&.abyss-text-root': {
      fontSize: '$smallText',
    },
  },
});
export const PrimarySectionStyle = {
  padding: '12px  16px 0px 16px',
  '@screen < $sm': { padding: '12px  12px 0px 12px' },
};

export const PopupContainerStyle = {
  padding: '0px',
  paddingTop: '4px',
  '@screen < $sm': { padding: '12px  12px 0px 12px' },
};
export const SecondarySectionStyle = {
  marginTop: '-20px',
  padding: '4px 16px 0px 78px',
  '@screen < $sm': { padding: '4px 12px 0px 76px' },
  borderTop: 'none !important',
};

export const popupSecondarySectionStyle = {
  marginTop: '0px',
  padding: '0px',
  paddingBottom: '10px',
  '@screen < $sm': { padding: '4px 12px 0px 76px' },
  borderTop: 'none !important',
};

export const VirtualVisitPatientStyle = {
  paddingTop: '10px',
  '@screen < $sm': {
    paddingTop: '8px',
    maxWidth: '175px',
  },
  '.abyss-tooltip-trigger': {
    '@media (max-width: 390px)': {
      maxWidth: '100px',
    },
  },
};

export const VirtualVisitStyle = {
  paddingTop: '8px',
  '@screen < $sm': {
    paddingTop: '6px',
    maxWidth: '100px',
  },
  '.abyss-tooltip-trigger': {
    '@media (max-width: 390px)': {
      maxWidth: '75px',
    },
  },
};
export const RatingText = styled(Text, {
  marginLeft: '3px',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
  gap: '4px',
  '&.abyss-text-root': {
    fontSize: '16',
  },
  '@screen < $md': {
    '&.abyss-text-root': {
      fontSize: '$xs',
    },
  },
});
export const UnderlineRatingText = styled(Text, {
  marginLeft: '3px',
  textDecoration: 'underline',
  textUnderlineOffset: '0.25em !important',
  gap: '4px',
  '&.abyss-text-root': {
    fontSize: '16',
  },
  '@screen < $md': {
    '&.abyss-text-root': {
      fontSize: '$xs',
    },
  },
});
export const RatingsText = styled(Text, {
  marginLeft: '3px',
  lineHeight: '18px',
  '&.abyss-text-root': {
    marginTop: '-0.8px',
  },
  '@screen < $md': {
    '&.abyss-text-root': {
      fontSize: '$xs',
    },
  },
});

export const ResponsiveText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '16px',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    maxWidth: '175px',
    '@screen < $sm': {
      fontSize: '$extraSmallText',
      '-webkit-line-clamp': '1',
      maxWidth: '140px',
      lineHeight: '16px',
    },
  },
  wordBreak: 'break-word',
  variants: {
    ancillarySpeciality: {
      true: {
        '&.abyss-text-root': {
          '-webkit-line-clamp': '1',
          textAlign: 'left',
          maxWidth: '230px',
          display: 'block',
          '@screen < $sm': {
            maxWidth: '200px',
          },
        },
      },
    },
  },
});

export const MilesButton = styled('div', {
  display: 'flex',
});

export const VirtualVisitLabel = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '16px',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    maxWidth: '175px',
    '@screen < $sm': {
      fontSize: '$extraSmallText',
      '-webkit-line-clamp': '1',
      maxWidth: '140px',
      lineHeight: '16px',
    },
  },
  wordBreak: 'break-word',
  variants: {
    ancillarySpeciality: {
      true: {
        '&.abyss-text-root': {
          '-webkit-line-clamp': '1',
          textAlign: 'left',
          maxWidth: '230px',
          '@screen < $sm': {
            maxWidth: '200px',
          },
        },
      },
    },
  },
});

export const NoRatingText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$xs',
    fontStyle: 'italic',
  },
  wordBreak: 'break-word',
});

export const AddressText = styled(Text, {
  fontSize: '$md',
  '@screen < $md': {
    '&.abyss-text-root': {
      fontSize: '$xs',
    },
  },
  '@screen < $sm': {
    '&.abyss-text-root': {
      fontSize: '$smallText',
      maxWidth: '200px',
    },
  },

  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  display: 'inline-block',
  overflow: 'hidden',
  maxWidth: '250px',
});

export const MilesAndAddressWrapper = styled('div', {
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  padding: '4px 16px',
  '@screen < $sm': {
    padding: '2px 12px',
  },
  '.abyss-text-root': {
    lineHeight: '20px !important',
  },
});
export const AddressWrapper = styled('div', {
  marginTop: '2px',
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  '@screen < $sm': {
    paddingTop: '2px',
  },
});

export const ProviderCardContent = styled('div', {
  '@screen < $md': {
    paddingBottom: '0',
  },
  height: '14px',
  lineHeight: '16px',
  textAlign: 'center',
  gap: '8px',
});

export const BottomWrapper = styled(Flex, {
  borderTop: '1px solid #CBCCCD',
  padding: '8px 16px',
  flexWrap: 'nowrap',
  span: {
    padding: '$xs 0',
  },
  '.icon-text-container': {
    alignItems: 'center',
  },
  '@screen < $sm': {
    padding: '8px 12px',
  },
});

export const BottomWrapperChoosePCP = styled(Flex, {
  borderTop: '1px solid #CBCCCD',
  padding: '5px 16px',
  flexWrap: 'nowrap',
  span: {
    padding: '$xs 0',
    whiteSpace: 'nowrap',
  },
  '.abyss-button-root': {
    width: '128px',
    height: '42px',
    border: '2px solid $primary1',
    '@screen < $sm': {
      height: '28px',
    },
  },
  '@screen < $sm': {
    padding: '8px 12pxs',
  },
});

export const Dot = styled('div', {
  height: '4px',
  width: '4px',
  borderRadius: '50px',
  background: '$gray8',
});

export const DirectionButton = styled('button', {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
});

export const ChooseAsPCPButton = styled(Button, {
  fontSize: '$md',
  fontWeight: '$semibold',
  lineHeight: '20px',
  padding: '0px !important',
});

export const FlexStyles = {
  paddingTop: '10px',
  '@screen < $sm': {
    paddingTop: '3px',
  },
  '.abyss-icon-material': {
    width: '16px',
    height: '16px',
    marginTop: '1px',
  },
  '.abyss-text-root': { marginTop: '-1px' },
};

export const popupFlexStyles = {
  paddingTop: '0px',
  '@screen < $sm': {
    paddingTop: '3px',
  },
  '.abyss-icon-material': {
    width: '16px',
    height: '16px',
    marginTop: '1px',
  },
  '.abyss-text-root': { marginTop: '-1px' },
};

export const costIndicatorStyles = {
  paddingTop: '8px',
  '@screen < $sm': {
    paddingTop: '1px',
  },
  '.abyss-text-root': {
    '@screen < $sm': {
      fontSize: '$extraSmallText !important',
      fontWeight: '700',
      lineHeight: '16px !important',
    },
  },
};

export const milesTextStyles = {
  '.abyss-icon-material': {
    marginTop: '4px',
  },
  '.abyss-text-root': {
    marginTop: '2px',
    '@screen < $sm': {
      marginTop: '5px',
      fontSize: '$extraSmallText !important',
      fontWeight: '700',
      lineHeight: '16px !important',
    },
  },
};

export const popupMilesTextStyles = {
  '.abyss-icon-material': {
    marginTop: '4px',
  },
  '.abyss-text-root': {
    marginTop: '2px',
    '@screen < $sm': {
      marginTop: '5px',
      fontSize: '$extraSmallText !important',
      fontWeight: '700',
      lineHeight: '16px !important',
    },
  },
};

export const TerminationDateIndicatorContainer = styled('div', {
  padding: '0px 8px 0px 8px',
  alignItems: 'center',
  justifyContent: 'center',
});

export const InNetworkBadgeContainer = styled('div', {
  padding: '0px 8px 0px 8px',
  alignItems: 'center',
  justifyContent: 'center',
});
