import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useSnackCardProviderResults } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { useSpacing } from '../../hooks/useSpacing';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getSnackCardDisplayValByLength } from '../../utils/snackcard.utils';
import { getOnlineRetailers } from '../../utils/user.utils';
import {
  Constants,
  FilterCollectionModel,
  ProviderTypes,
  SHOULD_GET_HG_DATA,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import {
  cardSubHeaderContainerStyle,
  cardSubHeaderWithNavigatorStyle,
  landingDrawerIcon,
  landingPopOverIcon,
  phoneOnly,
  viewAllLinkStyle,
} from '../ConstantsStyles';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { SnackCardCarousel, SnackCardContainer } from '../SnackCardContainer';
import { convertObjectToUrlParams } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';

const CardResultContainer = styled('div', {
  marginTop: '$md',
});

type CardGroup = {
  key: string;
  heading?: string;
  DNT_heading?: string;
  popoverContent?: string;
  specialtyCode?: string;
  isOnlineProviders: string;
  coverageType: string;
  providerType: string;
  isActive: boolean;
};

type Props = {
  section: CardGroup;
  onCompleted: (data: any[]) => any;
  onError?: (error: any) => any;
};

export const VisionCare = ({ section, onCompleted, onError }: Props) => {
  const { navigate } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const visionCarePopOverTestId = `vision-care-popover-${section?.DNT_heading}`;
  const visionCareModalDrawerTestId: string = 'vision-care-drawer';
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setDefaultSearchFilters } = useContext(SearchFilterContext);
  const [rulesPackageKey, setRulesPackageKey] = useState('');

  useEffect(() => {
    if (isOpen) {
      const linkName: string = visionCarePopOverTestId;
      const linkLocation: string = `body:${section.DNT_heading}`;
      const modalName: string =
        typeof section.DNT_heading !== 'string'
          ? String(section.DNT_heading)
          : section.DNT_heading;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const shouldGetHGData = SHOULD_GET_HG_DATA;
  const [enableInclSpecCodeChanges] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_INCLUDE_SPEC_CODE_CHANGES,
  ]);

  const {
    data: providersData,
    isLoading,
    headers,
  } = useSnackCardProviderResults({
    includeSpecialityRollupCodes: section.specialtyCode,
    includeSpecRollupCodesArray: enableInclSpecCodeChanges
      ? section.specialtyCode?.split(',')
      : null,
    providerType: section.providerType,
    search: section?.DNT_heading || section?.heading,
    coverageType: section.coverageType,
    onlineRetailers: section.isOnlineProviders, // isOnlineProviders that configured in lagoon must be 'Y/N/Blank'
    // onlineRetailers: getOnlineRetailers(section.isOnlineProviders), // isOnlineProviders that configured in lagoon must be 'Y/N/Blank'
    onCompleted,
    shouldGetHGData,
    onError,
  });

  const { totalCount } = providersData;
  const { styleProps, viewAllCount, css, viewAllColumnGap } =
    useSnackcardViewAllCount(totalCount);

  const numberOfCards = providersData?.snackCardList?.length;
  const displayCarousel = getSnackCardDisplayValByLength(numberOfCards);
  const displayViewAllLink = isLoading === false && numberOfCards;
  useEffect(() => {
    if (providersData?.rulesPackageKey) {
      setRulesPackageKey(providersData.rulesPackageKey);
    }
  }, [providersData?.rulesPackageKey]);

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const { setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      setSearchStore: state.setSearchStore,
    }))
  );
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType } = chipStore;

  const handleViewAllOnClick = () => {
    setDefaultSearchFilters(
      [
        FilterCollectionModel.AUTO_SEARCH_RADIUS,
        FilterCollectionModel.DISTANCE,
      ],
      {
        autoSearchRadius: providersData?.searchRadius,
      }
    );
    setCoverageType(section.coverageType);
    const typeaheadStoreValues = {
      specialtyCode: section.specialtyCode,
      search: section?.DNT_heading || section?.heading,
      sectionType:
        section.providerType === ProviderTypes.PRACTITIONER
          ? Constants.SEARCH_TYPES.PROVIDER
          : Constants.SEARCH_TYPES.FACILITY,
      providerType: section.providerType,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      keyword: section?.DNT_heading || section?.heading,
      heading: section.heading,
    };
    setAnalyticsState({ linkName: visionCarePopOverTestId });
    setTypeaheadSearchStore({
      specialtyCode: section.specialtyCode,
      search: section?.DNT_heading || section?.heading,
      sectionType:
        section.providerType === ProviderTypes.PRACTITIONER
          ? Constants.SEARCH_TYPES.PROVIDER
          : Constants.SEARCH_TYPES.FACILITY,
      providerType: section.providerType,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
    });
    setSearchStore({
      searchRadius: providersData?.searchRadius,
      onlineRetailers: getOnlineRetailers(section.isOnlineProviders), // isOnlineProviders that configured in lagoon must be 'Y/N/Blank'
    });

    const urlParams = convertObjectToUrlParams(chipStore, {
      coverageType: section.coverageType,
      ...typeaheadStoreValues,
      searchRadius: providersData?.searchRadius,
    });

    navigate(
      `${ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path}${urlParams}`
    );
  };

  const spacing = useSpacing(Number(section?.key) > 1);

  return (
    <React.Fragment>
      {section.isActive ? (
        <CardResultContainer css={spacing}>
          <Flex
            css={{
              ...cardSubHeaderContainerStyle,
              'abyss-flex-root': {
                ...cardSubHeaderContainerStyle['abyss-flex-root'],
                ...viewAllColumnGap['abyss-flex-root'],
              },
            }}
            justify="start"
            key={section.heading}
          >
            <div>
              <Heading
                css={cardSubHeaderWithNavigatorStyle}
                data-auto-testid="vision-care-heading"
                data-testid="vision-care-heading"
                display="h6"
                offset={2}
              >
                {section.heading}
                <span
                  style={{
                    marginLeft: '4px',
                    display: 'inline-block',
                  }}
                >
                  <Drawer
                    css={landingDrawerIcon}
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    position="bottom"
                    size="200"
                    title={section?.heading}
                  >
                    {section?.popoverContent}
                  </Drawer>
                  {mobileScreen ? (
                    <IconMaterial
                      css={landingPopOverIcon}
                      data-auto-testid={visionCareModalDrawerTestId}
                      data-testid={visionCareModalDrawerTestId}
                      icon="info"
                      onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                      size={16}
                      variant="outlined"
                    />
                  ) : (
                    <Popover
                      aria-label={`${t('INFORMATION_ABOUT')} ${
                        section.heading
                      }`}
                      content={section?.popoverContent}
                      css={{
                        'abyss-popover-content': {
                          zIndex: 9,
                        },
                      }}
                      data-auto-testid={visionCarePopOverTestId}
                      data-testid={visionCarePopOverTestId}
                      onOpenChange={() => setIsOpen(!isOpen)}
                      open={isOpen}
                      title={section?.heading}
                      width={mobileScreen ? 222 : 180}
                    >
                      <IconMaterial
                        css={landingPopOverIcon}
                        data-auto-testid={visionCarePopOverTestId}
                        data-testid={visionCarePopOverTestId}
                        icon="info"
                        onClick={() => setIsOpen(!isOpen)}
                        size={16}
                        variant="outlined"
                      />
                    </Popover>
                  )}
                </span>
              </Heading>
            </div>
            {displayViewAllLink ? (
              <LinkContainer
                {...styleProps}
                analyticsInfo={{
                  location: `body:${section.DNT_heading}`,
                }}
                aria-label={`${t('View all')} ${section.heading}`}
                css={{ ...viewAllLinkStyle, ...css }}
                data-auto-testid="vision-care-view-all"
                data-testid="vision-care-view-all"
                onClick={handleViewAllOnClick}
                text={`${t('View all')} ${viewAllCount}`}
              />
            ) : null}
          </Flex>

          <div>
            {displayCarousel ? (
              <SnackCardCarousel
                coverageType={section.coverageType}
                handleViewAllOnClick={handleViewAllOnClick}
                headers={headers}
                heading={section.heading}
                isFixes
                isLoading={isLoading}
                locationForAnalytics={section.DNT_heading}
                rulesPackageKey={rulesPackageKey}
                snackCardProviders={providersData?.snackCardList}
              />
            ) : (
              <SnackCardContainer
                coverageType={section.coverageType}
                data-auto-testid="vision-snack-card-container"
                dataTestId="vision-snack-card-container"
                handleViewAllOnClick={handleViewAllOnClick}
                headers={headers}
                heading={section.heading}
                isLoading={isLoading}
                locationForAnalytics={section.DNT_heading}
                rulesPackageKey={rulesPackageKey}
                snackCardProviders={providersData?.snackCardList}
              />
            )}
          </div>
        </CardResultContainer>
      ) : null}
    </React.Fragment>
  );
};
