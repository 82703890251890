import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import React, { useContext, useEffect, useState } from 'react';

import { CountySearchContext } from '../../context/CountySearchContext';
import { useLagoon } from '../../hooks/useLagoon';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { Provider } from '../../models/Provider';
import { ProviderType } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { isProviderStartDateInFuture } from '../../utils/date.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { DataCardContainer } from '../DataCard/DataCardContainer';
import { OutsideCountyAlert } from '../DataCard/OutsideCountyAlert';
import { NationalGroupProvidersVirtualDataCard } from '../NationalGroupProvidersVirtualBiteCard/NationalGroupProvidersVirtualDataCard';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import {
  mixedResultsSlideStyle,
  slideContainerStyle,
  slideStyle,
} from '../SnackCard/SnackCard.styles';
import { isOutOfCounty } from '../SnackCardContainer/utils';
import { getFeatureFlag } from '../Utils';
import { makeAllSlidesAriaHidden } from '../Utils/a11yUtils/a11yUtils';
import { isMnrMember } from '../Utils/memberUtils/memberUtils';
import { checkTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { ResultCardViewAllLinks } from './ResultCardsViewAllLink';
import { DataCardStyle, mixedResultCarouselStyle } from './ResultSection.style';

type Props = {
  headers: ResponseHeaders;
  results: Provider[];
  sectionType: string;
  isLoading: boolean;
  searchTerm: string;
  handleViewAllOnClick?: () => void;
};

export const MixedResultCards = ({
  headers,
  results,
  sectionType,
  isLoading,
  searchTerm,
  handleViewAllOnClick,
}: Props) => {
  const result = results?.slice(
    0,
    Constants.RESULT_SECTION.MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
  );
  const featureFlags = useLagoon('feature-flags')();
  const countySearchAlertFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT
  );
  const { isExpandViewAllEnabled } = useSnackcardViewAllCount();
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();
  const [slidesPerView, setSlidesPerView] = useState(2);
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const { lineOfBusiness } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);
  useEffect(() => {
    let windowWidth = window.innerWidth;
    function handleResize() {
      windowWidth = window.innerWidth;
      setWinWidth(windowWidth);
    }
    window.addEventListener('resize', handleResize);
    if (winWidth > 872) {
      if (winWidth > 872 && result?.length >= 3) setSlidesPerView(2);
      else if (winWidth < 1250 && result?.length === 2) setSlidesPerView(3);
      else if (winWidth < 1250 && result?.length > 2) setSlidesPerView(2);
      else if (winWidth > 1250 && result?.length >= 2) setSlidesPerView(3);
      else if (result?.length >= 1) setSlidesPerView(4);
    }
    if (winWidth < 872) setSlidesPerView(1);
    if (
      isExpandViewAllEnabled &&
      winWidth > 868 &&
      (result?.length === 1 || result?.length === 2)
    )
      setSlidesPerView(2);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const displayVirtualProvider = (provider: Provider) =>
    !provider?.address &&
    !!provider?.virtualCareOffered?.length &&
    (provider.providerType === ProviderType.PRACTITIONER ||
      provider.providerType === ProviderType.PROVIDER_GROUP);

  const slides = () => {
    if (isLoading) {
      return [1, 2, 3].map((index) => (
        <Slide css={slideStyle}>
          <Slide.Container css={slideContainerStyle}>
            <DataCardContainer
              css={DataCardStyle}
              index={index}
              isLoading
              searchTerm={searchTerm}
              sectionType={sectionType}
            />
          </Slide.Container>
        </Slide>
      ));
    }

    const displayTerminationDateEnabled: boolean = getFeatureFlag(
      featureFlags,
      ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
    );

    const isDisplayVirtualProvider = result?.some((res) =>
      displayVirtualProvider(res)
    );
    const isNetworkAlertIndicatorEnabled = result?.some((snackCardProvider) =>
      checkTerminationDate(
        displayTerminationDateEnabled,
        snackCardProvider?.networkEndDate
      )
    );
    const isInNetworkBadgeEnabled = result?.some((snackCardProvider) =>
      isProviderStartDateInFuture(snackCardProvider?.networkStartDate)
    );

    const isOutOfCntyIndicatorEnabled = result?.some((res) =>
      isOutOfCounty(isCountySearch, name, stateCode, res)
    );

    const outOfCntyIndicatoryShowing =
      countySearchAlertFlag && isOutOfCntyIndicatorEnabled;

    const allSlides = result?.map((res, i) => {
      const isOutOfCnty = isOutOfCounty(isCountySearch, name, stateCode, res);
      const showOutOfCntyIndicatory =
        (isOutOfCnty && countySearchAlertFlag) || isOutOfCntyIndicatorEnabled;
      const isNetworkAlertIndicator = result.some((snackCardProvider) =>
        checkTerminationDate(
          displayTerminationDateEnabled,
          snackCardProvider?.networkEndDate
        )
      );
      const isInNetworkBadge = result.some((snackCardProvider) =>
        isProviderStartDateInFuture(snackCardProvider?.networkStartDate)
      );

      return (
        <Slide
          css={mixedResultsSlideStyle(
            i,
            result?.length,
            isExpandViewAllEnabled
          )}
        >
          <Slide.Container css={slideContainerStyle}>
            {!displayVirtualProvider(res) && (
              <React.Fragment>
                <DataCardContainer
                  css={DataCardStyle}
                  headers={headers}
                  index={result.indexOf(res)}
                  isInNetworkBadge={isInNetworkBadge}
                  isLoading={isLoading}
                  isMNR={isMNR}
                  isNetworkAlertIndicator={isNetworkAlertIndicator}
                  locationForAnalytics={`${sectionType} results`}
                  practitioner={res}
                  searchTerm={searchTerm}
                  sectionType={sectionType}
                />
                {showOutOfCntyIndicatory && (
                  <OutsideCountyAlert
                    county={name?.split(',')[0]}
                    isOutOfCntyIndicatorEnabled={isOutOfCntyIndicatorEnabled}
                    isOutOfCounty={isOutOfCnty}
                  />
                )}
              </React.Fragment>
            )}
            {displayVirtualProvider(res) && (
              <React.Fragment>
                <NationalGroupProvidersVirtualDataCard
                  css={DataCardStyle}
                  fromMobileListView={false}
                  headers={headers}
                  index={result.indexOf(res)}
                  isLoading={isLoading}
                  locationForAnalytics={`${sectionType} results`}
                  practitioner={res}
                  sectionType={sectionType}
                />

                {showOutOfCntyIndicatory && (
                  <OutsideCountyAlert
                    county={name?.split(',')[0]}
                    isOutOfCntyIndicatorEnabled={isOutOfCntyIndicatorEnabled}
                    isOutOfCounty={isOutOfCnty}
                  />
                )}
              </React.Fragment>
            )}
          </Slide.Container>
        </Slide>
      );
    });

    {
      isExpandViewAllEnabled &&
        allSlides.push(
          <ResultCardViewAllLinks
            handleViewAllOnClick={handleViewAllOnClick}
            isDisplayVirtualProvider={isDisplayVirtualProvider}
            isNetworkIndicatorEnabled={
              isInNetworkBadgeEnabled || isNetworkAlertIndicatorEnabled
            }
            isOutOfCntyIndicatorEnabled={isOutOfCntyIndicatorEnabled}
            outOfCntyIndicatoryShowing={outOfCntyIndicatoryShowing}
            resultCount={result?.length}
            sectionType={sectionType}
          />
        );
    }

    return allSlides;
  };

  makeAllSlidesAriaHidden();

  return (
    <React.Fragment>
      <Carousel
        css={mixedResultCarouselStyle(
          isExpandViewAllEnabled ? result?.length + 1 : result?.length,
          winWidth,
          isLoading,
          isExpandViewAllEnabled
        )}
        data-auto-testid="mixed-results-carousel-skeleton-loader"
        data-testid="mixed-results-carousel-skeleton-loader"
        minimal
        nextSlideOnClick={() => makeAllSlidesAriaHidden()}
        noLoop
        prevSlideOnClick={() => makeAllSlidesAriaHidden()}
        slideIndexOnClick={() => makeAllSlidesAriaHidden()}
        slides={slides()}
        slidesPerView={slidesPerView}
      />
    </React.Fragment>
  );
};
