import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PortalContext } from '../../context/PortalContext';
import { capitalizeEachWord } from '../../utils/providerNames.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import {
  EAP_OPTION_LABEL,
  EAP_OPTION_LABEL_ES,
  FilterCollectionModel,
} from '../Constants';
import { BoardCertifiedInfoIcon } from './BoardCertifiedInfoIcon';

const buttonStyles: any = {
  'abyss-button-root': {
    height: '42px',
    marginTop: '20px',
    border: 'none',
    borderRadius: '24px',
  },
};

type Radio = {
  label: String;
  value: string | number | boolean;
  count?: number;
  isDisabled?: boolean;
};

interface Props {
  groupLabel?: string;
  options: Array<Radio>;
  defaultValue?:
    | string[]
    | number[]
    | boolean[]
    | (string | number | boolean)[];
  disableSubmitBtn?: boolean;
  model: string;
  onApply?: (currentItem: string[]) => void;
  onOptionChange?: (any) => void;
  submitBtnName?: string;
  labelFontSize?: string;
  locationForAnalytics?: string;
}

export const FilterCheckBoxGroup = ({
  options,
  defaultValue,
  groupLabel,
  model,
  onOptionChange = () => {},
  onApply = () => {},
  submitBtnName,
  disableSubmitBtn = false,
  labelFontSize = '16px',
  locationForAnalytics,
}: Props) => {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState(
    defaultValue?.map(String) || []
  );

  const { portalData } = useContext(PortalContext);
  const portalLockEAPfilter = portalData?.portalConfig?.lockEapFilter;

  useEffect(() => {
    setCurrentItem(defaultValue?.map(String) || []);
  }, [JSON.stringify(defaultValue)]);

  const onSubmit = () => {
    onApply(currentItem);
  };

  const getLabelName = (filter: string) => {
    const label = options
      .find((option) => option.value.toString() === filter)
      ?.label.concat(`_${filter}`);
    return label;
  };

  function onChangeCheckboxGroupOption(e) {
    let linkName;

    if (!isEqual(currentItem, e)) {
      if (isEmpty(currentItem) && e?.length === 1) {
        linkName = `${getLabelName(e[0])}:check`;
      } else {
        let updatedOption;
        if (e?.length > currentItem?.length) {
          updatedOption = difference(e, currentItem);
          linkName = `${getLabelName(updatedOption[0])}:check`;
        }
        if (e?.length < currentItem?.length) {
          updatedOption = difference(currentItem, e);
          linkName = `${getLabelName(updatedOption[0])}:uncheck`;
        }
      }
      adobeLinkTrackEvent({
        name: linkName,
        location: locationForAnalytics,
        type: 'checkbox',
      });
    }

    setCurrentItem(e);
    onOptionChange({
      [model]: options.filter((option) => e.includes(option.value.toString())),
    });
  }

  function listOfLabels(label: String, model: string) {
    if (
      model === t('CONTENT_FILTERS.HOSPITAL_AFFILIATIONS').replace(/\s/g, '')
    ) {
      return capitalizeEachWord(label);
    } else {
      return label;
    }
  }

  return (
    <React.Fragment>
      <Flex
        css={{
          'abyss-flex-root': {
            maxHeight: '314px',
            overflow: 'auto',
            overflowX: 'hidden',
          },
        }}
        direction="column"
      >
        <CheckboxGroup
          css={{
            'abyss-checkbox-group-label': {
              margin: '0',
            },
            'abyss-checkbox-group-root': {
              border: 'none',
            },
          }}
          data-auto-testid={`${model}-group`}
          data-testid={`${model}-group`}
          label={groupLabel}
          onChange={(e) => {
            onChangeCheckboxGroupOption(e);
          }}
          validators={{ required: false }}
          value={currentItem}
        >
          {options.map(({ value, label, count, isDisabled }) => {
            const shouldBeDisabled =
              count === 0 ||
              isDisabled ||
              (portalLockEAPfilter &&
                (label === EAP_OPTION_LABEL || label === EAP_OPTION_LABEL_ES));
            return (
              <Checkbox
                css={{
                  'abyss-checkbox-root': {
                    padding: '12px 0px 12px $xs',
                    margin: '0 !important',
                    alignItems: 'center !important',
                  },
                  'abyss-checkbox-label': {
                    fontSize: labelFontSize,
                    color: shouldBeDisabled
                      ? '$gray5'
                      : !shouldBeDisabled &&
                        (currentItem?.includes(value.toString())
                          ? '$gray8'
                          : '$gray7'),
                    fontWeight:
                      !shouldBeDisabled &&
                      (currentItem?.includes(value.toString())
                        ? '$bold'
                        : '$medium'),
                  },
                }}
                data-testid="checkbox-selection"
                isDisabled={shouldBeDisabled}
                key={label.concat(`_${value}`)}
                label={
                  count || count === 0
                    ? `${label} (${count})`
                    : listOfLabels(label, model)
                }
                value={value.toString()}
              />
            );
          })}
        </CheckboxGroup>
        {model ===
        FilterCollectionModel.BOARD_CERTIFIED_SPECIALITY_INDICATOR ? (
          <BoardCertifiedInfoIcon />
        ) : null}
      </Flex>
      {submitBtnName ? (
        <Button
          css={buttonStyles}
          data-auto-testid="apply-button"
          data-testid="apply-button"
          isDisabled={disableSubmitBtn}
          onClick={onSubmit}
        >
          {submitBtnName}
        </Button>
      ) : null}
    </React.Fragment>
  );
};
