import { ConstantsLagoon } from '../../../../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { getCurrentMember } from '../../../../../../utils/user.utils';
import { getDefaultCompareAttributes } from '../../../ViewAll/utils/getDefaultCompareAttributes';
import { CHECKBOX_ITEM_NAMES } from './compareDrawerConstants';

export function getDefaultCheckedCompareAttributes(
  coverageType: string,
  suppressPremiumCare: boolean,
  suppressPatientReviews: boolean
): string[] {
  const currentMember = getCurrentMember();
  const isCns = currentMember?.isCnSMember;

  const showTier2Badge = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_TWO_PROVIDER,
  ]);

  const isIFPTieredPlan = currentMember?.isIFPTieredPlan && showTier2Badge;

  const defaultCompareAttribute = getDefaultCompareAttributes(coverageType);
  const attributeList: string[] = [];
  defaultCompareAttribute
    .filter((item) => {
      if (item.name === CHECKBOX_ITEM_NAMES.PREMIUM_CARE_PROVIDER) {
        return !suppressPremiumCare;
      }
      if (item.name === CHECKBOX_ITEM_NAMES.REVIEWS) {
        return !suppressPatientReviews;
      }
      if (item.name === CHECKBOX_ITEM_NAMES.TIER_PROVIDER) {
        return !(isIFPTieredPlan || isCns);
      }
      if (item.name === CHECKBOX_ITEM_NAMES.TIER_2_PROVIDER) {
        return isIFPTieredPlan;
      }
      return true;
    })
    .map((index) => {
      if (index.isChecked === true) {
        attributeList.push(index.name);
      }
    });

  return attributeList;
}
