import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
/* eslint-disable no-nested-ternary */
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import { useContext } from 'react';
import React from 'react';

import { CountySearchContext } from '../../context/CountySearchContext';
import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { isProviderStartDateInFuture } from '../../utils/date.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { OutsideCountyAlert } from '../DataCard/OutsideCountyAlert';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { CardInfo } from '../SnackCard/CardInfo';
import { SnackCard } from '../SnackCard/SnackCard';
import { getFeatureFlag } from '../Utils';
import { isMnrMember } from '../Utils/memberUtils/memberUtils';
import { checkTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { SnackCardViewAllLink } from './SnackCardViewAllLink';
import { isOutOfCounty } from './utils';

const flexStyles = {
  'abyss-flex-root': {
    gap: '$md',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    alignItems: 'end',

    '& > div': {
      flex: 'none',
    },
  },
};

type Props = {
  coverageType?: string;
  dataTestId?: string;
  snackCardProviders: SnackCardProvider[];
  isLoading: boolean;
  locationForAnalytics?: string;
  heading?: string;
  rulesPackageKey?: string;
  headers: ResponseHeaders;
  handleViewAllOnClick?: () => void;
};

export const SnackCardContainer = ({
  coverageType,
  snackCardProviders = [],
  dataTestId = '',
  isLoading,
  locationForAnalytics,
  rulesPackageKey,
  headers,
  heading,
  handleViewAllOnClick,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);

  const featureFlags = useLagoon('feature-flags')();
  const countySearchAlertFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT
  );
  const displayTerminationDateEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
  );
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();
  const { isExpandViewAllEnabled } = useSnackcardViewAllCount();

  const noProvidersFoundData = useLagoon('ui-messaging')();
  const noProvidersFoundDataContent = find(noProvidersFoundData, {
    key: ConstantsLagoon.NO_PROVIDERS_FOUND,
  });
  const getLinkPositionIndex = (linkPosition?: number) => {
    if (linkPosition !== undefined) {
      return linkPosition + 1;
    }
    return linkPosition;
  };

  const linkLocationName = `body:${locationForAnalytics}`;

  const loadingCardArray: number[] = mobileScreen
    ? [1, 2, 3]
    : isExpandViewAllEnabled
    ? [1, 2, 3, 4, 5, 6]
    : [1, 2, 3, 4, 5];
  const { lineOfBusiness } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);

  const isNetworkAlertIndicatorEnabled = snackCardProviders.some(
    (snackCardProvider) =>
      checkTerminationDate(
        displayTerminationDateEnabled,
        snackCardProvider?.networkEndDate
      )
  );
  const isInNetworkBadgeEnabled = snackCardProviders.some((snackCardProvider) =>
    isProviderStartDateInFuture(snackCardProvider?.networkStartDate)
  );

  const hasPhysicalAddress = snackCardProviders.some(
    (snackCardProvider) => !!snackCardProvider.address
  );
  const isVirtualCareOffered = snackCardProviders.some(
    (snackCardProvider) => !!snackCardProvider.virtualCareOffered?.length
  );

  const isOutOfCntyIndicatorEnabled = snackCardProviders?.some(
    (snackCardProvider) =>
      isOutOfCounty(isCountySearch, name, stateCode, snackCardProvider)
  );

  return (
    <Flex
      css={flexStyles}
      data-auto-testid={dataTestId}
      data-testid={dataTestId}
    >
      {isLoading ? (
        loadingCardArray.map((item) => (
          <SnackCard isLoading={isLoading} key={item} />
        ))
      ) : snackCardProviders.length ? (
        snackCardProviders.map((snackCardProvider, index) => {
          const isOutOfCnty = isOutOfCounty(
            isCountySearch,
            name,
            stateCode,
            snackCardProvider
          );
          const isNetworkAlertIndicator = snackCardProviders.some(
            (snackCardProvider) =>
              checkTerminationDate(
                displayTerminationDateEnabled,
                snackCardProvider?.networkEndDate
              )
          );
          const isInNetworkBadge = snackCardProviders.some(
            (snackCardProvider) =>
              isProviderStartDateInFuture(snackCardProvider?.networkStartDate)
          );
          return (
            <>
              <div
                data-testid={`snack-card-${snackCardProvider?.providerType}`.replace(
                  / /g,
                  '-'
                )}
                key={snackCardProvider.locationId}
                style={{
                  display: 'grid',
                  flexDirection: 'column',
                  gridTemplateRows: 'max-content',
                }}
              >
                <CardInfo
                  cardType="snack-card-container"
                  headers={headers}
                  indexForAnalytics={getLinkPositionIndex(index)}
                  landingPage
                  locationForAnalytics={linkLocationName}
                  practitioner={snackCardProvider}
                  rulesPackageKey={rulesPackageKey}
                />
                <SnackCard
                  coverageType={coverageType}
                  distance={snackCardProvider.distance}
                  hasPhysicalAddress={!!snackCardProvider.address}
                  headers={headers}
                  imgSrc={snackCardProvider.imageLocation}
                  index={index}
                  isInNetworkBadge={isInNetworkBadge}
                  isLoading={isLoading}
                  isMNR={isMNR}
                  isNetworkAlertIndicator={isNetworkAlertIndicator}
                  isTieredProvider={snackCardProvider?.isTieredProvider}
                  isVirtualCareOffered={
                    !!snackCardProvider.virtualCareOffered?.length
                  }
                  key={snackCardProvider.locationId}
                  locationForAnalytics={locationForAnalytics}
                  locationId={snackCardProvider.locationId}
                  networkEndDate={snackCardProvider?.networkEndDate}
                  networkStartDate={snackCardProvider?.networkStartDate}
                  organizationCode={snackCardProvider.organizationCode}
                  organizationType={snackCardProvider.organizationType}
                  professionalDesignation={
                    snackCardProvider.primaryDegrees?.length
                      ? snackCardProvider.primaryDegrees[0]
                      : ''
                  }
                  providerId={snackCardProvider.providerId}
                  providerName={snackCardProvider.providerName}
                  providerType={snackCardProvider.providerType}
                  rating={
                    snackCardProvider.healthGradeRating
                      ? parseFloat(
                          parseFloat(
                            snackCardProvider.healthGradeRating
                          ).toFixed(1)
                        )
                      : undefined
                  }
                  snackCardProvider={snackCardProvider}
                  speciality={snackCardProvider?.speciality}
                  website={snackCardProvider?.website || ''}
                />
                {(countySearchAlertFlag || isOutOfCntyIndicatorEnabled) && (
                  <OutsideCountyAlert
                    county={name?.split(',')[0]}
                    isLandingPage
                    isOutOfCntyIndicatorEnabled={isOutOfCntyIndicatorEnabled}
                    isOutOfCounty={isOutOfCnty}
                  />
                )}
              </div>
              {isExpandViewAllEnabled &&
                index === snackCardProviders.length - 1 &&
                handleViewAllOnClick && (
                  <SnackCardViewAllLink
                    handleViewAllOnClick={handleViewAllOnClick}
                    hasPhysicalAddress={hasPhysicalAddress}
                    heading={heading}
                    isInNetworkBadge={isInNetworkBadgeEnabled}
                    isNetworkAlertIndicator={isNetworkAlertIndicatorEnabled}
                    isOutOfCntyIndicatorEnabled={isOutOfCntyIndicatorEnabled}
                    isVirtualCareOffered={isVirtualCareOffered}
                    key={snackCardProvider?.providerType}
                    providerType={snackCardProvider.providerType}
                  />
                )}
            </>
          );
        })
      ) : (
        <Text
          color="#6E7072"
          css={{
            'abyss-text-root': {
              fontWeight: 500,
            },
          }}
          data-testid="no-provider-results-message"
        >
          {noProvidersFoundDataContent?.message ?? ''}
        </Text>
      )}
    </Flex>
  );
};
