import { Flex } from '@abyss/web/ui/Flex';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { useSnackCardProviderResults } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { getSnackCardDisplayValByLength } from '../../utils/snackcard.utils';
import { getOnlineRetailers } from '../../utils/user.utils';
import { CardHeader } from '../CardHeader/CardHeader';
import { ReverseCoverageTypesCodes, SHOULD_GET_HG_DATA } from '../Constants';
import {
  cardHeaderContainerStyle,
  cardHeaderNullPageStyle,
} from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { LoadingSpinnerComp } from '../Loader';
import { SnackCardCarousel, SnackCardContainer } from '../SnackCardContainer';

type Props = {
  heading: string;
  coverageType: string;
};
export const TopRecommendedProvider = ({ heading, coverageType }: Props) => {
  const { t } = useTranslation();
  const lagoonTableName = 'vision-care';
  const browseVisionCare = useLagoon(lagoonTableName)();
  const visionSection = browseVisionCare.filter(
    (item) => item?.heading === t('Eye care providers')
  )[0];

  const browseDentalCare = useLagoon('browse-dental-providers-landing-page')();
  const dentalSection = browseDentalCare.filter(
    (item) => item?.heading === t('General dentist')
  )[0];
  const shouldGetHGData = SHOULD_GET_HG_DATA;
  const [rulesPackageKey, setRulesPackageKey] = useState('');

  const snackCardListPerCoverage = () => {
    switch (coverageType) {
      case ReverseCoverageTypesCodes.DENTAL:
        return useSnackCardProviderResults({
          includeSpecialityRollupCodes: dentalSection?.specialtyCode,
          providerType: dentalSection?.providerType,
          shouldGetHGData,
          coverageType,
        });
      case ReverseCoverageTypesCodes.VISION:
        return useSnackCardProviderResults({
          includeSpecialityRollupCodes: visionSection?.specialtyCode,
          providerType: visionSection?.providerType,
          coverageType: visionSection?.coverageType,
          onlineRetailers: getOnlineRetailers(visionSection?.isOnlineProviders),
          shouldGetHGData,
        });
      default:
        return useSnackCardProviderResults({
          providerType: 'practitioner',
          pcpIndicator: false,
          shouldGetHGData,
          coverageType,
        });
    }
  };

  const { data, isLoading, headers } = snackCardListPerCoverage();
  const { snackCardList } = data;

  const numberOfCards = data?.snackCardList?.length;
  const displayCarousel = getSnackCardDisplayValByLength(numberOfCards);
  useEffect(() => {
    if (data?.rulesPackageKey) {
      setRulesPackageKey(data.rulesPackageKey);
    }
  }, [data?.rulesPackageKey]);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinnerComp isLoading={isLoading} />
      ) : (
        <ContentWrapper>
          <Flex
            alignItems="flex-end"
            css={cardHeaderContainerStyle}
            justify="start"
          >
            <CardHeader
              css={cardHeaderNullPageStyle}
              data-testid="heading-top-providers-near-you"
            >
              {heading}
            </CardHeader>
          </Flex>
          {snackCardList?.length ? (
            <React.Fragment>
              {displayCarousel ? (
                <SnackCardCarousel
                  coverageType={coverageType}
                  headers={headers}
                  isFixes
                  isLoading={false}
                  locationForAnalytics={heading}
                  rulesPackageKey={rulesPackageKey}
                  snackCardProviders={snackCardList?.slice(0, 5)}
                />
              ) : (
                <SnackCardContainer
                  coverageType={coverageType}
                  headers={headers}
                  isLoading={false}
                  locationForAnalytics={heading}
                  rulesPackageKey={rulesPackageKey}
                  snackCardProviders={snackCardList?.slice(0, 5)}
                />
              )}
            </React.Fragment>
          ) : (
            t('No Providers Found')
          )}
        </ContentWrapper>
      )}
    </React.Fragment>
  );
};
