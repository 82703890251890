import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { getCurrentMember } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { FeatureFlags } from '../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { PSXIcon } from '../PSXIcon';
import { TieredDrawerStyles } from '../TierBenefitsCard/TierBenefitsCard.style';
import { getFeatureFlag } from '../Utils';
import {
  TierCardText,
  TierInfoBox,
  TierOneSnackCardPopoverStyles,
} from './SnackCard.styles';

type Props = {
  isTierOne?: boolean;
  addSpaceForTierBadge?: boolean;
};

export const TierOne = ({ isTierOne, addSpaceForTierBadge = false }: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isTierDrawerOpen, setIsTierDrawerOpen] = useState(false);

  const data = useLagoon('ui-messaging')();
  const tierOneContentOne = find(data, {
    key: ConstantsLagoon.MEDICAL_SPECIALISTS.TIER_ONE_BENEFITS_ONE,
  });
  const tierContent = tierOneContentOne?.message;

  const featureTierOneFlags = useLagoon('feature-flags')();
  const showTierProviderTag = getFeatureFlag(
    featureTierOneFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const currentMember = getCurrentMember();

  if (!isTierOne || !showTierProviderTag) {
    return addSpaceForTierBadge ? <div style={{ height: '24px' }} /> : null;
  }

  const tierOnePopoverContent =
    (tierContent ?? (currentMember?.isIFPTieredPlan && tierContent)) ||
    t('TIERED_BENEFITS.TIER_CONTENT');

  return (
    <div style={{ height: '24px' }}>
      <Drawer
        css={TieredDrawerStyles}
        data-auto-testid="tierOneSnackCardDrawerId"
        data-testid="tierOneSnackCardDrawerId"
        isOpen={isTierDrawerOpen}
        onClose={() => setIsTierDrawerOpen(false)}
        position="bottom"
        size="200"
        title={t('TIERED_BENEFITS.TIER_HEADING')}
      >
        {tierOnePopoverContent}
      </Drawer>
      {mobileScreen ? (
        <TierInfoBox
          data-auto-testid="tierOneSnackCardDrawer"
          data-testid="tierOneSnackCardDrawer"
          onClick={() => setIsTierDrawerOpen(true)}
        >
          <PSXIcon icon="Group 204976975" size={16} />
          <TierCardText>{t('Tier 1 Provider')}</TierCardText>
        </TierInfoBox>
      ) : (
        <Popover
          content={tierOnePopoverContent}
          css={TierOneSnackCardPopoverStyles}
          data-auto-testid="tierOneSnackCard"
          data-testid="tierOneSnackCard"
          position="bottom"
          title={t('TIERED_BENEFITS.TIER_HEADING')}
          width={214}
        >
          <TierInfoBox>
            <PSXIcon icon="Group 204976975" size={16} />
            <TierCardText>{t('Tier 1 Provider')}</TierCardText>
          </TierInfoBox>
        </Popover>
      )}
    </div>
  );
};
