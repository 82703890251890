import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';

import { StyledTextInputArea } from './index.style';

type Props = {
  dataTestId: String;
  options: Array<{ label: string; value: string }>;
  title: string;
  selectedValues: Array<string | number>;
  textAreaProperties: Record<string, any>;
  model?: string;
};

export const FeedbackCheckboxGroup = ({
  dataTestId = '',
  options = [],
  title,
  selectedValues = [],
  textAreaProperties,
  model = '',
}: Props) => (
  <Flex css={{ marginTop: '12px' }}>
    <CheckboxGroup
      data-testid={`feedback-form-${dataTestId}`}
      label={title}
      model={model}
    >
      {options?.map((checkbox) => (
        <Checkbox
          data-auto-testid={`feedback-form-info-checkbox-${checkbox?.value}`}
          data-testid={`feedback-form-info-checkbox-${checkbox?.value}`}
          key={`CheckBoxKey-${checkbox?.label}`}
          label={checkbox?.label || ''}
          value={checkbox?.value}
        />
      ))}
    </CheckboxGroup>
    {selectedValues?.includes(textAreaProperties?.name) && (
      <StyledTextInputArea
        data-testid={`other-${dataTestId}-input`}
        displayMaxLength
        label={textAreaProperties?.label}
        maxLength={textAreaProperties?.maxLength}
        model={textAreaProperties?.name}
        placeholder={textAreaProperties?.placeholder}
        rows={3}
        validators={{ required: true }}
      />
    )}
  </Flex>
);
