import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { SendFeedbackFormDrawer } from '../SendFeedbackFormDrawer';

interface ProviderData {
  name: string | undefined;
  address: Record<string, any>;
  npi: string | undefined;
  mpin: string | undefined;
  providerType: string;
}

interface Props {
  data: ProviderData | null;
  type: string;
  coverageType: string;
}
export const SendFeedback = ({ type = '', data, coverageType = '' }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { name, address, npi, mpin, providerType = '' } = data || {};

  const providerTypeFormat = {
    Organization: 'ORGANIZATION',
    Practitioner: 'PRACTITIONER',
    'Medical Group': 'MEDICAL_GROUP',
  };

  const location: string =
    `${address?.line?.[0]}, ${address?.city}, ${address?.state} ${address?.postalCode}` ||
    '';

  const providerData = {
    name,
    npi,
    location,
    mpin,
    providerType: providerTypeFormat[providerType],
  };

  return (
    <React.Fragment>
      <ContainerForAllSectionsStyled
        css={{
          backgroundColor: '#F4F6FA',
          paddingTop: '18px',
          borderBottom: 0,
          '& > div': {
            paddingBottom: 0,
          },
        }}
      >
        <ContentWrapper>
          <Text css={{ display: 'block', fontWeight: 700 }}>
            {t('FEEDBACK_FORM.OUT_OF_DATE_INFO')}
          </Text>
          <Button
            aria-label="send-feedback"
            css={{ marginTop: '8px' }}
            data-testid="send-feedback-button"
            onClick={() => setIsOpen(true)}
            size="$md"
            variant="outline"
          >
            {t('FEEDBACK_FORM.SEND_FEEDBACK')}
          </Button>
        </ContentWrapper>
      </ContainerForAllSectionsStyled>
      <SendFeedbackFormDrawer
        coverageType={coverageType}
        isOpen={isOpen}
        providerData={providerData}
        setIsOpen={setIsOpen}
        type={type}
      />
    </React.Fragment>
  );
};
