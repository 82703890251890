import { config } from '@abyss/web/tools/config';
import { Icon } from '@abyss/web/ui/Icon';

import { Tier2Icon } from '../SnackCard/IconTier2';

const PSX_ASSET_CDN = `${config('CDN_BASE_URL')}/cdn/assets`;

type Props = {
  isScreenReadable?: boolean;
  icon: string;
  title?: string;
  [x: string]: any;
};
export const PSXIcon = ({ isScreenReadable, icon, title, ...props }: Props) => {
  if (!icon) {
    return null;
  }

  // tier_two.svg is not reflecting on ui, so until issue is resolved as a workaround created a component for displaying the tier_two icon 
  // and using it in conditional check.
  // created a defect for this to track -- DE988217: tier two icon is not displaying on ui even after adding svg and after generating metadata
  if (icon === 'tier_two') {
    return <Tier2Icon />;
  }

  return (
    <Icon
      {...props}
      icon={icon}
      isScreenReadable={isScreenReadable}
      spriteSheetId={icon}
      spriteSheetSrc={`${PSX_ASSET_CDN}/psx_icons.svg`}
      title={isScreenReadable ? title || icon : null}
    />
  );
};
