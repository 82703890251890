import { Layout } from '@abyss/web/ui/Layout';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { InNetworkBadgeWithDate } from '../../../common/InNetworkBadge/InNetworkBadge';
import { TerminationBadge } from '../../../common/TerminationBadge/TerminationBadge';
import { getFeatureFlag } from '../../../common/Utils';
import { useLagoon } from '../../../hooks/useLagoon';
import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { ProviderLocation } from '../../../models/ProviderDetails';
import { isProviderStartDateInFuture } from '../../../utils/date.utils';
import { shortenZipCode } from '../../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { TierBadge } from '../../TierBadge/TierBadge';
import {
  AddressWrapper,
  TierBadgeContainer,
} from '../../TierBadge/TierBadge.styled';
import {
  StyledChangeLocationButton,
  StyledLocationsContentWrapper,
  StyledPopoverRadio,
} from './PopOver.style';

type Props = {
  setSelectedLocation: (a: any, b: any) => void;
  selectedLocation: any;
  locationsDetails: ProviderLocation[] | FacilityLocationsResponse[];
  setIsOpen: (a: boolean) => void;
  selectedIndex: number;
  isOpen: boolean;
};

export const LocationsContentForPopOverDesktop = ({
  setSelectedLocation,
  selectedLocation,
  locationsDetails,
  setIsOpen,
  selectedIndex,
  isOpen,
}: Props) => {
  const { t } = useTranslation();
  const [currentLocation, setCurrentLocation] = useState(selectedLocation);
  const changeButtonTestId = 'change-button';
  const changeLocationAnalyticsLocation = 'change location';
  const featureFlags = useLagoon('feature-flags')();
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );

  const handleChangeLocation = () => {
    setSelectedLocation(currentLocation, selectedIndex);
    setIsOpen(!isOpen);
    adobeLinkTrackEvent({
      name: changeButtonTestId,
      location: `modal:${changeLocationAnalyticsLocation}`,
      type: 'internal',
    });
  };

  return (
    <RadioGroup
      css={{
        'abyss-radio-group-container': { overflowX: 'hidden' },

        'abyss-radio-root': { marginLeft: '5px' },
      }}
      data-auto-testid="radio-group"
      data-testid="radio-group"
      onChange={(event) => {
        adobeLinkTrackEvent({
          name: 'select location',
          location: `modal:${changeLocationAnalyticsLocation}`,
          type: 'radio',
        });
        setCurrentLocation(event.target._wrapperState.initialValue);
      }}
      value={currentLocation}
    >
      <StyledLocationsContentWrapper>
        {locationsDetails?.map((item, index) => (
          <React.Fragment key={index}>
            <StyledPopoverRadio
              cssProps={{
                selected: currentLocation?.locationId === item?.locationId,
              }}
              data-auto-testid="radio-group-locations"
              data-testid="radio-group-locations"
              key={item?.address?.line?.[0]}
              label={
                <>
                  <TerminationBadge
                    endDate={item.network?.end}
                    message={t('PCP_TERMINATION.MAY_LEAVE_THE_NETWORK_ON')}
                  />
                  {displayInNetworkBadgeEnabled &&
                    isProviderStartDateInFuture(item?.network?.start) && (
                      <InNetworkBadgeWithDate
                        message={t(
                          'DISPLAY_IN_NETWORK_BADGE.IN_NETWORK_BADGE_PCP'
                        )}
                        startDate={item?.network?.start}
                      />
                    )}
                  <TierBadgeContainer>
                    <AddressWrapper>
                      {item?.address?.line?.[0]} {'\n'} {item?.address?.city},
                      {item?.address?.state}
                      {shortenZipCode(item?.address?.postalCode)}
                    </AddressWrapper>
                    <TierBadge
                      isTier2Provider={item?.isTier2Provider}
                      isTieredprovider={item?.isTieredProvider}
                    />
                  </TierBadgeContainer>
                </>
              }
              value={item}
            />
          </React.Fragment>
        ))}
      </StyledLocationsContentWrapper>
      <Layout.Group>
        <StyledChangeLocationButton
          data-auto-testid={changeButtonTestId}
          data-testid={changeButtonTestId}
          onClick={handleChangeLocation}
        >
          {t('Change')}
        </StyledChangeLocationButton>
      </Layout.Group>
    </RadioGroup>
  );
};
